<div style="  margin-left: 3%; margin-right: 3%;">
<div class="alert alert-info" role="alert">
    ¡Todo tipo de recetas fitness para disfrutar!
 </div>
</div>

    <div class="row d-flex justify-content-center pt-lg-5">
        <div class="col-md-2 col-sm-8">
            <div class="card">
                <img class="card-img-top" src="../assets/demoImg.jpg" />
                <div class="card-body">
                  <h5 class="card-title">Sopa de remolacha con un toque de anacardos</h5>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">Cras justo odio</li>
                  <li class="list-group-item">Dapibus ac facilisis in</li>
                  <li class="list-group-item">Vestibulum at eros</li>
                </ul>
                <div class="card-body">
                  <a href="#" class="card-link">Ver receta</a>
                </div>
              </div>
        </div>
        <div class="col-md-2 col-sm-8">
            <div class="card" >
                 <img class="card-img-top" src="../assets/demoImg.jpg" />
                <div class="card-body">
                  <h5 class="card-title">Card title</h5>
                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">Cras justo odio</li>
                  <li class="list-group-item">Dapibus ac facilisis in</li>
                  <li class="list-group-item">Vestibulum at eros</li>
                </ul>
                <div class="card-body">
                  <a href="#" class="card-link">Card link</a>
                  <a href="#" class="card-link">Another link</a>
                </div>
              </div>
        </div>
        <div class="col-md-2 col-sm-8">
            <div class="card" >
                 <img class="card-img-top" src="../assets/demoImg.jpg" />
                <div class="card-body">
                  <h5 class="card-title">Card title</h5>
                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">Cras justo odio</li>
                  <li class="list-group-item">Dapibus ac facilisis in</li>
                  <li class="list-group-item">Vestibulum at eros</li>
                </ul>
                <div class="card-body">
                  <a href="#" class="card-link">Card link</a>
                  <a href="#" class="card-link">Another link</a>
                </div>
              </div>
        </div>
        <div class="col-md-2 col-sm-8">
            <div class="card" >
                 <img class="card-img-top" src="../assets/demoImg.jpg" />
                <div class="card-body">
                  <h5 class="card-title">Card title</h5>
                  <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item">Cras justo odio</li>
                  <li class="list-group-item">Dapibus ac facilisis in</li>
                  <li class="list-group-item">Vestibulum at eros</li>
                </ul>
                <div class="card-body">
                  <a href="#" class="card-link">Card link</a>
                  <a href="#" class="card-link">Another link</a>
                </div>
              </div>
        </div>
      </div>


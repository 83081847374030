import { Component, OnInit } from '@angular/core';
import { HomeServiceService } from '../home-service.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-recuperar-cuenta',
  templateUrl: './recuperar-cuenta.component.html',
  styleUrls: ['./recuperar-cuenta.component.css']
})
export class RecuperarCuentaComponent implements OnInit {

  public email;
  public dni;
  showLoginButton = false;
  recuperarButton = false;


  ERROR_AddressException = false;
  ERROR_MessagingException = false;
  NOT_FOUND = false;
  emailEnviado = false;

  constructor( private HomeService: HomeServiceService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.emailEnviado = false;
    this.showLoginButton = false;
  }

  
  recuperarCuenta(){
    this.spinner.show();

    this.ERROR_AddressException = false;
    this.ERROR_MessagingException = false;
    this.NOT_FOUND = false;
    this.emailEnviado = false;

    const data = new FormData();
    data.append('email',this.email);
    data.append('dni',this.dni);
    this.HomeService.recuperarCuenta(data).subscribe((res: any) => {

      if(res.status=='200OK'){
        this.emailEnviado = true;
        this.showLoginButton = true;
        this.spinner.hide();
      }else if(res.status == '404_NOT_FOUND'){
          this.NOT_FOUND = true;
          this.spinner.hide();
      }
     
      
    },
    error => {
      this.spinner.hide();
    if(error.error.text == "500ERROR_AddressException"){
      this.ERROR_AddressException = true;
    }else if(error.error.text == "500ERROR_MessagingException"){
        this.ERROR_MessagingException = true;
    }else{
      this.ERROR_MessagingException = true;
      sessionStorage.clear();
    }
    
    });
    
  }


  goLogin(){
    this.router.navigate(['login']);

  }


}

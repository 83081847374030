import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-modo-mantenimiento',
  templateUrl: './modo-mantenimiento.component.html',
  styleUrls: ['./modo-mantenimiento.component.css']
})
export class ModoMantenimientoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    const stackContainer = document.querySelector('.stack-container');
const cardNodes = document.querySelectorAll('.card-container');
const perspecNodes = document.querySelectorAll('.perspec');
const perspec = document.querySelector('.perspec');
const card = document.querySelector('.card');

let counter = stackContainer.children.length;

//function to generate random number
function randomIntFromInterval(min, max) {
    return Math.floor(Math.random() * (max - min + 1) + min);
}

//after tilt animation, fire the explode animation
card.addEventListener('animationend', function () {
    perspecNodes.forEach(function (elem, index) {
        elem.classList.add('explode');
    });
});

//after explode animation do a bunch of stuff
perspec.addEventListener('animationend', function (e) {
    if ((<any>e).animationName === 'explode') {
        cardNodes.forEach(function (elem, index) {

            //add hover animation class
            (<any>elem).classList.add('pokeup');

            //add event listner to throw card on click
            (<any>elem).addEventListener('click', function () {
                let updown = [800, -800]
                let randomY = updown[Math.floor(Math.random() * updown.length)];
                let randomX = Math.floor(Math.random() * 1000) - 1000;
                (<any>elem).style.transform = `translate(${randomX}px, ${randomY}px) rotate(-540deg)`;
                (<any>elem).style.transition = "transform 1s ease, opacity 2s";
                (<any>elem).style.opacity = "0";
                counter--;
                if (counter === 0) {
                  (<any>stackContainer).style.width = "0";
                  (<any>stackContainer).style.height = "0";
                }
            });

            //generate random number of lines of code between 4 and 10 and add to each card
            let numLines = randomIntFromInterval(5, 10);

            //loop through the lines and add them to the DOM
            for (let index = 0; index < numLines; index++) {
                let lineLength = randomIntFromInterval(25, 97);
                var node = document.createElement("li");
                node.classList.add('node-' + index);
                elem.querySelector('.code ul').appendChild(node).setAttribute('style', '--linelength: ' + lineLength + '%;');

                //draw lines of code 1 by 1
                if (index == 0) {
                    elem.querySelector('.code ul .node-' + index).classList.add('writeLine');
                } else {
                    elem.querySelector('.code ul .node-' + (index - 1)).addEventListener('animationend', function (e) {
                        elem.querySelector('.code ul .node-' + index).classList.add('writeLine');
                    });
                }
            }
        });
    }
});
  }

}

<div style="  margin-left: 3%; margin-right: 3%;">
    <div *ngIf="datosActualizados" class="container">
        <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            ¡Datos actualizados!
          </div>
        </div>

<div class="container">
    <div class="row">
      <div class="col-12">
              <div class="panel panel-primary">
                <div class="panel-heading">Datos de la licencia </div>
                <form (ngSubmit)="updateDatosLicencia()" class="needs-validation" novalidate>
                <div class="panel-body"><div>
                    <p style="color: red;">* Si se selecciona una fecha inferior a la del dia actual la cuenta se da de baja.</p>
                    <div class="form-row">
                        
                        <div class="col-md-4 mb-3">
                          <label for="fechaFinLicencia">Licencia valida hasta: {{fechaFinLicencia | date:'dd/MM/yyyy'  }} </label>
                        </div>
                        <div class="form-group col-md-4 mb-3">
                            <label for="tipoCuenta">Tipo de cuenta</label>
                            <select [(ngModel)] = "tipoCuenta" name="tipoCuenta" class="form-control" id="tipoCuenta">
                              <option>Seleccione una opción</option>
                              <option>Basica</option>
                              <option>Nutricion</option>
                              <option>Entrenamiento</option>
                              <option>Nutrición y entrenamiento</option>ç
                              <option>Reto 8 Semanas</option>
                            </select>
                        </div>
                        <div class="col-md-4 mb-3">
                            <label for="fechaFinLicencia">Nueva fecha fin licencia</label>
                            <input [(ngModel)]= "fechaFinLicencia" name="fechaFinLicencia" type="date" class="form-control"  id="fechaFinLicencia">
                            <div class="pt-3"> <button  class="btn btn-primary"  type="submit">Renovar</button></div>
                          </div>
                      </div>
                </div>
              </div>
              </form>
            </div>
      </div>
    </div>
</div>

<div style="  margin-left: 3%; margin-right: 3%;">
    <div *ngIf="rutinaAdjuntada" class="container">
        <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            ¡La rutina del cliente ya esta subida y preparada!
          </div>
        </div>

<div style="  margin-left: 3%; margin-right: 3%;">
    <div class="row">
      <div class="col-12">
              <div class="panel panel-primary">
                <div class="panel-heading">Adjuntar Rutina y dieta </div>
                <div class="panel-body"><div>
                    <p style="color: red;">*Solo se aceptan formatos PDF.</p>
                    <div class="form-row">
                        <div class="col-md-3 mb-6">
                            <div class="form-group">
                                <label for="rutina">Rutina</label>
                                <div class="item">
                                    Progreso: {{ (uploadProgressRutina | async) / 100 | percent }}
                                  </div>
                                  <input type="file" (change)="uploadRutina($event)" accept="accept='application/pdf'"> 
                             </div>
                        </div>
                        <div class="col-md-3 mb-6">
                            <div class="form-group">
                                <label for="dieta">Dieta</label>
                                <div class="item">
                                    Progreso: {{ (uploadProgressDieta | async) / 100 | percent }} 
                                  </div>
                                  <input type="file" (change)="uploadDieta($event)" accept="accept='application/pdf'"> 
                            </div>
                        </div>
                        <div class="col-md-3 mb-6">
                            <div class="form-group">
                                <label for="fechaDisponible">Fecha disponible descarga</label>
                                <input [(ngModel)] = "fechaDisponible" name="fechaDisponible" type="date" class="form-control" name="fechaDisponible" id="fechaDisponible" >
                            </div>
                        </div>
                        <div class="col-md-3 mb-6">
                            <div class="form-group">
                                <label for="fechaProximaRevision">Fecha proxima revision</label>
                                <input [(ngModel)] = "fechaProximaRevision" name="fechaProximaRevision" type="date" class="form-control" name="fechaProximaRevision" id="fechaProximaRevision" >
                            </div>
                        </div>
                      </div>
                      <div class="pt-3"> <button class="btn btn-primary"  (click)="adjuntarRutinaYdieta()"  type="submit">Enviar</button></div>
                </div>
              </div>
            </div>
      </div>
    </div>
</div>

<div *ngIf="rutinaEliminada" class="container">
  <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
      ¡Se ha eliminado correctamente!
    </div>
  </div>

<div style="  margin-left: 3%; margin-right: 3%;">
    <div class="row">
      <div class="col-6">
              <div class="panel panel-primary">
                <div class="panel-heading">Historico rutina</div>
                <div class="panel-body"><div>
                    <table class="table table-hover">
                        <tr>
                          <th>Fecha disponible descarga</th>                 
                          <th>Rutina</th>
                          <th>Eliminar Rutina</th>
                        </tr>
                        <tr  *ngFor = "let entrenamiento of entrenamientos">
                          <td>{{entrenamiento.fechaRutina | date:'dd/MM/yyyy' }}</td>
                          <td><a class="btn btn-primary" href={{entrenamiento.excelRutinaPath}} target="_blank"> Descargar Rutina  </a></td>
                          <td><button type="button" (click) = "eliminarRutina(entrenamiento.idRutina, entrenamiento.fechaRutina)" class="btn btn-danger">Eliminar</button></td>
                        </tr>
                      </table>
                </div>
              </div>
            </div>
      </div>
      <div class="col-6">
        <div class="panel panel-primary">
          <div class="panel-heading">Historico dieta</div>
          <div class="panel-body"><div>
              <table class="table table-hover">
                  <tr>
                    <th>Fecha disponible descarga</th>                 
                    <th>Dieta</th>
                    <th>Eliminar Dieta</th>
                  </tr>
                  <tr  *ngFor = "let dieta of dietas">
                    <td>{{dieta.fechaDieta | date:'dd/MM/yyyy' }}</td>
                    <td><a class="btn btn-primary" href={{dieta.excelDietaPath}} target="_blank"> Descargar Dieta  </a></td>
                    <td><button type="button" (click) = "eliminarDieta(dieta.idDieta)" class="btn btn-danger">Eliminar</button></td>
                  </tr>
                </table>
          </div>
        </div>
      </div>
</div>
    </div>
</div>

<div style="  margin-left: 3%; margin-right: 3%;">
  <div class="row">
    <div class="col-12">
            <div class="panel panel-primary">
              <div class="panel-heading">Grafica evolucion del peso </div>
              <div class="panel-body"><div>
                <canvas id="canvas" width="200" height="50">{{chart}}</canvas>
              </div>
            </div>
          </div>
    </div>
  </div>
</div>

<div style="  margin-left: 3%; margin-right: 3%;">
    <div class="row">
      <div class="col-12">
              <div class="panel panel-primary">
                <div class="panel-heading">Grafica evolucion medidas </div>
                <div class="panel-body"><div>
                  <canvas id="canvas2" width="200" height="50">{{chartMedidas}}</canvas>
                </div>
              </div>
            </div>
      </div>
    </div>
  </div>

  <div style="  margin-left: 3%; margin-right: 3%;">
    <div class="row">
      <div class="col-lg-6 col-sm-12">
              <div class="panel panel-primary">
                <div class="panel-heading">Desglose Macronutrientes </div>
                <div class="panel-body"><div>
                  <div id="container"></div>
                </div>
              </div>
            </div>
      </div>
      <div class="col-lg-6 col-sm-12">
        <div class="panel panel-primary">
          <div class="panel-heading">Desglose Macronutrientes </div>
          <div class="panel-body">
            <div>
               <p>Kcalorias necesarias para mantener el peso (TMB): {{tmb}} Kcal</p>
               <p>Calorias necesarias para {{objetivos}}:  {{tmbConObjetivos}} Kcal</p>
               <p>Porcentaje hidratos de carbono: {{repartoMacrosHc}}%</p>
               <p>Porcentaje proteinas: {{repartoMacrosPr}}%</p>
               <p>Porcentaje grasas: {{repartoMacrosGr}}%</p>
               <p>Kcalorias en hidratos de carbono: {{caloriasCalculadasHcShow}} Kcal</p>
               <p>Kcalorias en proteina: {{caloriasCalculadasPrShow}} Kcal</p>
               <p>Kcalorias en Grasas: {{caloriasCalculadasGrShow}}Kcal</p>
               <div class="panel-body">
               <div class="row">
                   <label>1 Gramo de proteina o de hidratos de carbono son 4kcal</label>
               </div>
               <div class="row">
                   <label>1 Gramo de grasa son 9kcal</label>
               </div>
              </div>
              
            </div>      
         </div>
      </div>
</div>
    </div>
  </div>

 


<div style="  margin-left: 3%; margin-right: 3%;">
  <div class="row">
    <div class="col-12">
            <div class="panel panel-primary">
              <div class="panel-heading">Galeria de evolucion </div>
<div *ngIf = "evolucionEntrenamientos" style="  margin-left: 3%; margin-right: 3%; padding-top: 2%;">
  <div class="row">
    <div class="col-12">
            <div *ngFor = "let entrenamiento of dataEvolucionEntrenamiento | paginate: { itemsPerPage: count, currentPage: p } " class="panel panel-primary">
              <div  class="panel-heading">Evolucion del entrenamiento del cliente </div>
              <label class="mt-4 ml-4" >  <img  class="photoStyle" src="../assets/calendario_color.png" style="width:24px;height:24px;" />  Fecha:  {{entrenamiento.fechaProgreso }}   <img  class="photoStyle" src="../assets/escala_actual_color.png" style="width:24px;height:24px;" /> Peso: {{entrenamiento.peso}} Kilogramos   <img  class="photoStyle" src="../assets/plan.png" style="width:24px;height:24px;" /> Nota sesión nutrición: {{entrenamiento.valoracion_nutricion}}   <img  class="photoStyle" src="../assets/personal.png" style="width:24px;height:24px;" />  Nota sesión entrenamiento: {{entrenamiento.valoracion_entrenamiento}} </label>
              <div class="panel-body"><div>
                  <div class="container">
                    <div class="row">
                      <div class="col-sm text-center">
                           <img  style="margin-top: 4%;" class="photoStyle" *ngIf="entrenamiento.fileFrente!='undefined'"  [src]="entrenamiento.fileFrente" style="width:350px;height:300px;"/>
                           <a  style="margin-top: 4%;"  *ngIf="entrenamiento.fileFrente!='undefined'" [href]=entrenamiento.fileFrente  class="btn btn-primary">Ver en tamaño real</a>
                          <img  class="photoStyle" *ngIf="entrenamiento.fileFrente=='undefined'" src="../assets/foto.png" style="width:150px;height:100px;">
                      </div>
                      <div class="col-sm text-center">
                          <img  style="margin-top: 4%;" class="photoStyle" *ngIf="entrenamiento.filePerfil!='undefined'" [src]="entrenamiento.filePerfil" style="width:350px;height:300px;"  />
                          <a  style="margin-top: 4%;"  *ngIf="entrenamiento.filePerfil!='undefined'" [href]=entrenamiento.filePerfil  class="btn btn-primary">Ver en tamaño real</a>
                          <img   class="photoStyle" *ngIf="entrenamiento.filePerfil=='undefined'" src="../assets/foto.png" style="width:150px;height:100px;">
                      </div>
                      <div class="col-sm text-center">
                          <img  style="margin-top: 4%;" class="photoStyle" *ngIf="entrenamiento.fileEspalda!='undefined'" [src]="entrenamiento.fileEspalda" style="width:350px;height:300px;"  />
                          <a  style="margin-top: 4%;"  *ngIf="entrenamiento.fileEspalda!='undefined'" [href]=entrenamiento.fileEspalda  class="btn btn-primary">Ver en tamaño real</a>
                          <img  class="photoStyle" *ngIf="entrenamiento.fileEspalda=='undefined'" src="../assets/foto.png" style="width:150px;height:100px;">
                      </div>
                    </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-right">
            <pagination-controls (pageChange)="p = $event"></pagination-controls>
          </div>
    </div>
   </div>
  </div>
</div>
</div>

</div>


<div style="  margin-left: 3%; margin-right: 3%;">
  <div *ngIf="datosActualizadosPerfil" class="container">
      <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          ¡Has actualizado los datos del cliente!
        </div>
      </div>
      </div>

      <div *ngIf="notaEntrenadorGuardada" class="container">
        <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            Nota guardada
          </div>
        </div>
      </div>

      



      
<div style="  margin-left: 3%; margin-right: 3%;">
    <div class="row">
        <div class="col-8 pt-3">
                <div class="panel panel-primary">
                  <div class="panel-heading">Edicion de usuario</div>
                  <div class="panel-body"><div>
                    <h3>Datos personales</h3>
                    <form (ngSubmit)="saveFormPerfilUsuario()" class="needs-validation" novalidate>
                      <div class="form-row">
                        <div class="col-md-4 mb-3">
                          <label for="nombre">Nombre*</label>
                          <input  [(ngModel)] = "nombre" name="nombre" type="text" class="form-control" name= "nombre" id="nombre" placeholder="Nombre" >

                        </div>
                        <div class="col-md-4 mb-3">
                          <label for="apellido1">Apellido Primero*</label>
                          <input [(ngModel)] = "apellido1" name="apellido1"  type="text" class="form-control" name="apellido1" id="apellidoPrimero" placeholder="Apellido primero"  >

                        </div>
                       <div class="col-md-4 mb-3">
                          <label for="apellido2">Apellido Segundo</label>
                          <input [(ngModel)] = "apellido2" name="apellido2" type="text" class="form-control" name="apellido2" id="apellido2" placeholder="Apellido segundo" >

                        </div>
                      </div>

                      <div class="form-row">
                          <div class="col-md-4 mb-3">
                            <label for="dni">DNI (Sin letra)*</label>
                            <input [(ngModel)] = "dni" name="dni" type="text" class="form-control" name="dni" id="dni" placeholder="xxxxxxxxx" >

                          </div>
                          <div class="col-md-4 mb-3">
                            <label for="telefono">Telefono*</label>
                            <input  [(ngModel)] = "telefono" name="telefono" type="number" class="form-control" name="telefono" id="telefono" placeholder="Telefono"  >

                          </div>
                         <div class="col-md-4 mb-3">
                            <label for="email">Email*</label>
                            <input [(ngModel)] = "email" name="email" type="text" class="form-control" name="email" id="email" placeholder="Email"  >

                          </div>
                        </div>
                        <h3 class="pt-3">Datos fisicos</h3>
                        <div class="form-row">
                            <div class="col-md-4 mb-3">
                              <label for="anioNacimiento">Año de nacimiento</label>
                              <input  [(ngModel)] = "anioNacimiento" name="anioNacimiento" type="date" class="form-control" name="anioNacimiento" id="anioNacimiento" placeholder="Año de nacimiento"  >

                            </div>
                            <div class="col-md-4 mb-3">
                              <label for="altura">Altura(cm)*</label>
                              <input [(ngModel)] = "altura" name="altura" type="number" class="form-control" name="altura" id="altura" placeholder="Altura"  >

                            </div>
                           <div class="col-md-4 mb-3">
                              <label for="pesoKilos">Peso(Kg)*</label>
                              <input [(ngModel)] = "pesoKilos" name="pesoKilos" type="number" class="form-control" name="ppesoKiloseso" id="pesoKilos" placeholder="Peso"  >

                            </div>
                          </div>
                          <div class="form-row">
                              <div class="col-md-4 mb-3">
                                <label for="pesoGramos">Peso (Gramos)*</label>
                                <input [(ngModel)] = "pesoGramos" name="pesoGramos" type="number" class="form-control" name="pesoGramos" id="pesoGramos" placeholder="Peso"  >

                              </div>
                              <div class="form-group col-md-4 mb-3">
                                  <label for="complexion">Complexion</label>
                                  <select  [(ngModel)] = "complexion" name="complexion" class="form-control" id="complexion">

                                    <option value="fuerte">Fuerte</option>
                                    <option value="delgado">Delgado</option>
                                    <option value="atletico">Atletico</option>
                                  </select>
                              </div>
                              <div class="form-group col-md-4 mb-3">
                                  <label for="actividadFisicaDeseada">Actividad Física deseada</label>
                                  <select [(ngModel)]="actividadFisicaDeseada" name="actividadFisicaDeseada" class="form-control" id="actividadFisicaDeseada" >
                                    <option value= ligero >Ejercicio ligero (1-3 dias por semana) </option>
                                    <option value=moderado>Ejercicio moderado ( 3-5 dias por semana)</option>
                                    <option value=fuerte >Ejercicio fuerte (6 dias por semana)</option>
                                    <option value=profesional>Ejercicio profesional</option>
                                  </select>
                              </div>
                            </div>
                            <div class="form-row">
                                <div class="col-md-4 mb-3">
                                  <label for= 'objetivos'>Objetivos</label>
                                  <select  [(ngModel)] = "objetivos" name="objetivos" class="form-control" id="objetivos" class="form-control">
  
                                      <option value="Ganar masa muscular">Ganar masa muscular</option>
                                      <option value="Definición muscular">Definición muscular</option>
                                      <option value="Pérdida de peso">Pérdida de peso</option>
                                      <option value="Mantener el peso">Mantener el peso</option>
                                    </select>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <label for="comentarios">Comentarios</label>
                                    <textarea  [(ngModel)] = "comentarios" name="comentarios" class="form-control" name="comentarios" id="comentarios" placeholder="Escribe aqui tus alergias" required></textarea>

                                  </div>
                                  <div class="form-group col-md-4 mb-3">
                                      <label for="suplementacion">Suplementacion</label>
                                      <select [(ngModel)] = "suplementacion" name="suplementacion" class="form-control" id="exampleFormControlSelect1">
                                        <option value="si">Si</option>
                                        <option value="no">No</option>
                                        <option value="moderada">Moderada</option>
                                      </select>
                                  </div>
                              </div>


                              
                              <h3>Documento de bienvenida</h3>

                              <div class="form-group row">
                                <div class="col-md-4 mb-6">
                                    <div class="form-group">
                                      <label for="contrasenia2">Adjunta aqui el documento de bienvenida</label>
                                        <div class="item">
                                            Progreso: {{ (uploadProgressDocBienve | async) / 100 | percent }}
                                          </div>
                                   <input type="file"  formControlName="docBienve" (change)="adjuntoBienvenida($event)"  accept="application/pdf,application/msword,
                                   application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
                                  
                                    </div>
                                </div>
                              </div>

                              <div *ngIf="urlDoc!=null " class="form-group row">
                                <div class="col-md-4 mb-3">
                                  <label for="docBienvenida">El documento ha sido adjuntado por el cliente, descargalo  <a href={{urlDoc}} target="_blank"> aquí </a> </label>     
                                </div>
                              </div>
                           
                              <div class="pt-3"> <button class="btn btn-primary"  type="submit">Guardar</button></div>
                    </form>
                  </div>
                </div>
              </div>
        </div>
       
        <div class="col-4 pt-3">
          <div class="panel panel-primary">
            <div class="panel-heading">Notas </div>
            <div class="panel-body"><div>
              <label>Introduce aqui los comentarios del cliente, solo los puedes ver tu</label>
              <form (ngSubmit)="guardarNotas()" class="needs-validation" novalidate>
              <textarea  [(ngModel)] = "notasEntrenador" name="notasEntrenador" style="width: 100%;">{{notasEntrenador}}</textarea>
              <div class="pt-3"> <button class="btn btn-primary"  type="submit">Guardar</button></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

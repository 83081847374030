import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';

import { NgxSpinnerService } from "ngx-spinner";


@Injectable({
  providedIn: 'root'
})

export class HomeServiceService {
  private uri;
  constructor(  private http: HttpClient, private router: Router ) {

  
   }

   public eliminarRutina(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/eliminarRutina', dataForm, {headers: requestHeaders});
  }

  public eliminarDieta(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/eliminarDieta', dataForm, {headers: requestHeaders});
  }

  public enviarEncuestaProgreso(dataForm) {
    const token = sessionStorage.getItem('token');
    dataForm.append('email', sessionStorage.getItem('username'));
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/backendService/guardarEncuesta', dataForm, {headers: requestHeaders});
  }


   public tieneSuscripcion(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/backendService/tieneSuscripcion', dataForm, {headers: requestHeaders});
  }

   

   public obtenerPreparacionesRutinaPerfilAdministrador(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/obtenerPreparacionRutina', dataForm, {headers: requestHeaders});
  }

  
  public obtenerPreparacionesDietaPerfilAdministrador(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/obtenerPreparacionDieta', dataForm, {headers: requestHeaders});
  }

   public actualizardietayrutina(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/actualizardietayrutina', dataForm, {headers: requestHeaders});
  }

  public guardarNotasEntrenador(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/guardarNotas', dataForm, {headers: requestHeaders});
  }

  public obtenerNotasEntrenador(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/obtenerNotas', dataForm, {headers: requestHeaders});
  }


  public crearAnuncio(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/mantenimiento/crearAnuncio', dataForm, {headers: requestHeaders});
  }

  public recuperarCuenta(dataForm) {
    return this.http.post('https://atlasproduction.fly.dev/backendService/recuperarCuenta', dataForm);
  }

  public getAnuncios() {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/mantenimiento/getAnuncios', null, {headers: requestHeaders});
  }

  public crearCupon(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/mantenimiento/crearCupon', dataForm, {headers: requestHeaders});
  }

  public getCupones() {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/mantenimiento/getCupones',null, {headers: requestHeaders});
  }

  public borrarCupon(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/mantenimiento/borrarCupon',dataForm, {headers: requestHeaders});
  }

  public borrarAnuncio(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/mantenimiento/borrarAnuncio',dataForm, {headers: requestHeaders});
  }

  public enviarRecordatorioMail(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/backendService/enviarRecordatorio', dataForm, {headers: requestHeaders});
  }

  public enviarRecordatorioMailBaja(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/backendService/enviarRecordatorioBaja', dataForm, {headers: requestHeaders});
  }


  public savePerfilUsuarioDesdeAdmin(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/updatePerfil', dataForm, {headers: requestHeaders});
  }

  public esSuper(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/comprobarSuper', dataForm, {headers: requestHeaders});
  }

  public updateLicenciaManual(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/updateLicenciaManual', dataForm, {headers: requestHeaders});
  }

  public getUserProfileAdministratorSide(user_id) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/getPerfilUsuario', user_id, {headers: requestHeaders});
  }


  public registroUsuario(dataForm) {
    return this.http.post('https://atlasproduction.fly.dev/backendService/nuevoUsuario', dataForm);
  }


  public makePayment(dataForm){
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/paypal/make/payment', dataForm, {headers: requestHeaders});
  }

  public validarCupon(dataForm){
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/paypal/comprobarCupon', dataForm, {headers: requestHeaders});
  }

  public completePyament(dataForm){
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/paypal/complete/payment', dataForm, {headers: requestHeaders});
  }

  public getUserData(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/editarPerfil', dataForm, {headers: requestHeaders});
  }


  public saveNuevoProgreso(dataForm) {
    dataForm.append('email', sessionStorage.getItem('username'));
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/backendService/nuevoProgreso', dataForm, {headers: requestHeaders});
  }

  public savePerfilUsuario(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/buscarPerfil', dataForm, {headers: requestHeaders});
  }

  public savePerfilUsuarioUsuario(dataForm) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/backendService/updatePerfil', dataForm, {headers: requestHeaders});
  }

  public getDatosLicencia(data) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/paypal/datosLicencia', data, {headers: requestHeaders});
  }

  public getDatosPrecios() {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/paypal/getPrecios', null, {headers: requestHeaders});
  }


  public getUsuariosDeBaja() {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/usuariosDeBaja', null, {headers: requestHeaders});
  }

  public getPerfilUsuarioOnload() {
    const data = new FormData();
    data.append('email', sessionStorage.getItem('username'));
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/backendService/miPerfil', data, {headers: requestHeaders});
  }

  public searchProfile(dataForm) {
    return this.http.post('uri', dataForm);
  }

 


  public getHomeData() {
    const data = new FormData();
    data.append('email', sessionStorage.getItem('username'));
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/backendService/home', data, {headers: requestHeaders});

  }


  
  public getHomeDataAdminMacros(emailClient) {
    const data = new FormData();
    data.append('email', emailClient);
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/backendService/home', data, {headers: requestHeaders});

  }

  public getEvolucionEntrenamientos() {
    const data = new FormData();
    data.append('email', sessionStorage.getItem('username'));
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/backendService/evolucionEntrenamientos', data, {headers: requestHeaders});
  }

  public getEvolucionEntrenamientosAdmin(data) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    return this.http.post('https://atlasproduction.fly.dev/administrador/evolucionEntrenamientosAdmin', data, {headers: requestHeaders});
  }

  public sendTelegramMessage(url, data) {

    return this.http.post(url, data);
 
}



  public getUsuariosProximaRevision() {
    return this.http.get('uri');
  }

  public getNuevaPreparacion(data) {
    const token = sessionStorage.getItem('token');
    let requestHeaders = new HttpHeaders();
    requestHeaders = requestHeaders.set('Authorization', token);
    // tslint:disable-next-line:max-line-length
    return this.http.post('https://atlasproduction.fly.dev/backendService/nuevaPreparacion', data , {headers: requestHeaders});
  }

  authenticate(email, password) {
  const data = new FormData();
  data.append('email', email);
  data.append('password', password);
  return this.http.post<any>('https://atlasproduction.fly.dev/backendService/login', data).pipe(
     map(
       userData => {
         sessionStorage.setItem('username', email);
         const tokenStr =  userData.token;
         sessionStorage.setItem('token', tokenStr);
         return userData;
       }
     )
    );
  }
  peso(peso: any): string | Blob {
    throw new Error('Method not implemented.');
  }

isUserLoggedIn() {
  const user = sessionStorage.getItem('username');
  return !(user === null);
}

logOut() {
  sessionStorage.clear();
}


}

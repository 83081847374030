<div style="  margin-left: 3%; margin-right: 3%;">

    <div style="  margin-left: 3%; margin-right: 3%;">
        <div *ngIf="datosGuardadosOK" class="container">
            <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
                ¡Datos actualizados!
              </div>
            </div>

<div class="row">
    <div class="col-12 pt-3">
            <div class="panel panel-primary">
              <div class="panel-heading">Mi perfil</div>
              <div class="panel-body"><div>
                <h3>Datos personales</h3>
                <p style="color: red;">* Todos los datos que se modifiquen seran notificados a tu entrenador personal, si cambias de objetivos esta es tu seccion</p>
                  <form (ngSubmit)="saveFormPerfilUsuario()" class="needs-validation" novalidate>
                      <div class="form-row">
                        <div class="col-md-4 mb-3">
                          <label for="nombre">Nombre*</label>
                          <input  [(ngModel)] = "nombre" name="nombre" type="text" class="form-control" name= "nombre" id="nombre" placeholder="Nombre" disabled>

                        </div>
                        <div class="col-md-4 mb-3">
                          <label for="apellido1">Apellido Primero*</label>
                          <input [(ngModel)] = "apellido1" name="apellido1"  type="text" class="form-control" name="apellido1" id="apellidoPrimero" placeholder="Apellido primero"  disabled>

                        </div>
                       <div class="col-md-4 mb-3">
                          <label for="apellido2">Apellido Segundo</label>
                          <input [(ngModel)] = "apellido2" name="apellido2" type="text" class="form-control" name="apellido2" id="apellido2" placeholder="Apellido segundo" disabled>

                        </div>
                      </div>

                      <div class="form-row">
                          <div class="col-md-4 mb-3">
                            <label for="dni">DNI (Sin letra)*</label>
                            <input [(ngModel)] = "dni" name="dni" type="text" class="form-control" name="dni" id="dni" placeholder="xxxxxxxxx" disabled>

                          </div>
                          <div class="col-md-4 mb-3">
                            <label for="telefono">Telefono*</label>
                            <input  [(ngModel)] = "telefono" name="telefono" type="number" class="form-control" name="telefono" id="telefono" placeholder="Telefono"  required>

                          </div>
                         <div class="col-md-4 mb-3">
                            <label for="email">Email*</label>
                            <input [(ngModel)] = "email" name="email" type="text" class="form-control" name="email" id="email" placeholder="Email"  required>

                          </div>

                          <div class="form-group col-md-4 mb-3">
                            <label for="sexo">Sexo</label>
                            <select  [(ngModel)] = "sexo" name="sexo" class="form-control" id="sexo">

                              <option value="hombre">Hombre</option>
                              <option value="mujer">Mujer</option>
                              <option value="otro">Otro</option>
                            </select>
                        </div>
                        </div>
                        <h3 class="pt-3">Datos fisicos</h3>
                        <div class="form-row">
                            <div class="col-md-4 mb-3">
                              <label for="anioNacimiento">Año de nacimiento</label>
                              <input  [(ngModel)] = "anioNacimiento" name="anioNacimiento" type="text" class="form-control" name="anioNacimiento" id="anioNacimiento" placeholder="Año de nacimiento"  disabled>

                            </div>
                            <div class="col-md-4 mb-3">
                              <label for="altura">Altura(cm)*</label>
                              <input [(ngModel)] = "altura" name="altura" type="number" class="form-control" name="altura" id="altura" placeholder="Altura"  required>

                            </div>
                           <div class="col-md-4 mb-3">
                              <label for="pesoKilos">Peso(Kg)*</label>
                              <input [(ngModel)] = "pesoKilos" name="pesoKilos" type="number" class="form-control" name="ppesoKiloseso" id="pesoKilos" placeholder="Peso"  disabled>

                            </div>
                          </div>
                          <div class="form-row">
                              <div class="col-md-4 mb-3">
                                <label for="pesoGramos">Peso (Gramos)*</label>
                                <input [(ngModel)] = "pesoGramos" name="pesoGramos" type="number" class="form-control" name="pesoGramos" id="pesoGramos" placeholder="Peso"  disabled>

                              </div>
                              <div class="form-group col-md-4 mb-3">
                                  <label for="complexion">Complexion</label>
                                  <select  [(ngModel)] = "complexion" name="complexion" class="form-control" id="complexion">

                                    <option value="fuerte">Fuerte</option>
                                    <option value="delgado">Delgado</option>
                                    <option value="atletico">Atletico</option>
                                  </select>
                              </div>
                              <div class="form-group col-md-4 mb-3">
                                  <label for="actividadFisicaDeseada">Actividad Física deseada</label>
                                  <select [(ngModel)]="actividadFisicaDeseada" name="actividadFisicaDeseada" class="form-control" id="actividadFisicaDeseada" >
                                    <option value= ligero >Ejercicio ligero (1-3 dias por semana) </option>
                                    <option value=moderado>Ejercicio moderado ( 3-5 dias por semana)</option>
                                    <option value=fuerte >Ejercicio fuerte (6 dias por semana)</option>
                                    <option value=profesional>Ejercicio profesional</option>
                                  </select>
                              </div>
                            </div>
                            <div class="form-row">
                              <div class="form-group col-md-4 mb-3">
                                  <label for= 'objetivos'>Objetivos</label>
                                  <select  [(ngModel)] = "objetivos" name="objetivos" class="form-control" id="objetivos" class="form-control">
  
                                      <option value="Ganar masa muscular">Ganar masa muscular</option>
                                      <option value="Definición muscular">Definición muscular</option>
                                      <option value="Pérdida de peso">Pérdida de peso</option>
                                      <option value="Mantener el peso">Mantener el peso</option>
                                    </select>
                                </div>
                                <div class="form-group col-md-4 mb-3">
                                  <label for="suplementacion">Suplementacion</label>
                                  <select [(ngModel)] = "suplementacion" name="suplementacion" class="form-control" id="exampleFormControlSelect1">
                                    <option value="si">Si</option>
                                    <option value="no">No</option>
                                    <option value="moderada">Moderada</option>
                                  </select>
                              </div>
                              </div>
                                <div class="form-row">
                                <div class="col-md-12 mb-3">
                                    <label for="comentarios">Comentarios</label>
                                    <textarea   rows= 3 [(ngModel)] = "comentarios" name="comentarios" class="form-control" name="comentarios" id="comentarios" placeholder="Escribe aqui tus alergias" required></textarea>
                                  </div>
                                </div>
                               
                              <div class="form-row">
                                  <div class="col-md-4 mb-3">
                                    <label for="contrasenia">Contraseña</label>
                                    <input [(ngModel)] = "contrasenia" name="contrasenia" type="password" class="form-control" name="contrasenia" id="contrasenia" placeholder="Password">

                                  </div>
                                  <div class="col-md-4 mb-3">
                                      <label for="contrasenia2">Repita contraseña</label>
                                      <input  [(ngModel)] = "contrasenia2" name="contrasenia2" type="password" class="form-control" name="contrasenia2" id="contrasenia2" placeholder="Password">

                                    </div>
                                </div>

                                <h3>Documento de bienvenida</h3>

                                <div *ngIf="docBienvenida==null || docBienvenida==undefined" class="form-group row">
                                  <div class="col-md-4 mb-6">
                                      <div class="form-group">
                                        <label for="contrasenia2">Adjunta aqui el documento de bienvenida (*Preferiblemente PDF)</label>
                                          <div class="item">
                                              Progreso: {{ (uploadProgressDocBienve | async) / 100 | percent }}
                                            </div>
                                     <input type="file"  formControlName="docBienve" (change)="adjuntoBienvenida($event)"  accept="application/pdf,application/msword,
                                     application/vnd.openxmlformats-officedocument.wordprocessingml.document"/>
                                    
                                      </div>
                                  </div>
                                </div>

                                <div *ngIf="docBienvenida!=null || docBienvenida!=undefined" class="form-group row">
                                  <div class="col-md-4 mb-3">
                                    <label for="docBienvenida">El documento ya ha sido adjuntado, para adjuntar uno nuevo debes hablar con tu entrenador personal</label>     
                                  </div>
                                </div>
                                

                                <div class="form-row">

                                  <div class="form-group col-sm-1 col-md-6 mb-3">
                                      <input [(ngModel)] = "permiteSubirFotos" name="permiteSubirFotos" type="checkbox" class="form-check-input" id="permiteSubirFotos" >
                                      <label class="form-check-label"  style="padding-left: 5%;"  for="permiteSubirFotos">Consiento el uso de las fotos que suba a la aplicación en la web publica <a href="http://atlasfitness.es">http://atlasfitness.es</a> </label>
                                  </div>
                              </div>

                              <div class="form-row">
                                <div class="form-group col-sm-12 col-md-6 mb-3">
                                  <input [(ngModel)] = "checkPermiteRecibirNotificaciones" name="checkPermiteRecibirNotificaciones"  type="checkbox" class="form-check-input"  id="checkPermiteRecibirNotificaciones">
                                  <label class="form-check-label" style="padding-left: 5%;" for="checkPermiteRecibirNotificaciones">Deseo recibir notificaciones de los anuncios publicados en el tablon de Novedades </label>
                              </div>
                            </div>
                              <div class="pt-3"> <button  (click)="saveFormPerfilUsuario()" class="btn btn-primary"  type="submit">Guardar</button></div>
                    </form>
              </div>
            </div>
          </div>
    </div>
</div>
</div>

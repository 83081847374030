import { Component, OnInit } from '@angular/core';
import { HomeServiceService } from '../home-service.service';

import { Router,NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css']
})
export class NavBarComponent implements OnInit {

  constructor(public HomeService: HomeServiceService ) { 

   
  }

  public esVisible = false;

  ngOnInit() {
    const data = new FormData();
    data.append('user_id2',sessionStorage.getItem('user_id2'));
    this.HomeService.esSuper(data).subscribe((res: any) => {
      if(res == null){
        this.esVisible = false;
      }else{
        this.esVisible = true;
      }
    });

  }

}

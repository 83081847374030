import { Component, OnInit } from '@angular/core';
import { HomeServiceService } from '../home-service.service';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-nueva-preparacion',
  templateUrl: './nueva-preparacion.component.html',
  styleUrls: ['./nueva-preparacion.component.css']
})
export class NuevaPreparacionComponent implements OnInit {

  public hayPreparacion = false;
  public hayPreparacionesPasadas = false;
  public fechaNuevaPreparacion = '01/01/1990';

  constructor(private HomeService: HomeServiceService,  public router: Router,private titleService:Title) {
    this.titleService.setTitle("AtlasFitness::Nueva Preparacion");
  }

public excelpathrutina;
public excelpathdieta;
public fechaDieta;

  ngOnInit() {


    


   
    const data = new FormData();
    data.append('userId', sessionStorage.getItem('user_id2'));


    this.HomeService.tieneSuscripcion(data).subscribe((res:any) => {

      if(res==-1){
       this.router.navigate(['suscripcion']); 
      }
 
      if(res==0){
       this.router.navigate(['suscripcion']); 
      }
 
     });

      this.HomeService.getNuevaPreparacion(data).subscribe((res: any) => {
       if(res!=null){
        if(res.excelDietaPath!= "" || res.excelRutinaPath != ""){
         
          this.hayPreparacion = true;
          this.fechaNuevaPreparacion = res.fechaRutina;
          this.excelpathrutina = res.excelRutinaPath;
          this.excelpathdieta = res.excelDietaPath;

        }else{
          this.hayPreparacion = false;
          this.fechaNuevaPreparacion = res.fechaRutina;
        }
      }
        },
        error => {
         sessionStorage.clear();
          this.router.navigate(['error']); 
        }
      );

  


  }

}

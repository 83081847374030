
<div style="  margin-left: 3%; margin-right: 3%;">

  <div style="  margin-left: 3%; margin-right: 3%;">
      <div *ngIf="cuponCreado" class="container">
          <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
              ¡Tu cupon ha sido creado!
            </div>
          </div>

          <div style="  margin-left: 3%; margin-right: 3%;">
            <div *ngIf="cuponExiste" class="container">
                <div class="alert alert-danger" role="error"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                    ¡El cupon ya existe, crea otro nuevo!
                  </div>
                </div>
           </div>

           
       


                
                

<div class="row">
  <div class="col-12 pt-3">
          <div class="panel panel-primary">
            <div class="panel-heading">Nuevo Cupon</div>
            <div class="panel-body"><div>
              <h3>Introduce el nuevo cupon</h3>
                <form  [formGroup]="crearCuponForm" (ngSubmit)="crearCupon()"> 
                   
                    <div class="form-row">
                      <div class="col-md-3 mb-3">
                        <label for="nombreCupon">Nombre cupon</label>
                        <input  [(ngModel)] = "nombreCupon" name="nombreCupon"  type="text" formControlName="nombreCupon" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nombreCupon.errors }" placeholder="Nombre del cupon" />
                            <div *ngIf="submitted && f.nombreCupon.errors" class="invalid-feedback">
                                <div *ngIf="f.nombreCupon.errors.required">Campo nombre cupon es obligatorio</div>
                            </div>
                      </div>
                      <div class="col-md-3 mb-3">
                        <label for="fechaInicio">Fecha Inicio</label>
                        <input  [(ngModel)] = "fechaInicio" name="fechaInicio"  type="date" formControlName="fechaInicio" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fechaInicio.errors }" placeholder="Fecha de inicio" />
                        <div *ngIf="submitted && f.fechaInicio.errors" class="invalid-feedback">
                            <div *ngIf="f.fechaInicio.errors.required">Fecha de inicio es obligatoria</div>
                        </div>

                      </div>
                     <div class="col-md-3 mb-3">
                        <label for="fechaFin">Fecha Fin</label>
                        <input  [(ngModel)] = "fechaFin" name="fechaFin"  type="date" formControlName="fechaFin" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.fechaFin.errors }" placeholder="Fecha de inicio" />
                        <div *ngIf="submitted && f.fechaFin.errors" class="invalid-feedback">
                            <div *ngIf="f.fechaFin.errors.required">Fecha de fin es obligatoria</div>
                        </div>
                      </div>
                      <div class="col-md-3 mb-3">
                        <label for="descuento"> Porcentaje de descuento (*0.1 10%) </label>
                        <input  [(ngModel)] = "descuento" name="descuento"  type="number" formControlName="descuento" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.descuento.errors }" placeholder="Descuento a aplicar" />
                        <div *ngIf="submitted && f.descuento.errors" class="invalid-feedback">
                            <div *ngIf="f.descuento.errors.required">Campo descuento del cupon es obligatorio</div>
                        </div>
                      </div>

                    </div>
                      <div class="pt-3"> <button  class="btn btn-primary">Crear cupon</button></div>
                  </form>
            </div>
          </div>
        </div>
  </div>
</div>

</div>


<div style="  margin-left: 3%; margin-right: 3%;">
    <div *ngIf="borrarCuponAlert" class="container">
        <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
            ¡El cupon ha sido eliminado!
          </div>
        </div>
   </div>

<div style="  margin-left: 3%; margin-right: 3%;">
  <div class="row">
    <div class="col-12">
            <div class="panel panel-primary">
              <div class="panel-heading">Cupones Activos</div>
              <div class="panel-body"><div>
                  <table class="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Nombre Cupon</th>
                          <th scope="col">Fecha Inicio</th>
                          <th scope="col">Fecha Fin</th>
                          <th scope="col">Descuento</th>
                          <th>Accion</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  *ngFor="let c of listaCupones">
                          <td  >{{c.nombreCupon}}</td>
                          <td >{{c.fechaInicio}}</td>
                          <td >{{c.fechaFin}}</td>
                          <td >{{c.descuentoTabla}}</td>
                          <td  ><button (click)="borrarCupon(c.idCupon)" type="button" class="btn btn-danger mr-3">Borrar Cupon</button></td> 
                        </tr>

                      </tbody>
                    </table>
            </div>
          </div>
    </div>
    </div>
    </div>

    <div style="  margin-left: 3%; margin-right: 3%;">
      <div *ngIf="anuncioCreado" class="container">
          <div class="alert alert-success" role="error"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
              ¡El anuncio se ha creado y es visible para todos los usuarios!
            </div>
          </div>
     </div>

    <div class="row">
      <div class="col-12 pt-3">
              <div class="panel panel-primary">
                <div class="panel-heading">Tablon anuncios</div>
                <div class="panel-body"><div>
                  <h3>Crea anuncios para los usuarios</h3>
                    <form (ngSubmit)="crearAnuncio()" class="needs-validation" novalidate>
                        <div class="form-row">
                          <div class="col-md-3 mb-3">
                            <label for="nombreTitulo">Titulo</label>
                            <input  [(ngModel)] = "nombreTitulo" name="nombreTitulo" type="text" class="form-control"  id="nombreTitulo" placeholder="Nombre titulo" >
    
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-9 mb-3">
                            <label for="textoTablon">Texto</label>
                            <textarea  [(ngModel)] = "textoTablon" name="textoTablon" class="form-control" name="textoTablon" id="textoTablon" placeholder="Escribe aqui la noticia"></textarea>
                          </div>
                        </div>
                        <div class="form-row">
                          <div class="col-md-9 mb-3">
                            <label for="enlace">Enlace (Añadir el http:// o https://)</label>
                            <input  [(ngModel)] = "enlace" name="enlace" type="text" class="form-control"  id="enlace" placeholder="Enlace al articulo " >
                          </div>
                        </div>
                          <div class="pt-3"> <button  class="btn btn-primary"  type="submit">Crear anuncio</button></div>
                      </form>
                </div>
              </div>
            </div>
      </div>
    </div>
    
</div>


<div style="  margin-left: 3%; margin-right: 3%;">
  <div *ngIf="borrarAnuncioAlert" class="container">
      <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
          ¡El anuncio ha sido eliminado!
        </div>
      </div>
 </div>

<div style="  margin-left: 3%; margin-right: 3%;">
  <div class="row">
    <div class="col-12">
            <div class="panel panel-primary">
              <div class="panel-heading">Anuncios Creados</div>
              <div class="panel-body"><div>
                  <table class="table table-hover">
                      <thead>
                        <tr>
                          <th scope="col">Id Anuncio</th>
                          <th scope="col">Titulo</th>
                          <th scope="col">Fecha</th>
                          <th>Accion</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr  *ngFor="let anuncio of listaAnuncios">
                          <td>{{anuncio.id}}</td>
                          <td>{{anuncio.titulo}}</td>
                          <td>{{anuncio.fechaCreacion | date:'dd/MM/yyyy'}}</td>
                          <td><button (click)="borrarAnuncio(anuncio.id)" type="button" class="btn btn-danger mr-3">Borrar Anuncio</button></td> 
                        </tr>

                      </tbody>
                    </table>
            </div>
          </div>
    </div>
    </div>
    </div>


   
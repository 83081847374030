import {Component,OnInit,Input} from '@angular/core';
import {HomeServiceService} from '../home-service.service';
import {resolve,async} from 'q';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {NgxPaginationModule} from 'ngx-pagination';
import {Title} from "@angular/platform-browser";
import {Observable} from 'rxjs';
import {AngularFireStorage} from '@angular/fire/storage';
import {finalize} from 'rxjs/operators';
import {FormBuilder,FormGroup,Validators} from '@angular/forms';
import {NgxSpinnerService} from "ngx-spinner";



@Component({
	selector: 'app-nuevo-progreso',
	templateUrl: './nuevo-progreso.component.html',
	styleUrls: ['./nuevo-progreso.component.css'],

})
export class NuevoProgresoComponent implements OnInit {


  
  
  
	uploadProgressEspalda: Observable < number > ;
	uploadProgressPefil: Observable < number > ;
	uploadProgressFrente: Observable < number > ;

	uploadURLEspalda: Observable < string > ;
	uploadURLPerfil: Observable < string > ;
	uploadURLFrente: Observable < string > ;

	public urlEspalda;
	public urlPerfil;
	public urlFrente;


	p: Number = 1;
	count: Number = 3;
	public fecha;
	public peso;
	public cintura = "0";
	public cadera = "0";
	public pecho = "0";
	public brazoDerecho = "0";
	public brazoIzquierdo = "0";
	public muslo = "0";
	public pantorrilla = "0";


	public evolucionEntrenamientos = false;
	public dataEvolucionEntrenamientoSinOrdenar = null;
  public dataEvolucionEntrenamiento = null;

	displayForm = false;
	datosGuardadosOK = false;
	datosError = false;
	public datosImagen = false;


	registerForm: FormGroup;
	submitted = false;
	constructor(private HomeService: HomeServiceService, private sanitizer: DomSanitizer, public router: Router, private titleService: Title, private _storage: AngularFireStorage, private formBuilder: FormBuilder, private spinner: NgxSpinnerService) {
		this.titleService.setTitle("AtlasFitness::Nuevo Progreso");
	}


	ngOnInit() {


		const data = new FormData();
		data.append('userId', sessionStorage.getItem('user_id2'));

		this.HomeService.tieneSuscripcion(data).subscribe((res: any) => {

			if (res == -1) {
				this.router.navigate(['suscripcion']);
			}

			if (res == 0) {
				this.router.navigate(['suscripcion']);
			}

		});

		this.registerForm = this.formBuilder.group({
			fecha: ['', Validators.required],
			peso: ['', Validators.required],
			frente: ['', null],
			perfil: ['', null],
      espalda: ['', null],
			cintura: ['', null],
			cadera: ['', null],
			pecho: ['', null],
			brazoDerecho: ['', null],
			brazoIzquierdo: ['', null],
			muslo: ['', null],
      pantorrilla: ['', null]

		});

		this.HomeService.getEvolucionEntrenamientos().subscribe((res: any) => {


				if (resolve.length > 0) {

  
				
					this.dataEvolucionEntrenamientoSinOrdenar = res;

          if( this.dataEvolucionEntrenamientoSinOrdenar.progresoUsuario.length > 0){
            this.evolucionEntrenamientos = true;
            this.dataEvolucionEntrenamientoSinOrdenar.progresoUsuario.sort(function compare(a, b) {
              var dateA = new Date( a.fechaProgreso);
              var dateB = new Date( b.fechaProgreso);
              return  dateB.valueOf() - dateA.valueOf() ;
            }); 

            this.dataEvolucionEntrenamiento =  this.dataEvolucionEntrenamientoSinOrdenar;
          }else{
            this.evolucionEntrenamientos = false;
            this.dataEvolucionEntrenamiento = res;
          }

         
				}

			},
			error => {
				sessionStorage.clear();
				this.router.navigate(['error']);
			});
	}

	get f() {
		return this.registerForm.controls;
	}
	mostrarForm() {

		this.displayForm = true;
	}

	cerrarForm() {

		this.displayForm = false;

	}

	public cargaFinFrente = true;

	uploadFrente(event) {
		this.cargaFinFrente = false;
		// Get input file
		const file = event.target.files[0];
		// Generate a random ID
		const randomId = Math.random().toString(36).substring(2);

		const filepath = `evolucionClientes/${randomId}`;
		const fileRef = this._storage.ref(filepath);
		// Upload image
		const task = this._storage.upload(filepath, file);
		// Observe percentage changes
		this.uploadProgressFrente = task.percentageChanges();
		// Get notified when the download URL is available
		task.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(url => {
					this.urlFrente = url;
					this.cargaFinFrente = true;
					// <-- do what ever you want with the url..
				});
			})
		).subscribe();
	}


	public cargaFinPerfil = true;

	uploadPerfil(event) {
		this.cargaFinPerfil = false;
		// Get input file
		const file = event.target.files[0];
		// Generate a random ID
		const randomId = Math.random().toString(36).substring(2);

		const filepath = `evolucionClientes/${randomId}`;
		const fileRef = this._storage.ref(filepath);
		// Upload image
		const task2 = this._storage.upload(filepath, file);
		// Observe percentage changes
		this.uploadProgressPefil = task2.percentageChanges();
		// Get notified when the download URL is available
		task2.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(url => {
					this.urlPerfil = url;
					this.cargaFinPerfil = true;
					// <-- do what ever you want with the url..
				});
			})
		).subscribe();
	}


	public cargaFinEspalda = true;

	uploadEspalda(event) {
		this.cargaFinEspalda = false;
		// Get input file
		const file = event.target.files[0];
		// Generate a random ID
		const randomId = Math.random().toString(36).substring(2);

		const filepath = `evolucionClientes/${randomId}`;
		const fileRef = this._storage.ref(filepath);
		// Upload image
		const task2 = this._storage.upload(filepath, file);
		// Observe percentage changes
		this.uploadProgressEspalda = task2.percentageChanges();
		// Get notified when the download URL is available
		task2.snapshotChanges().pipe(
			finalize(() => {
				fileRef.getDownloadURL().subscribe(url => {
					this.urlEspalda = url;
					this.cargaFinEspalda = true;
					// <-- do what ever you want with the url..
				});
			})
		).subscribe();
	}


	enviarProgreso() {


		this.spinner.show();
		this.datosImagen = false;
		this.datosError = false;
		this.datosGuardadosOK = false;
		// stop here if form is invalid
		if (this.registerForm.invalid) {
			this.datosError = true;
			this.spinner.hide();
			return;
		}

		if (!this.cargaFinEspalda && !this.cargaFinFrente && !this.cargaFinPerfil) {
			this.datosImagen = true;
			this.spinner.hide();
		} else {
			this.datosImagen = false;
			this.datosError = false;
			//SUBIDAS A FIREBASE
			const data = new FormData();
			data.append('peso', this.peso);
			data.append('fecha', this.fecha);
			data.append('fileToUploadFrente', this.urlFrente);
			data.append('fileToUploadPerfil', this.urlPerfil);
			data.append('fileToUploadEspalda', this.urlEspalda);
			data.append('cintura', this.cintura);
			data.append('cadera', this.cadera);
			data.append('pecho', this.pecho);
			data.append('brazoDerecho', this.brazoDerecho);
			data.append('brazoIzquierdo', this.brazoIzquierdo);
			data.append('muslo', this.muslo);
			data.append('pantorrilla', this.pantorrilla);


			this.HomeService.saveNuevoProgreso(data).subscribe((res: any) => {

					this.urlFrente = null;
					this.urlEspalda = null;
					this.urlPerfil = null;

					if (res.status === 'ok') {
						this.displayForm = false;
						this.datosGuardadosOK = true;
            this.ngOnInit();
            this.openPopup();

					} else {
						this.datosError = true;
					}
					this.spinner.hide();
				},
				error => {
					sessionStorage.clear();
					this.router.navigate(['error']);
					this.spinner.hide();
				});
		}

	}


  displayStyle = "none";
  encuestaEnviada = false;
  hayError = false;

  ratingDisplayEntrenamiento: number;
  ratingDisplayNutricion: number;
  
  onRatingSetEntrenamiento(rating: number): void {
    this.ratingDisplayEntrenamiento = rating;
  }

  onRatingSetNutricion(rating: number): void {
    this.ratingDisplayNutricion = rating;
  }

  openPopup() {
    this.encuestaEnviada = false;
    this.hayError = false;
    this.displayStyle = "block";
   
    
  }
  closePopup() {
    this.displayStyle = "none";
    this.ngOnInit();
    
  }

  enviarValoracion(){
    this.encuestaEnviada = false;
    this.hayError = false;
   

    var formData = new FormData(); // Currently empty
    formData.append('valoracion_entrenamiento',   this.ratingDisplayEntrenamiento.toString()+"/5");
    formData.append('valoracion_nutricion', this.ratingDisplayNutricion.toString()+"/5");


    this.HomeService.enviarEncuestaProgreso(formData).subscribe((res: any) => {
      this.encuestaEnviada = true;

    },
    error => {
      this.hayError = true;
    }); 
    

  }
       

public mostrarEncuesta(){
  
}

}
import { Component, OnInit } from '@angular/core';
import { HomeServiceService } from '../home-service.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Title} from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.css']
})
export class RegistroComponent implements OnInit {

  public nombre;
  public apellido1;
  public apellido2;
  public dni;
  public telefono;
  public email;
  public anioNacimiento;
  public altura;
  public pesoKilos;
  public pesoGramos;
  public objetivos="";
  public comentarios="";
  public contrasenia;
  public contrasenia2;

  public actividadFisicaDeseada;
  public suplementacion;
  public complexion;
  public sexo;

  datosGuardadosOK = false;
  datosError = false;
  usuarioRegistrado = false;


  public permiteSubirFotos = false;
  public checkPermiteRecibirNotificaciones = false;



  registerForm: FormGroup;
  submitted = false;
  constructor(private HomeService: HomeServiceService,  public router: Router, private formBuilder: FormBuilder,private titleService:Title,  private spinner: NgxSpinnerService) {
                
    this.titleService.setTitle("AtlasFitness::Registro");


   }

  ngOnInit() {
    this.spinner.show();
    this.registerForm = this.formBuilder.group({
      nombre: ['', Validators.required],
      apellido1: ['', Validators.required],
      apellido2:  ['', Validators.required],
      dni:   ['',[Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(9)]], 
      telefono:  ['',[Validators.required, Validators.pattern("^[0-9]*$"), Validators.maxLength(9)]], 
      email:  ['', [Validators.required, Validators.email]],
      anioNacimiento:  ['', Validators.required],
      altura:  ['',[Validators.required, Validators.pattern("^[0-9]*$")]], 
      pesoKilos:   ['',[Validators.required, Validators.pattern("^[0-9]*$")]], 
      pesoGramos:   ['',[Validators.required, Validators.pattern("^[0-9]*$")]], 
      complexion:  ['', null],
      actividadFisicaDeseada: ['', Validators.required],
      contrasenia: ['', [Validators.required, Validators.minLength(6)]],
      contrasenia2: ['', [Validators.required, Validators.minLength(6)]],
      suplementacion: ['', null],
      comentarios: ['', null],
      objetivos: ['', Validators.required],
      sexo: ['', Validators.required],
      permiteSubirFotos: ['', null],
      checkPermiteRecibirNotificaciones: ['', null]

      
  });
  this.spinner.hide();
  }

  get f() { return this.registerForm.controls; }


  onSubmit() {

    this.spinner.show();
    this.submitted = true;
    this.datosGuardadosOK = false;
    this.datosError = false;
    this.usuarioRegistrado = false;

    // stop here if form is invalid
    if (this.registerForm.invalid) {
      this.datosError = true;
      this.spinner.hide();
        return;
    }

    const data = new FormData();
    data.append('nombre', this.registerForm.value.nombre);
    data.append('apellido1', this.registerForm.value.apellido1);
    data.append('apellido2', this.registerForm.value.apellido2);
    data.append('dni', this.registerForm.value.dni);
    data.append('telefono', this.registerForm.value.telefono);
    data.append('email', this.registerForm.value.email);
    data.append('anioNacimiento', this.registerForm.value.anioNacimiento);
    data.append('altura', this.registerForm.value.altura);
    data.append('pesoKilos', this.registerForm.value.pesoKilos);
    data.append('pesoGramos', this.registerForm.value.pesoGramos);
    data.append('objetivos', this.registerForm.value.objetivos);
    data.append('comentarios', this.registerForm.value.comentarios);
    data.append('contrasenia', this.registerForm.value.contrasenia);
    data.append('contrasenia2', this.registerForm.value.contrasenia2);
    data.append('actividadFisicaDeseada', this.registerForm.value.actividadFisicaDeseada);
    data.append('suplementacion', this.registerForm.value.suplementacion);
    data.append('complexion', this.registerForm.value.complexion);
    data.append('sexo', this.registerForm.value.sexo);
    data.append('checkPermiteRecibirNotificaciones',new Boolean(this.registerForm.value.checkPermiteRecibirNotificaciones).toString());
    data.append('permiteSubirFotos',new Boolean(this.registerForm.value.permiteSubirFotos).toString());

    this.HomeService.registroUsuario(data).subscribe((res: any) => {
     
      if(res){
        this.datosGuardadosOK = true;
        window.scroll(0,0);

      }else{
        this.usuarioRegistrado = true;
        window.scroll(0,0);
       
      }
      this.spinner.hide();
    
    },
    error => {
      this.datosError = true;

      this.spinner.hide();
    });
   
   
}
irLogin(){
  this.router.navigate(['login']);
}  


seleccionarPlan(){
 this.spinner.show();
  (this.HomeService.authenticate(this.email, this.contrasenia).subscribe(
    data => {
      this.router.navigate(['suscripcion']);
      this.spinner.hide();

    },
    error => {
      this.spinner.hide();
      sessionStorage.clear();
      this.router.navigate(['inicio']);
     
    }
  )
  );
 
}
atras() {
  this.router.navigate(['login']);
}


}

import { Component, OnInit } from '@angular/core';
import {Chart} from 'chart.js';
import { HomeServiceService } from '../home-service.service';
import { resolve } from 'q';
import { NgxPaginationModule } from 'ngx-pagination';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";
import { AngularFireStorage  } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable } from 'rxjs';
import * as Highcharts from 'highcharts';


@Component({
  selector: 'app-editar-perfil-usuario-administrador',
  templateUrl: './editar-perfil-usuario-administrador.component.html',
  styleUrls: ['./editar-perfil-usuario-administrador.component.css'],
  template: `
  <div [chart]="chartMacros"></div>
`
})
export class EditarPerfilUsuarioAdministradorComponent implements OnInit {

  p: Number = 1;
  count: Number = 1;
  public nombre;
  public apellido1;
  public apellido2;
  public dni;
  public telefono;
  public email;
  public anioNacimiento;
  public altura;
  public pesoKilos;
  public pesoGramos;
  public objetivos;
  public comentarios;
  public contrasenia;
  public contrasenia2;

  public actividadFisicaDeseada;
  public suplementacion;
  public complexion;
  public evolucionEntrenamientos = false;
  public dataEvolucionEntrenamiento;

  public tipoCuenta;
  public fechaFinLicencia;
  datosActualizados = false;
  datosActualizadosPerfil = false;
  rutinaAdjuntada = false;

  public notasEntrenador;

  chart = [];

  uploadProgressRutina: Observable<number>;
  uploadURLRutina: Observable<string>;
  public urlRutina;
  public fechaDisponible;

  public fechaProximaRevision;

  uploadProgressDieta: Observable<number>;
  uploadURLDieta: Observable<string>;
  public urlDieta;

  public notaEntrenadorGuardada = false;

  public entrenamientos=[];
  public dietas=[];
  


  
  chartMedidas = [];
  public dataMedidas;

  public arrayCintura = [];
  public arrayCadera = [];
  public arrayPecho = [];
  public arrayBrazoDerecho = [];
  public arrayBrazoIzquierdo = [];
  public arrayMuslo = [];
  public arrayPantorrilla = [];
  public arrayFechaProgreso = [];

  public rutinaEliminada = false;


  public docBienvenida = false;
  uploadProgressDocBienve: Observable<number>;
  public urlDoc;

  constructor(private HomeService: HomeServiceService, public router: Router,private titleService:Title, private _storage: AngularFireStorage) {
    this.titleService.setTitle("AtlasFitness::Editar perfil adminisitracion");
   }

  ngOnInit() {

    const data2 = new FormData();
    data2.append('user_id2',sessionStorage.getItem('user_id2'));
    this.HomeService.esSuper(data2).subscribe((res: any) => {

      if(res == null){
        sessionStorage.clear();
        this.router.navigate(['login']);
      }
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });

    const dataNotas = new FormData();
    dataNotas.append('user_id',sessionStorage.getItem('user_id'));
    this.HomeService.obtenerNotasEntrenador(dataNotas).subscribe((res: any) => {
     if(res!=null){
      this.notasEntrenador =  res.notas;
     }
   
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });

 
  

    const data = new FormData();
    data.append('user_id', sessionStorage.getItem('user_id'));
    
    this.HomeService.getUserProfileAdministratorSide(data).subscribe((res: any)  => {
     if (resolve.length > 0) {
      this.evolucionEntrenamientos = true;
      this.dataEvolucionEntrenamiento = res.progresoUsuario;
    }

     this.cargarMacrosUsuario(res.email);

      this.nombre = res.nombre;
      this.apellido1 = res.apellido1;
      this.apellido2 = res.apellido2;
      this.dni = res.dni;
      this.telefono = res.telefono;
      this.email = res.email;
      this.anioNacimiento = res.anioNacimiento;
      this.altura = res.altura;
      this.pesoKilos = res.pesoInicialKilos;
      this.pesoGramos = res.pesoInicialGramos;
      this.complexion = res.complexion;
      this.actividadFisicaDeseada = res.actividadFisicaDeseada;
      this.objetivos = res.objetivosText;
      this.comentarios = res.comentariosText;
      this.suplementacion = res.suplementacion;
      this.contrasenia = res.contrasenia1;
      this.contrasenia2 = res.contrasenia2;
      this.tipoCuenta = res.tipoCuenta;
      this.fechaFinLicencia = res.fechaFinLicencia;
      this.urlDoc = res.cuestionarioBienvenida;
   
    

    this.chart = new Chart ('canvas', {
      type: 'line',
      data: {
          labels:  res.pesoEvolucionMes,
          datasets: [{
              label: '% de peso',
              data: res.pesoEvolucion,
              borderColor: '#80b6f4',
              pointBorderColor: '#80b6f4',
              pointBackgroundColor: '#80b6f4',
              pointHoverBackgroundColor: '#80b6f4',
              pointHoverBorderColor: '#80b6f4',
              pointBorderWidth: 10,
              pointHoverRadius: 10,
              pointHoverBorderWidth: 1,
              pointRadius: 3,
              fill: false,
              borderWidth: 4,
          }]
      },
      options: {
          scales: {
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          }
      }
  });
},
error => {
 sessionStorage.clear();
  this.router.navigate(['error']); 
});


const dataPreparaciones = new FormData();
dataPreparaciones.append('user_id',sessionStorage.getItem('user_id'));
this.HomeService.obtenerPreparacionesRutinaPerfilAdministrador(dataPreparaciones).subscribe((res: any) => {


 this.entrenamientos = res;
  },
  error => {
   sessionStorage.clear();
    this.router.navigate(['error']); 
  });
  

  this.HomeService.obtenerPreparacionesDietaPerfilAdministrador(dataPreparaciones).subscribe((res: any) => {

   
   this.dietas = res;
     },
     error => {
      sessionStorage.clear();
       this.router.navigate(['error']); 
     });

     const dataMedidas = new FormData();
     dataMedidas.append('user_id',   sessionStorage.getItem('user_id'));
     this.HomeService.getEvolucionEntrenamientosAdmin(dataMedidas).subscribe((res: any) => {
      if (resolve.length > 0) {
        this.dataMedidas = res.progresoUsuario;

        this.dataMedidas.forEach( elemento => {// recorremos cada elemento del array

          this.arrayCintura.push(elemento.cintura);
          this.arrayCadera.push(elemento.cadera);
          this.arrayPecho.push(elemento.pecho);
          this.arrayBrazoDerecho.push(elemento.brazoDerecho);
          this.arrayBrazoIzquierdo.push(elemento.brazoIzquierdo);
          this.arrayMuslo.push(elemento.muslo);
          this.arrayPantorrilla.push(elemento.pantorrilla);
          this.arrayFechaProgreso.push(elemento.fechaProgreso);
             
        });  
        this.chartMedidas = new Chart ('canvas2', {
          type: 'line',
          data: {
              labels: this.arrayFechaProgreso,
              datasets: [{
                  label: ['Cintura',],
                  data: this.arrayCintura,
                  borderColor: '#80b6f4',
                  pointBorderColor: '#80b6f4',
                  pointBackgroundColor: '#80b6f4',
                  pointHoverBackgroundColor: '#80b6f4',
                  pointHoverBorderColor: '#80b6f4',
                  pointBorderWidth: 10,
                  pointHoverRadius: 10,
                  pointHoverBorderWidth: 1,
                  pointRadius: 3,
                  fill: false,
                  borderWidth: 4,
              },{
                label:  ['Cadera'],
                data: this.arrayCadera,
                borderColor: '#6AF7E8',
                pointBorderColor: '#6AF7E8',
                pointBackgroundColor: '#6AF7E8',
                pointHoverBackgroundColor: '#6AF7E8',
                pointHoverBorderColor: '#6AF7E8',
                pointBorderWidth: 10,
                pointHoverRadius: 10,
                pointHoverBorderWidth: 1,
                pointRadius: 3,
                fill: false,
                borderWidth: 4,
            },{
              label:  ['Pecho'],
              data:  this.arrayPecho,
              borderColor: '#6AF78A',
              pointBorderColor: '#6AF78A',
              pointBackgroundColor: '#6AF78A',
              pointHoverBackgroundColor: '#6AF78A',
              pointHoverBorderColor: '#6AF78A',
              pointBorderWidth: 10,
              pointHoverRadius: 10,
              pointHoverBorderWidth: 1,
              pointRadius: 3,
              fill: false,
              borderWidth: 4,
          },{
                label:  ['Brazo Derecho'],
                data: this.arrayBrazoDerecho,
                borderColor: '#B06AF7',
                pointBorderColor: '#B06AF7',
                pointBackgroundColor: '#B06AF7',
                pointHoverBackgroundColor: '#B06AF7',
                pointHoverBorderColor: '#B06AF7',
                pointBorderWidth: 10,
                pointHoverRadius: 10,
                pointHoverBorderWidth: 1,
                pointRadius: 3,
                fill: false,
                borderWidth: 4,
            },{
              label:  ['Brazo Izquierdo'],
              data: this.arrayBrazoIzquierdo,
              borderColor: '#6A97F7',
              pointBorderColor: '#6A97F7',
              pointBackgroundColor: '#6A97F7',
              pointHoverBackgroundColor: '#6A97F7',
              pointHoverBorderColor: '#6A97F7',
              pointBorderWidth: 10,
              pointHoverRadius: 10,
              pointHoverBorderWidth: 1,
              pointRadius: 3,
              fill: false,
              borderWidth: 4,
          },{
            label:  ['Muslo'],
            data: this.arrayMuslo,
            borderColor: '#F7F76A',
            pointBorderColor: '#F7F76A',
            pointBackgroundColor: '#F7F76A',
            pointHoverBackgroundColor: '#F7F76A',
            pointHoverBorderColor: '#F7F76A',
            pointBorderWidth: 10,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            borderWidth: 4,
        },{
          label:  ['Pantorrilla'],
          data: this.arrayPantorrilla,
          borderColor: '#F7B56A',
          pointBorderColor: '#F7B56A',
          pointBackgroundColor: '#F7B56A',
          pointHoverBackgroundColor: '#F7B56A',
          pointHoverBorderColor: '#F7B56A',
          pointBorderWidth: 10,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 1,
          pointRadius: 3,
          fill: false,
          borderWidth: 4,
      }],
          },
          options: {
              scales: {
                  yAxes: [{
                      ticks: {
                          beginAtZero: true
                      }
                  }]
              }
          }
      });
      }

    
    });


 }

  
  
 


  public updateDatosLicencia() {
    const data = new FormData();
    data.append('user_id',sessionStorage.getItem('user_id'));
    data.append('tipoCuenta', this.tipoCuenta);
    data.append('fechaFinLicencia', this.fechaFinLicencia);

    this.HomeService.updateLicenciaManual(data).subscribe(Response => {
      this.datosActualizados= true;
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });

  }


  public saveFormPerfilUsuario() {

    const dataAD = new FormData();
    dataAD.append('nombre',this.nombre);
    dataAD.append('apellido1',this.apellido1);
    dataAD.append('apellido2',this.apellido2);
    dataAD.append('dni',this.dni);
    dataAD.append('telefono',this.telefono);
    dataAD.append('email',this.email);
    dataAD.append('anioNacimiento', this.anioNacimiento);
    dataAD.append('altura', this.altura);
    dataAD.append('pesoKilos',this.pesoKilos);
    dataAD.append('pesoGramos',this.pesoGramos);
    dataAD.append('objetivos',this.objetivos);
    dataAD.append('comentarios',this.comentarios);
    dataAD.append('actividadFisicaDeseada',this.actividadFisicaDeseada);
    dataAD.append('suplementacion',this.suplementacion);
    dataAD.append('complexion',this.complexion);
    dataAD.append('user_id',sessionStorage.getItem('user_id'));
  
    this.HomeService.savePerfilUsuarioDesdeAdmin(dataAD).subscribe(Response => {

      this.datosActualizadosPerfil= true;
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });
     
  }

  public saveFormPerfilUsuarioDocBienvenida(urlDoc) {
    const dataAD = new FormData();
    dataAD.append('nombre',this.nombre);
    dataAD.append('apellido1',this.apellido1);
    dataAD.append('apellido2',this.apellido2);
    dataAD.append('dni',this.dni);
    dataAD.append('telefono',this.telefono);
    dataAD.append('email',this.email);
    dataAD.append('anioNacimiento', this.anioNacimiento);
    dataAD.append('altura', this.altura);
    dataAD.append('pesoKilos',this.pesoKilos);
    dataAD.append('pesoGramos',this.pesoGramos);
    dataAD.append('objetivos',this.objetivos);
    dataAD.append('comentarios',this.comentarios);
    dataAD.append('actividadFisicaDeseada',this.actividadFisicaDeseada);
    dataAD.append('suplementacion',this.suplementacion);
    dataAD.append('complexion',this.complexion);
    dataAD.append('user_id',sessionStorage.getItem('user_id'));
    dataAD.append('urlDocBienvenida', urlDoc)
  
    this.HomeService.savePerfilUsuarioDesdeAdmin(dataAD).subscribe(Response => {

      this.datosActualizadosPerfil= true;
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });
     
  }
 


  uploadRutina(event) {
    // Get input file
    const file = event.target.files[0];
    // Generate a random ID
    const randomId = Math.random().toString(36).substring(2);
    const filepath = `rutinasClientes/${randomId}`;
    const fileRef = this._storage.ref(filepath);
    // Upload image
    const task2 = this._storage.upload(filepath, file);
    // Observe percentage changes
    this.uploadProgressRutina = task2.percentageChanges();
    // Get notified when the download URL is available
    task2.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(url => {
          this.urlRutina = url;
        });
      })
    ).subscribe();
  }



  uploadDieta(event) {
    // Get input file
    const file = event.target.files[0];
    // Generate a random ID
    const randomId = Math.random().toString(36).substring(2);
    const filepath = `dietasClientes/${randomId}`;
    const fileRef = this._storage.ref(filepath);
    // Upload image
    const task2 = this._storage.upload(filepath, file);
    // Observe percentage changes
    this.uploadProgressDieta = task2.percentageChanges();
    // Get notified when the download URL is available
    task2.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(url => {
          this.urlDieta = url;
        });
      })
    ).subscribe();
  }



  public adjuntarRutinaYdieta(){

     //SUBIDAS A FIREBASE
  const data = new FormData();
  data.append('rutina', this.urlRutina);
  data.append('dieta', this.urlDieta);
  data.append('fechaDisponible', this.fechaDisponible);
  data.append('fechaProximaRevision', this.fechaProximaRevision);
  data.append('user_id',sessionStorage.getItem('user_id'));

  this.HomeService.actualizardietayrutina(data).subscribe(Response => {

    this.rutinaAdjuntada= true;

    const dataPreparaciones = new FormData();
dataPreparaciones.append('user_id',sessionStorage.getItem('user_id'));
this.HomeService.obtenerPreparacionesRutinaPerfilAdministrador(dataPreparaciones).subscribe((res: any) => {
 this.entrenamientos = res;
  },
  error => {
   sessionStorage.clear();
    this.router.navigate(['error']); 
  });
  

  this.HomeService.obtenerPreparacionesDietaPerfilAdministrador(dataPreparaciones).subscribe((res: any) => {
   this.dietas = res;
     },
     error => {
      sessionStorage.clear();
       this.router.navigate(['error']); 
     });

  },
  error => {
   sessionStorage.clear();
    this.router.navigate(['error']); 
  });
}


public guardarNotas(){
  this.notaEntrenadorGuardada = false;

  const data = new FormData();


  data.append('notaTexto', this.notasEntrenador);
  data.append('user_id',sessionStorage.getItem('user_id'));

  
  this.HomeService.guardarNotasEntrenador(data).subscribe(Response => {

    this.notaEntrenadorGuardada=true;
  },
  error => {
   sessionStorage.clear();
    this.router.navigate(['error']); 
  });


}

public eliminarRutina(id_rutina, fecha_rutina){

  const data = new FormData();
  data.append('user_id',sessionStorage.getItem('user_id'));
  data.append('id_rutina', id_rutina);
  data.append('fecha_rutina', fecha_rutina);


  this.HomeService.eliminarRutina(data).subscribe(Response => {
    const dataPreparaciones = new FormData();
    dataPreparaciones.append('user_id',sessionStorage.getItem('user_id'));
    this.rutinaEliminada = true;
    this.HomeService.obtenerPreparacionesRutinaPerfilAdministrador(dataPreparaciones).subscribe((res: any) => {
   
     this.entrenamientos = res;
      },
      error => {
       sessionStorage.clear();
        this.router.navigate(['error']); 
      });
    
  },
  error => {
   sessionStorage.clear();
    this.router.navigate(['error']); 
  });
  
}



public eliminarDieta(id_dieta){

  const data = new FormData();
  data.append('user_id',sessionStorage.getItem('user_id'));
  data.append('id_dieta', id_dieta);


  this.HomeService.eliminarDieta(data).subscribe(Response => {
    const dataPreparaciones = new FormData();
    dataPreparaciones.append('user_id',sessionStorage.getItem('user_id'));
    this.rutinaEliminada = true;
    this.HomeService.obtenerPreparacionesDietaPerfilAdministrador(dataPreparaciones).subscribe((res: any) => {
      this.dietas = res;
        },
        error => {
         sessionStorage.clear();
          this.router.navigate(['error']); 
        });
   
    
  },
  error => {
   sessionStorage.clear();
    this.router.navigate(['error']); 
  });


}



public adjuntoBienvenida(event){

  const file = event.target.files[0];
  // Generate a random ID
  const randomId = Math.random().toString(36).substring(2);
 
  const filepath = `docsBienvenidas/${randomId}`;
  const fileRef = this._storage.ref(filepath);
  // Upload image
  const task2 = this._storage.upload(filepath, file);
  // Observe percentage changes
  this.uploadProgressDocBienve = task2.percentageChanges();
  // Get notified when the download URL is available
  task2.snapshotChanges().pipe(
    finalize(() => {
      fileRef.getDownloadURL().subscribe(url => {
        this.urlDoc = url;
        this.docBienvenida = true;
        this.saveFormPerfilUsuarioDocBienvenida(url);
        // <-- do what ever you want with the url..
      });
    })
  ).subscribe();

}


  chartMacros: Chart;
  public tmb;
  public tmbConObjetivos;
  public repartoMacrosHc;
  public repartoMacrosPr;
  public repartoMacrosGr;
  public caloriasCalculadasHcShow;
  public caloriasCalculadasPrShow;
  public caloriasCalculadasGrShow;
  public objetivos_macros;
  public showError = false;


  public gramosHcF;
  public gramosPrF;
  public gramosGrF;

cargarMacrosUsuario(email){


  this.HomeService.getHomeDataAdminMacros(email).subscribe((res: any) => {
    var today = new Date();
    var year = today.getFullYear();
    var continuar = true;
    var altura = Number(res.altura);
    var edadActual =year - Number(res.anioNacimiento.split("-")[0]);
    var pesoKilosActual =res.pesoKilos;
    var sexo = res.sexo;
    var tmb;
   // this.objetivos_macros = res.objetivosText.toLowerCase();


    if(sexo == 'hombre'){
      tmb = 66 + (13.7 * pesoKilosActual) + (5 * altura ) - (6.8 * edadActual);
      this.tmb = tmb.toFixed(2);
     
    }else if(sexo == 'mujer'){
      tmb = 655 + (9.6 * pesoKilosActual) + (1.8 * altura) - (4.7 * edadActual);
      this.tmb=tmb.toFixed(2);
    }else{
      //error actualiza tu sexo
      continuar = false;
 
    }

    var hc;
    var pr;
    var gr;

    var caloriasCalculadasHc;
    var caloriasCalculadasPr;
    var caloriasCalculadasGr;

    if(continuar){
      if(res.objetivosText == 'Ganar masa muscular'){
        hc = 0.55;
        pr = 0.35;
        gr = 0.10;
        tmb = tmb + 500;

        this.repartoMacrosGr = gr;
        this.repartoMacrosHc = hc;
        this.repartoMacrosPr = pr;
        this.tmbConObjetivos = tmb.toFixed(2);
      }else if(res.objetivosText == 'Definición muscular'){
        hc = 0.25;
        pr = 0.60;
        gr = 0.15;
        tmb = tmb - 500;
        this.repartoMacrosGr = gr;
        this.repartoMacrosHc = hc;
        this.repartoMacrosPr = pr;
        this.tmbConObjetivos = tmb.toFixed(2);
      }else if(res.objetivosText == 'Pérdida de peso'){
        hc = 0.35;
        pr = 0.55;
        gr = 0.10;
        tmb = tmb - 300;
        this.repartoMacrosGr = gr;
        this.repartoMacrosHc = hc;
        this.repartoMacrosPr = pr;
        this.tmbConObjetivos = tmb.toFixed(2);
      }else if(res.objetivosText == 'Mantener el peso'){
        hc = 0.40;
        pr = 0.45;
        gr = 0.15;
        tmb = tmb + 200;
        this.repartoMacrosGr = gr;
        this.repartoMacrosHc = hc;
        this.repartoMacrosPr = pr;
        this.tmbConObjetivos = tmb.toFixed(2);
      }

      caloriasCalculadasHc = tmb * hc;
      caloriasCalculadasPr =  tmb * pr
      caloriasCalculadasGr = tmb * gr;

      this.caloriasCalculadasGrShow = caloriasCalculadasGr.toFixed(2);
      this.caloriasCalculadasHcShow = caloriasCalculadasHc.toFixed(2);
      this.caloriasCalculadasPrShow = caloriasCalculadasPr.toFixed(2);

      var gramosHc = Number((caloriasCalculadasHc / 4).toFixed(2));
      var gramosPr =  Number((caloriasCalculadasPr / 4).toFixed(2));
      var gramosGr =   Number((caloriasCalculadasGr / 9).toFixed(2));

      this.gramosGrF = gramosGr;
      this.gramosHcF = gramosHc;
      this.gramosPrF = gramosPr;

      Highcharts.chart('container', {
        chart: {
            type: 'pie',
            options3d: {
                enabled: true,
                alpha: 45,
                beta: 0
            }
        },
        title: {
            text: 'Reparto macronutrientes en gramos'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.y} gr</b>'
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                depth: 35,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}'
                }
            }
        },
        series: [{
            type: 'pie',
            name: 'Gramos',
            data: [
                ['Proteinas', gramosPr],
                ['Hidratos de carbono', gramosHc ],
                ['Grasas', gramosGr]
            ]
        }]
    });

    }


    console.log(pesoKilosActual);

   }, error => {
 
    sessionStorage.clear();
     this.router.navigate(['error']); 
   });


}

}

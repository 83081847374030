import { Component, OnInit } from '@angular/core';
import { HomeServiceService } from '../home-service.service';
import { Router } from '@angular/router';
import {Title} from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";
import { Observable } from 'rxjs';
import { AngularFireStorage  } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-perfil-usuario',
  templateUrl: './perfil-usuario.component.html',
  styleUrls: ['./perfil-usuario.component.css']
})
export class PerfilUsuarioComponent implements OnInit {
  public nombre;
  public apellido1;
  public apellido2;
  public dni;
  public telefono;
  public email;
  public anioNacimiento;
  public altura;
  public pesoKilos;
  public pesoGramos;
  public objetivos;
  public comentarios;
  public contrasenia;
  public contrasenia2;

  public actividadFisicaDeseada;
  public suplementacion;
  public complexion;
  public sexo;

  public docBienvenida = false;
  uploadProgressDocBienve: Observable<number>;
  public urlDoc;


  public permiteSubirFotos = false;
  public checkPermiteRecibirNotificaciones = false;


  datosGuardadosOK = false;



  constructor(private HomeService: HomeServiceService, public router: Router, private titleService:Title, private spinner: NgxSpinnerService, private _storage: AngularFireStorage) {   
    
    this.titleService.setTitle("AtlasFitness:: Perfil"); 

}

  ngOnInit() {
 
  
    this.datosGuardadosOK = false;

    this.HomeService.getPerfilUsuarioOnload().subscribe((res: any) => {
      this.nombre = res.nombre;
      this.apellido1 = res.apellido1;
      this.apellido2 = res.apellido2;
      this.dni = res.dni;
      this.telefono = res.telefono;
      this.email = res.email;
      this.anioNacimiento = res.anioNacimiento;
      this.altura = res.altura;
      this.pesoKilos = res.pesoInicialKilos;
      this.pesoGramos = res.pesoInicialGramos;
      this.complexion = res.complexion;
      this.actividadFisicaDeseada = res.actividadFisicaDeseada;
      this.objetivos = res.objetivosText;
      this.comentarios = res.comentariosText;
      this.suplementacion = res.suplementacion;
      this.contrasenia = res.contrasenia1;
      this.contrasenia2 = res.contrasenia2;
      this.sexo = res.sexo;
      this.docBienvenida = res.cuestionarioBienvenida;
      this.checkPermiteRecibirNotificaciones = res.checkPermiteRecibirNotificaciones;
      this.permiteSubirFotos = res.checkPermiteSubirFotos;

   
  },
  error => {
    
   sessionStorage.clear();
  
    this.router.navigate(['error']); 
  });
  }


  public saveFormPerfilUsuario() {

    this.spinner.show();
    this.datosGuardadosOK = false;
    console.log(this.sexo);
    const data = new FormData();
    data.append('nombre',this.nombre);
    data.append('apellido1',this.apellido1);
    data.append('apellido2',this.apellido2);
    data.append('dni',this.dni);
    data.append('telefono',this.telefono);
    data.append('email',this.email);
    data.append('anioNacimiento', this.anioNacimiento);
    data.append('altura', this.altura);
    data.append('pesoKilos',this.pesoKilos);
    data.append('pesoGramos',this.pesoGramos);
    data.append('objetivos',this.objetivos);
    data.append('comentarios',this.comentarios);
    data.append('contrasenia',this.contrasenia);
    data.append('contrasenia2',this.contrasenia2);
    data.append('actividadFisicaDeseada',this.actividadFisicaDeseada);
    data.append('suplementacion',this.suplementacion);
    data.append('complexion',this.complexion);
    data.append('sexo', this.sexo);
    data.append('checkPermiteRecibirNotificaciones',new Boolean(this.checkPermiteRecibirNotificaciones).toString());
    data.append('permiteSubirFotos',new Boolean(this.permiteSubirFotos).toString());
     

    this.HomeService.savePerfilUsuarioUsuario(data).subscribe((res: any) => {
      this.ngOnInit();
      this.datosGuardadosOK = true;
      this.spinner.hide();
      window.scroll(0,0);
  },
  error => {
    sessionStorage.clear();
    this.spinner.hide();
    this.router.navigate(['error']);
  });
  }

  public saveFormPerfilUsuarioDoc(url) {
    this.spinner.show();
    this.datosGuardadosOK = false;
    console.log(this.sexo);
    const data = new FormData();
    data.append('nombre',this.nombre);
    data.append('apellido1',this.apellido1);
    data.append('apellido2',this.apellido2);
    data.append('dni',this.dni);
    data.append('telefono',this.telefono);
    data.append('email',this.email);
    data.append('anioNacimiento', this.anioNacimiento);
    data.append('altura', this.altura);
    data.append('pesoKilos',this.pesoKilos);
    data.append('pesoGramos',this.pesoGramos);
    data.append('objetivos',this.objetivos);
    data.append('comentarios',this.comentarios);
    data.append('contrasenia',this.contrasenia);
    data.append('contrasenia2',this.contrasenia2);
    data.append('actividadFisicaDeseada',this.actividadFisicaDeseada);
    data.append('suplementacion',this.suplementacion);
    data.append('complexion',this.complexion);
    data.append('sexo', this.sexo);
    data.append('urlDocBienvenida', url);
    data.append('checkPermiteRecibirNotificaciones',new Boolean(this.checkPermiteRecibirNotificaciones).toString());
    data.append('permiteSubirFotos',new Boolean(this.permiteSubirFotos).toString());
  
     

    this.HomeService.savePerfilUsuarioUsuario(data).subscribe((res: any) => {
      this.ngOnInit();
      this.datosGuardadosOK = true;
      this.spinner.hide();
      window.scroll(0,0);
  },
  error => {
    sessionStorage.clear();
    this.spinner.hide();
    this.router.navigate(['error']);
  });
  }


  public adjuntoBienvenida(event){

    const file = event.target.files[0];
    // Generate a random ID
    const randomId = Math.random().toString(36).substring(2);
   
    const filepath = `docsBienvenidas/${randomId}`;
    const fileRef = this._storage.ref(filepath);
    // Upload image
    const task2 = this._storage.upload(filepath, file);
    // Observe percentage changes
    this.uploadProgressDocBienve = task2.percentageChanges();
    // Get notified when the download URL is available
    task2.snapshotChanges().pipe(
      finalize(() => {
        fileRef.getDownloadURL().subscribe(url => {
          this.urlDoc = url;
          this.docBienvenida = true;
          this.saveFormPerfilUsuarioDoc(url);
          // <-- do what ever you want with the url..
        });
      })
    ).subscribe();

  }

}

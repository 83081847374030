<div *ngIf="datosGuardadosOK" class="container">
  <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
     <span aria-hidden="true">&times;</span>
     </button>
     Datos guardados correctamente
  </div>
</div>
<div *ngIf="datosError" class="container">
  <div class="alert alert-danger" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
     <span aria-hidden="true">&times;</span>
     </button>
     Error al guardar los datos
  </div>
</div>
<div *ngIf="datosImagen" class="container">
  <div class="alert alert-danger" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
     <span aria-hidden="true">&times;</span>
     </button>
     Espera a que se suban las fotos
  </div>
</div>
<div style="  margin-left: 3%; margin-right: 3%;">
  <div class="row">
     <div class="col-12">
        <div class="panel panel-primary">
           <div class="panel-heading">Nuevo Progreso </div>
           <div class="panel-body">
              <div>
                 <button (click)="mostrarForm()" type="button" class="btn btn-primary">Nuevo progreso</button>
              </div>
           </div>
        </div>
     </div>
  </div>
</div>
<div *ngIf="displayForm" style="  margin-left: 3%; margin-right: 3%;">
  <div class="row">
     <div class="col-12">
        <div class="panel panel-primary">
           <div class="panel-heading">Registrar progreso </div>
           <div class="panel-body">
              <div>
                 <form [formGroup]="registerForm" (ngSubmit)="enviarProgreso()">
                 <div class="form-group row">
                    <label for="fecha" class="col-sm-2 col-form-label">Fecha*</label>
                    <div class="col-sm-10">
                       <input [(ngModel)] = "fecha" name="fecha" type="date" class="form-control" formControlName="fecha" id="fecha" placeholder="Introduce la fecha de las fotos" [ngClass]="{ 'is-invalid': submitted && f.fecha.errors }">
                       <div *ngIf="submitted && f.fecha.errors" class="invalid-feedback">
                          <div *ngIf="f.fecha.errors.required">Campo fecha obligatorio</div>
                       </div>
                    </div>
                 </div>
                 <div class="form-group row">
                    <label for="peso" class="col-sm-2 col-form-label">Peso*</label>
                    <div class="col-sm-10">
                       <input [(ngModel)] = "peso" name="peso" type="number" class="form-control"  formControlName="peso" id="peso" placeholder="Introduce tu peso actual" [ngClass]="{ 'is-invalid': submitted && f.peso.errors }" >
                       <div *ngIf="submitted && f.peso.errors" class="invalid-feedback">
                          <div *ngIf="f.peso.errors.required">Campo peso obligatorio</div>
                       </div>
                    </div>
                 </div>
                 <div class="form-group row">
                    <label for="fileFrente" class="col-sm-2 col-form-label">Foto de frente</label>
                    <div class="col-md-4 mb-6">
                       <div class="form-group">
                          <div class="item">
                             Progreso: {{ (uploadProgressFrente | async) / 100 | percent }}
                          </div>
                          <!-- <input accept="image/*" type="file" class="form-control-file" id="exampleFormControlFile1" (change)="handleFileInputFrente($event.target.files)"> -->
                          <input type="file"  formControlName="frente" (change)="uploadFrente($event)" accept="image/*"> 
                       </div>
                    </div>
                 </div>
                 <div class="form-group row">
                    <label for="filePerfil" class="col-sm-2 col-form-label">Foto de perfil</label>
                    <div class="col-sm-10">
                       <div class="form-group">
                          <div class="item">
                             Progreso: {{ (uploadProgressPefil | async) / 100 | percent }}
                          </div>
                          <!--<input accept="image/*" type="file" class="form-control-file" id="exampleFormControlFile1" (change)="handleFileInputPerfil($event.target.files)"> -->
                          <input type="file" formControlName="perfil" (change)="uploadPerfil($event)" accept="image/*"> 
                       </div>
                    </div>
                 </div>
                 <div class="form-group row">
                    <label for="fileEspalda" class="col-sm-2 col-form-label">Foto de espaldas</label>
                    <div class="col-sm-10">
                       <div class="form-group">
                          <div class="item">
                             Progreso: {{ (uploadProgressEspalda | async) / 100 | percent }}
                          </div>
                          <!-- <input accept="image/*" type="file" class="form-control-file" id="exampleFormControlFile1" (change)="handleFileInputEspalda($event.target.files)"> -->
                          <input type="file" formControlName="espalda" (change)="uploadEspalda($event)" accept="image/*"> 
                       </div>
                    </div>
                 </div>
                 <div class="panel panel-info">
                    <!-- Default panel contents -->
                    <div class="panel-heading">Medidas a tomar</div>
                    <!-- Table -->
                    <table class="table">
                       <tr>
                          <th>Pilometria</th>
                          <th>Perimetros (Cm)</th>
                          <th>Medida</th>
                       </tr>
                       <tr>
                          <td>Pliege abdominal</td>
                          <td>Cintura</td>
                          <td><input [(ngModel)] = "cintura" name="cintura" id="cintura" type="number" class="form-control" formControlName="cintura"></td>
                       </tr>
                       <tr>
                          <td>Suprailiaco</td>
                          <td>Cadera</td>
                          <td><input [(ngModel)] = "cadera" name="cadera" id="cadera" type="number" class="form-control" formControlName="cadera"></td>
                       </tr>
                       <tr>
                          <td>Subescapular</td>
                          <td>Pecho</td>
                          <td><input [(ngModel)] = "pecho" name="pecho" id="pecho" type="number" class="form-control" formControlName="pecho"></td>
                       </tr>
                       <tr>
                          <td>Tricipital Derecho</td>
                          <td>Brazo derecho</td>
                          <td><input [(ngModel)] = "brazoDerecho" name="brazoDerecho" id="brazoDerecho" type="number" class="form-control" formControlName="brazoDerecho"></td>
                       </tr>
                       <tr>
                          <td>Tricipital Izquierdo</td>
                          <td>Brazo izquierdo</td>
                          <td><input [(ngModel)] = "brazoIzquierdo" name="brazoIzquierdo" id="brazoIzquierdo" type="number" class="form-control" formControlName="brazoIzquierdo"></td>
                       </tr>
                       <tr>
                          <td>Cuadricipital</td>
                          <td>Muslo</td>
                          <td><input [(ngModel)] = "muslo" name="muslo" id="muslo" type="number" class="form-control" formControlName="muslo"></td>
                       </tr>
                       <tr>
                          <td>Gemelo</td>
                          <td>Pantorrilla</td>
                          <td><input [(ngModel)] = "pantorrilla" name="pantorrilla" id="pantorrilla" type="number" class="form-control" formControlName="pantorrilla"></td>
                       </tr>
                    </table>
                 </div>
                 <button  class="btn btn-primary " >Guardar</button>
                 <button (click)="cerrarForm()" type="button" class="btn btn-primary  ml-3 ">Cerrar</button>
                 </form>
              </div>
           </div>
        </div>
     </div>
  </div>
</div>
<div *ngIf = "evolucionEntrenamientos" style="  margin-left: 3%; margin-right: 3%;">
<div class="row">
<div class="col-12">
  <div class="panel panel-primary">
     <div class="panel-heading">Evolucion</div>
     <div class="panel-body">
        <div>
           <div *ngFor = "let entrenamiento of dataEvolucionEntrenamiento.progresoUsuario | paginate: { itemsPerPage: count, currentPage: p }  " class="panel panel-primary">
              <div  class="panel-heading">Evolucion de tu entrenamiento </div>
              <label class="mt-4 ml-4" >  <img  class="photoStyle" src="../assets/calendario_color.png" style="width:24px;height:24px;" />  Fecha:  {{entrenamiento.fechaProgreso }}   <img  class="photoStyle" src="../assets/escala_actual_color.png" style="width:24px;height:24px;" /> Peso: {{entrenamiento.peso}} Kilogramos   <img  class="photoStyle" src="../assets/plan.png" style="width:24px;height:24px;" /> Nota sesión nutrición: {{entrenamiento.valoracion_nutricion}}   <img  class="photoStyle" src="../assets/personal.png" style="width:24px;height:24px;" />  Nota sesión entrenamiento: {{entrenamiento.valoracion_entrenamiento}} </label>
             
              <div class="panel-body">
                 <div>
                    <div class="container">
                       <div class="row">
                          <div class="col-sm text-center">
                             <img style="margin-top: 4%;" class="photoStyle" *ngIf="entrenamiento.fileFrente!='undefined'"  [src]="entrenamiento.fileFrente"/>
                             <a  style="margin-top: 4%;" *ngIf="entrenamiento.fileFrente!='undefined'" [href]=entrenamiento.fileFrente  class="btn btn-primary">Ver en tamaño real</a>
                             <img  class="photoStyle" *ngIf="entrenamiento.fileFrente=='undefined'" src="../assets/foto.png" style="width:150px;height:100px;">
                          </div>
                          <div class="col-sm text-center">
                             <img  style="margin-top: 4%;" class="photoStyle" *ngIf="entrenamiento.fileEspalda!='undefined'" [src]="entrenamiento.filePerfil"  />
                             <a  style="margin-top: 4%;" *ngIf="entrenamiento.fileEspalda!='undefined'" [href]=entrenamiento.filePerfil  class="btn btn-primary">Ver en tamaño real</a>
                             <img   class="photoStyle" *ngIf="entrenamiento.filePerfil=='undefined'" src="../assets/foto.png" style="width:150px;height:100px;">
                          </div>
                          <div class="col-sm text-center">
                             <img  style="margin-top: 4%;" class="photoStyle" *ngIf="entrenamiento.fileEspalda!='undefined'" [src]="entrenamiento.fileEspalda"  />
                             <a  style="margin-top: 4%;" *ngIf="entrenamiento.fileEspalda!='undefined'" [href]=entrenamiento.fileEspalda  class="btn btn-primary">Ver en tamaño real</a>
                             <img  class="photoStyle" *ngIf="entrenamiento.fileEspalda=='undefined'" src="../assets/foto.png" style="width:150px;height:100px;">
                          </div>
                       </div>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </div>
     <div *ngIf="evolucionEntrenamientos" class="text-left">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
     </div>
  </div>
</div>
</div></div>

<div *ngIf="!evolucionEntrenamientos" style="  margin-left: 3%; margin-right: 3%;">
  <div class="d-flex justify-content-center"> <h3>Aún no has subido ningún reporte</h3></div> 
</div>

<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document">
    <div *ngIf="!encuestaEnviada" class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">¿Como ha ido tu anterior preparación?</h4>
      </div>
      <div  class="modal-body">
        <p>Esta encuesta es opcional, pero ayudara al entrenador personal a la hora de realizar modificaciones en la próxima planificación, valora ahora tu anterior preparación, ¿has podido llevar la dieta y entrenamiento correctamente?</p>
        <label>Entrenamiento: </label>
          <div class="d-flex justify-content-center"> <ngx-stars [readonly]="false" [size]="3" [maxStars]="5" (ratingOutput)="onRatingSetEntrenamiento($event)"></ngx-stars></div> 
        <label>Nutrición: </label>
        <div class="d-flex justify-content-center"> <ngx-stars [readonly]="false" [size]="3" [maxStars]="5" (ratingOutput)="onRatingSetNutricion($event)"></ngx-stars></div> 
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" 
                (click)="enviarValoracion()">
          Enviar valoración
        </button>
        <button type="button" class="btn btn-danger" 
                (click)="closePopup()">
          Cerrar
        </button>
      </div>
    </div>
    <div *ngIf="encuestaEnviada" class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">¿Como ha ido tu anterior preparación?</h4>
      </div>
      <div  class="modal-body">
        
          <div *ngIf="!hayError" class="d-flex justify-content-center"> Gracias por tu colaboración </div> 
          <div *ngIf="hayError" class="d-flex justify-content-center">Ups, parece que hubo un error al guardar la valoración</div> 
        
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" 
                (click)="closePopup()">
          Cerrar
        </button>
      </div>
    </div>
  </div>
</div>
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AngularFireStorage  } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-contacto-entrenador',
  templateUrl: './contacto-entrenador.component.html',
  styleUrls: ['./contacto-entrenador.component.css']
})
export class ContactoEntrenadorComponent implements OnInit {
  uploadProgress: Observable<number>;

  uploadURL: Observable<string>;

  constructor(private _storage: AngularFireStorage) { }

  ngOnInit() {
  }

  upload(event) {
    // Get input file
    const file = event.target.files[0];

    // Generate a random ID
    const randomId = Math.random().toString(36).substring(2);
   
    const filepath = `images/${randomId}`;

    const fileRef = this._storage.ref(filepath);

    // Upload image
    const task = this._storage.upload(filepath, file);

    // Observe percentage changes
    this.uploadProgress = task.percentageChanges();

    // Get notified when the download URL is available
    task.snapshotChanges().pipe(
      finalize(() => this.uploadURL = fileRef.getDownloadURL())
    ).subscribe();
  }

}

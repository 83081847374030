<div>
<div style="  margin-left: 3%; margin-right: 3%;">
    <div class="row">
      <div class="col-12">
              <div class="panel panel-primary">
                <div class="panel-heading">Buscar Usuario</div>
                <div class="panel-body"><div>
                  <form (ngSubmit)="submitAdministradorSearch()" >
                        <div class="form-group row">
                            <label for="nombre" class="col-sm-2 col-form-label">Nombre</label>
                            <div class="col-sm-10">
                              <input [(ngModel)] = "nombre" name="nombre" type="text" class="form-control" id="nombre" placeholder="Adrian Aguirre">
                            </div>
                          </div>

                        <div class="form-group row">
                          <label for="apellido1" class="col-sm-2 col-form-label">Apellido Primero</label>
                          <div class="col-sm-10">
                            <input [(ngModel)] = "apellido1" name="apellido1" type="text" class="form-control" id="apellido1" placeholder="Apellido">
                          </div>
                        </div>
                        <div class="form-group row">
                            <label for="apellido2" class="col-sm-2 col-form-label">Apellido Segundo</label>
                            <div class="col-sm-10">
                              <input [(ngModel)] = "apellido2" name="apellido2" type="text" class="form-control" id="apellido2" placeholder="Segundo Apellido">
                            </div>
                          </div>
                          <div class="form-group row">
                              <label for="dni" class="col-sm-2 col-form-label">DNI</label>
                              <div class="col-sm-10">
                                <input [(ngModel)] = "dni" name="dni" type="number" class="form-control" id="dni" placeholder="DNI">
                              </div>
                            </div>
                            <div class="form-group row">
                                <label for="telefono" class="col-sm-2 col-form-label">Telefono</label>
                                <div class="col-sm-10">
                                  <input [(ngModel)] = "telefono" name="number" type="text" class="form-control" id="telefono" placeholder="Telefono">
                                </div>
                              </div>
                              <div class="form-group row">
                                  <label for="email" class="col-sm-2 col-form-label">Email</label>
                                  <div class="col-sm-10">
                                    <input [(ngModel)] = "email" name="email" type="text" class="form-control" id="email" placeholder="Email">
                                  </div>
                                </div>

                          <button (click)="submitAdministradorSearch()" type="button" class="btn btn-primary ">Buscar</button>

                      </form>
                </div>
              </div>
            </div>
      </div>
     </div>
   </div>


   <div *ngIf="emailEnviadoOk" class="container">
      <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        Se ha enviado correctamente el email del recordatorio de renovacion de cuenta.
        </div>
      </div>

   <div style="  margin-left: 3%; margin-right: 3%;">
      <div class="row">
        <div class="col-12">
                <div class="panel panel-primary">
                  <div class="panel-heading">Resultados</div>
                  <div class="panel-body"><div>
                      <table class="table table-hover">
                          <thead>
                            <tr>
                              <th style="display:none;" scope="col">idUsuario</th>
                              <th scope="col">Nombre</th>
                              <th scope="col">Apellido Primero</th>
                              <th scope="col">Apellido Segundo</th>
                              <th scope="col">DNI</th>
                              <th scope="col">Email</th>
                              <th scope="col">Próxima revisión</th>
                              <th scope="col">Fecha Fin Licencia</th>
                              <th scope="col">Suscripción</th>
                              <th scope="col">Acciones</th>
                             
                            </tr>
                          </thead>
                          <tbody>
                            <tr [ngStyle]="{'background-color': usuario.color}"  *ngFor="let usuario of usuarios">
                              <td style="display:none;">{{usuario.user_id}}</td>
                              <td >{{usuario.nombre}}</td>
                              <td >{{usuario.apellido1}}</td>
                              <td >{{usuario.apellido2}}</td>
                              <td >{{usuario.dni}}</td>
                              <td >{{usuario.email}}</td>
                              <td >{{usuario.proximaRevision | date:'dd/MM/yyyy'}}</td>
                              <td [ngStyle]="{'background-color': usuario.color_fin}" >{{usuario.fechaFinLicencia | date:'dd/MM/yyyy'  }}  <img *ngIf="usuario.image_payment"  class="photoStyle" src="../assets/late-payment.png" style="width:34px;height:34px;" /></td>
                              <td >{{usuario.tipoCuenta}}</td>
                              <td  ><button (click)="enviarRecordatorio(usuario.email)" type="button" class="btn btn-danger mr-3">Enviar recordatorio</button> <button (click)="getRow(usuario.user_id)" type="button" class="btn btn-success">Ver Perfil</button></td>    
                            </tr>

                          </tbody>
                        </table>
                </div>
              </div>
        </div>
        </div>
        </div>
        
</div>


<div *ngIf="superAdmin ==='s' " style="  margin-left: 3%; margin-right: 3%;">
  <div class="row">
    <div class="col-12">
            <div class="panel panel-primary">
              <div class="panel-heading">Usuarios sin pagar</div>
              <div  class="panel-body"><div>
                  <table class="table table-hover">
                      <thead>
                        <tr>
                          <th style="display:none;" scope="col">idUsuario</th>
                          <th scope="col">Nombre</th>
                          <th scope="col">Apellido Primero</th>
                          <th scope="col">Apellido Segundo</th>
                          <th scope="col">DNI</th>
                          <th scope="col">Email</th>
                          <th scope="col">Próxima revisión</th>
                          <th scope="col">Fecha Fin Licencia</th>
                          <th scope="col">Suscripción</th>
                          <th scope="col">Acciones</th>
                         
                        </tr>
                      </thead>
                      <tbody>
                        <tr  *ngFor="let usuario of usuariosBaja  | paginate: { itemsPerPage: count, currentPage: p } ">
                          <td  style="display:none;">{{usuario.user_id}}</td>
                          <td> {{usuario.nombre}}</td>
                          <td >{{usuario.apellido1}}</td>
                          <td >{{usuario.apellido2}}</td>
                          <td >{{usuario.dni}}</td>
                          <td >{{usuario.email}}</td>
                          <td >{{usuario.proximaRevision | date:'dd/MM/yyyy'}}</td>
                          <td >{{usuario.fechaFinLicencia | date:'dd/MM/yyyy'  }}</td>
                          <td >{{usuario.tipoCuenta}}</td>
                          <td ><button (click)="enviarRecordatorioBaja(usuario.email)" type="button" class="btn btn-danger mr-3">Enviar recordatorio</button> <button (click)="getRow(usuario.user_id)" type="button" class="btn btn-success">Ver Perfil</button></td>    
                        </tr>
                      </tbody>
                    </table>
                    <div *ngIf="usuariosBaja.length > 0">
                      <pagination-controls (pageChange)="p = $event"></pagination-controls>
                  </div>
            </div>
          </div>
    </div>
  
    </div>
   
    </div>
    
</div>
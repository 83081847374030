import { Component, OnInit } from '@angular/core';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-bienvenido',
  templateUrl: './bienvenido.component.html',
  styleUrls: ['./bienvenido.component.css']
})
export class BienvenidoComponent implements OnInit {

  constructor(private titleService:Title) {
                
    this.titleService.setTitle("AtlasFitness::Bienvenido");


   }


  ngOnInit() {
  }

}

import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {Title} from "@angular/platform-browser";

@Component({
  selector: 'app-informacion-usuario',
  templateUrl: './informacion-usuario.component.html',
  styleUrls: ['./informacion-usuario.component.css']
})
export class InformacionUsuarioComponent implements OnInit {


  constructor(private router: Router,private titleService:Title) {
                
    this.titleService.setTitle("AtlasFitness::Bienvenido");


   }

  ngOnInit() {

  }

  continuar() {
    this.router.navigate(['registro']);
  }

  atras() {
    this.router.navigate(['login']);
  }

}

import { Component, OnInit } from '@angular/core';
import { HomeServiceService } from '../home-service.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Router} from '@angular/router';
import {Title} from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-mantenimientos',
  templateUrl: './mantenimientos.component.html',
  styleUrls: ['./mantenimientos.component.css']
})
export class MantenimientosComponent implements OnInit {


  public nombreCupon;
  public fechaInicio;
  public fechaFin;
  public descuento;

  public cuponCreado = false;
  public cuponExiste = false;
  public anuncioCreado = false;


  public nombreTitulo;
  public textoTablon;
  public enlace;
  submitted = false;
  crearCuponForm: FormGroup;
  datosError = false;

  public listaCupones = [];
  public listaAnuncios = [];
  public borrarCuponAlert = false;

  public borrarAnuncioAlert =  false;
  


  constructor(private HomeService: HomeServiceService, public router: Router,  private formBuilder: FormBuilder,private titleService:Title,  private spinner: NgxSpinnerService) {
    this.titleService.setTitle("AtlasFitness::Mantenimiento");
         
   }

  ngOnInit() {
    this.borrarCuponAlert = false;
    this.borrarAnuncioAlert = false;
    const data2 = new FormData();
    data2.append('user_id2',sessionStorage.getItem('user_id2'));
    this.HomeService.esSuper(data2).subscribe((res: any) => {
    

      if(res == null || res.superAdmin == 'false'){
        sessionStorage.clear();
        this.router.navigate(['login']);
      }
    });

    this.crearCuponForm = this.formBuilder.group({
      nombreCupon: ['', Validators.required],
      fechaInicio: ['', Validators.required],
      fechaFin: ['', Validators.required],
      descuento:  ['',Validators.required]
    }); 


   this.HomeService.getCupones().subscribe((res: any) => {
    

     this.listaCupones = res;

  }); 

  
  this.HomeService.getAnuncios().subscribe((res: any) => {
    
    this.listaAnuncios = res;

 }); 



  }

  get f() { return this.crearCuponForm.controls; }

  crearCupon(){
    this.submitted = true;
    this.datosError = false;
    if (this.crearCuponForm.invalid) {
      this.datosError = true;
        return;
    }
  
    const data = new FormData();
    data.append('nombreCupon',this.crearCuponForm.value.nombreCupon);
    data.append('fechaInicio',this.crearCuponForm.value.fechaInicio);
    data.append('fechaFin',this.crearCuponForm.value.fechaFin);
    data.append('descuento',this.crearCuponForm.value.descuento);
    

    this.HomeService.crearCupon(data).subscribe((res: any) => {

      this.cuponCreado=true;
      this.listaCupones = [];
      this.HomeService.getCupones().subscribe((res: any) => {
        
   
        this.listaCupones = res;
   
     }); 
   

    },error => {

    //this.cuponExiste=true;

      }
      );
  }

  crearAnuncio(){
    this.spinner.show();
    const data = new FormData();
    data.append('nombreTitulo',this.nombreTitulo);
    data.append('textoTablon',this.textoTablon);
    data.append('enlace',this.enlace);
  
    

    this.HomeService.crearAnuncio(data).subscribe((res: any) => {

      this.anuncioCreado=true;
      this.listaAnuncios = [];
      this.HomeService.getAnuncios().subscribe((res: any) => {
       
        this.listaAnuncios = res;
    
     }); 
     this.spinner.hide();
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });


  }

  public borrarCupon(idCupon){

    const data = new FormData();
    data.append('idCupon',idCupon);
    this.HomeService.borrarCupon(data).subscribe((res: any) => {
      this.borrarCuponAlert = true;
      this.listaCupones = [];
      this.HomeService.getCupones().subscribe((res: any) => {
        
   
        this.listaCupones = res;
   
     }); 

    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });
  }




  public borrarAnuncio(idAnuncio){

    const data = new FormData();
    data.append('idAnuncio',idAnuncio);
    this.HomeService.borrarAnuncio(data).subscribe((res: any) => {
      this.borrarAnuncioAlert = true;
      this.listaAnuncios = [];
      this.HomeService.getAnuncios().subscribe((res: any) => {
       
        this.listaAnuncios = res;   
     },
     error => {
      sessionStorage.clear();
       this.router.navigate(['error']); 
     }); 
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });
  }
  


}

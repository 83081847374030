<div style="  margin-left: 3%; margin-right: 3%;">
  <div *ngIf = "!showError" class="alert alert-info" role="alert">
     Estos valores se han obtenido en función de tus datos como la altura, peso, edad, actividad física aplicando las formulas de Harris-Benedict,son datos aproximados que el entrenador personarl ira ajustando dependiendo de la persona.
  </div>

  <div *ngIf= "showError" class="alert alert-secondary" role="alert">
    No podemos calcular tus macronutrientes porque no nos has indicado el sexo, actualizalo en tus datos!
  </div>
<div *ngIf= "!showError" class="row">
  <div class="col-12 pt-3">
  
     <div class="panel panel-primary">
      <div class="panel-heading">Reparto macronutrientes</div>
      <div class="panel-body">
         <div>
            <p>A continuación se muestra el reparto de macronutrientes para tu dieta, si cambias o actualizas tus objetivos este grafico se actualiza.</p>
            <div id="container"></div>
         </div>
      </div>
   </div>
   <div class="panel panel-primary">
      <div class="panel-heading">Desglose de macronutrientes</div>
     
      <div class="panel-body">
         <div>
            <p>Kcalorias necesarias para mantener el peso (TMB): {{tmb}} Kcal</p>
            <p>Calorias necesarias para {{objetivos}}:  {{tmbConObjetivos}} Kcal</p>
            <p>Porcentaje hidratos de carbono: {{repartoMacrosHc}}%</p>
            <p>Porcentaje proteinas: {{repartoMacrosPr}}%</p>
            <p>Porcentaje grasas: {{repartoMacrosGr}}%</p>
            <p>Kcalorias en hidratos de carbono: {{caloriasCalculadasHcShow}} Kcal</p>
            <p>Kcalorias en proteina: {{caloriasCalculadasPrShow}} Kcal</p>
            <p>Kcalorias en Grasas: {{caloriasCalculadasGrShow}}Kcal</p>
            <div class="panel-body">
            <div class="row">
                <label>1 Gramo de proteina o de hidratos de carbono son 4kcal</label>
            </div>
            <div class="row">
                <label>1 Gramo de grasa son 9kcal</label>
            </div>
           </div>
           
         </div>      
      </div>
   </div>
   <div class="panel panel-primary">
      <div class="panel-heading">Desglose de macronutrientes</div>
     
      <div class="panel-body">
         <div>
            <table class="table table-striped">
               <thead>
                  <tr>
                    <th scope="col">Macronutrientes</th>
                    <th scope="col">Cantidad</th>
                    <th scope="col">Unidades</th>
                    <th scope="col">Suma ( ={{tmbConObjetivos}}) </th>
                    <th scope="col">Porcentaje (%)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Proteinas</th>
                    <td>{{gramosPrF}}</td>
                    <td>Gramos</td>
                    <td>{{caloriasCalculadasPrShow}} Kcal</td>
                    <td>{{repartoMacrosPr}}%</td>
                  </tr>
                  <tr>
                    <th scope="row">Hidratos de carbono</th>
                    <td>{{gramosHcF}}</td>
                    <td>Gramos</td>
                    <td>{{caloriasCalculadasHcShow}}Kcal</td>
                    <td>{{repartoMacrosHc}}%</td>
                  </tr>
                  <tr>
                     <th scope="row">Grasas</th>
                     <td>{{gramosGrF}}</td>
                     <td>Gramos</td>
                     <td>{{caloriasCalculadasGrShow}}Kcal</td>
                     <td>{{repartoMacrosGr}}%</td>
                  </tr>
                </tbody>
             </table>
           <p>Recomendado mínimo 2 piezas de fruta de temporada al día</p>
         </div>      
      </div>
   </div>
  </div>
</div>
</div>
<div style="  margin-left: 3%; margin-right: 3%;">
  <div class="row">
    <div class="col-12">
            <div class="panel panel-primary">
              <div class="panel-heading">Asistente de bienvenida </div>
              <div class="panel-body"><div>
                <label>Bienvenido</label>

                <button class="btn btn-primary"  type="submit">Siguiente</button>
              </div>
            </div>
          </div>
    </div>
</div>
</div>


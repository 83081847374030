<div class="container">
    <div class="row">
      <div *ngIf="!hayPreparacion" class="col-12">
              <div class="panel panel-primary">
                <div *ngIf="fechaNuevaPreparacion!='01/01/1990'" class="panel-heading">Proxima Preparacion :<strong> {{fechaNuevaPreparacion | date:'dd/MM/yyyy'}} </strong></div>
                <div *ngIf="fechaNuevaPreparacion=='01/01/1990'" class="panel-heading">Proxima Preparacion :<strong> De momento no hay fecha </strong></div>
                <div class="panel-body"><div>
                  <label>Tu proxima preparacion estara disponible en la fecha indicada</label>
                </div>
              </div>
            </div>
      </div>
</div>
</div>
<div class="container">
      <div *ngIf="hayPreparacion" style="  margin-left: 3%; margin-right: 3%;">
          <div class="row">
            <div class="col-12">
                    <div class="panel panel-primary">
                      <div class="panel-heading">Nueva preparacion disponible de <strong>{{fechaNuevaPreparacion | date:'dd/MM/yyyy'}}</strong> </div>
                      <div class="panel-body"><div>
                        <div  *ngIf="excelpathdieta != null" class="col-md-6 mb-6">
                            <div class="form-group">
                                <label for="rutina">Ya tienes disponible tu nueva dieta :</label>
                                <a class="btn btn-primary" href={{excelpathdieta}} target="_blank"> Descargar Dieta  </a>
                             </div>
                        </div>
                        <div *ngIf="excelpathrutina != null" class="col-md-6 mb-6">
                            <div class="form-group">
                                <label for="rutina">Ya tienes disponible tu nueva rutina :</label>
                                <a class="btn btn-primary" href={{excelpathrutina}} target="_blank"> Descargar Rutina</a>
                             </div>
                        </div>
                      </div>
                    </div>
                  </div>
            </div>
        </div>
    </div>
  </div>


<div *ngIf="hayPreparacionesPasadas" style="  margin-left: 3%; margin-right: 3%;">
    <div class="row">
      <div class="col-12">
              <div class="panel panel-primary">
                <div class="panel-heading">Preparacion </div>
                <div class="panel-body"><div>
                  <div class="row">
                      <label>Texto</label>
                  </div>
                  <button type="button" class="btn btn-primary">Descargar</button>
                </div>
              </div>
            </div>
      </div>
  </div>
</div>


<div style="  margin-left: 3%; margin-right: 3%;">
    
  <div *ngIf="finSuscripcion">
      <div class="alert alert-danger" role="alert">
          Tu suscripción se ha acabado, para poder utilizar la aplicacón completa y continuar con tu entrenador asignado te recomendamos renovar tu plan <a href="#" [routerLink]="['/suscripcion']" >aquí</a>.
      </div>
  </div>
  <div *ngIf="susNuncaActiva">
      <div class="alert alert-warning" role="alert">
          Activa tu suscripción en tu <a href="#" [routerLink]="['/suscripcion']" >perfil/suscripción</a> para poder asginarte un entrenador personal y poder utilizar la aplicación al completo.
      </div>
  </div>

  <div *ngIf="proximaRevision == null && tipoCuenta != null &&  !finSuscripcion">
    <div class="alert alert-info" role="alert">
        ¿Eres nuevo?, Te recomendamos que vayas a la pestaña de <a href="#"  [routerLink]="['/comoFunciona']" >FAQs</a> para ver como funciona la aplicación
    </div>
</div>

  
  <div class="row">
      <div class="col-sm">
          <div class="panel panel-primary">
              <div class="panel-heading">Tú progreso</div>
              <div class="panel-body">
                  <div>
                      <canvas id="canvas" width="400" height="150">{{chart}}</canvas>
                  </div>
             <!--   <div  class="form-group row">
                   
                    <label style="margin-top: 3%;" for="colFormLabel" class="col-sm col-form-label"><img tooltip="<p>Si quieres saber las calorias necesarias para cumplir tu objetivo, para ver desglosado en macronutrientes ve a la pestaña de Macronutrientes</p>" class="photoStyle" src="../assets/objetivo.png" style="width:24px;height:24px;" /> Calorias para mantener el peso : {{TMB}} Kcal Más informacion en la pestaña de macronutriente </label> 
                  </div> -->
                  <div class="form-group row">
                   
                    <label  for="colFormLabel" class="col-sm col-form-label"><img tooltip="<p>El índice de masa corporal (IMC) es un método utilizado para estimar la cantidad de grasa corporal que tiene una persona, y determinar por tanto si el peso está dentro del rango normal, o por el contrario, se tiene sobrepeso o delgadez. Para ello, se pone en relación la estatura y el peso actual del individuo.</p>" class="photoStyle" src="../assets/bmi.png" style="width:24px;height:24px;" />Tu IMC es : {{textoImc}} </label> 
                    
                  </div>
                  <div *ngIf= "calculoImc != 0">
                    
                  <div class="progress" *ngIf="calculoImc <= 18">
                    <div class="progress-bar progress-bar-striped" role="progressbar" [style.width.%]="calculoImc" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div class="progress" *ngIf="calculoImc > 19 && calculoImc <25 ">
                    <div class="progress-bar progress-bar-striped bg-success" role="progressbar"[style.width.%]="calculoImc" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                  <div class="progress" *ngIf="calculoImc >= 25 && calculoImc < 30 ">
                    <div class="progress-bar progress-bar-striped  bg-warning" role="progressbar"  [style.width.%]="calculoImc" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                   
                  </div>
                  <div class="progress" *ngIf="calculoImc >= 30">
                    <div class="progress-bar progress-bar-striped bg-danger" role="progressbar" [style.width.%]="calculoImc" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                  </div>
                </div>
                <div>
                 <label><img tooltip="<p>El reparto de macronutrientes esta calculado mediantes las formulas de Harris-Benedict, es un calculo aproximado que cada entrenador personal ira ajustando en función del cliente.</p>" class="photoStyle" src="../assets/pie-chart.png" style="width:24px;height:24px;" />Si quieres ver el reparto de macronutrientes para lograr tus objetivos pincha  <a href="#" [routerLink]="['/macronutrientes']" >aquí</a></label>
                </div>
               
              </div>
              
          </div>
      </div>

      <div class="col-sm">
          <div class="panel panel-primary">
              <div class="panel-heading">Resumen General</div>
              <div class="panel-body">
                  <div>
                      <div class="form-group row">
                          <label for="colFormLabel" class="col-sm col-form-label"><img class="photoStyle" src="../assets/login.png" style="width:24px;height:24px;" />Cuenta: {{cuenta}}</label>
                      </div>

                      <div class="form-group row">
                          <label for="colFormLabel" class="col-sm col-form-label "><img class="photoStyle" src="../assets/escala_color.png" style="width:24px;height:24px;" /> Peso Inicial: {{pesoInicial}}</label>
                      </div>

                      <div class="form-group row">
                          <label for="colFormLabel" class="col-sm col-form-label"> <img class="photoStyle" src="../assets/escala_actual_color.png" style="width:24px;height:24px;" />Peso Actual: {{pesoActual}}</label>
                      </div>

                      <div class="form-group row">
                          <label *ngIf="tipoCuenta != null" for="colFormLabel" class="col-sm col-form-label"><img class="photoStyle" src="../assets/tarjeta-de-credito.png" style="width:24px;height:24px;" />Tipo suscripcion: {{tipoCuenta}} </label>
                          <label *ngIf="tipoCuenta == null" for="colFormLabel" class="col-sm col-form-label"><img class="photoStyle" src="../assets/tarjeta-de-credito.png" style="width:24px;height:24px;" />Tipo suscripcion: No tienes ningun plan, activalo en suscripciones </label>
                      </div>

                      <div class="form-group row">
                          <label *ngIf="proximaRevision!=null" for="colFormLabel" class="col-sm col-form-label"><img class="photoStyle" src="../assets/tarea-completada.png" style="width:24px;height:24px;" />Proxima planificacíon: {{proximaRevision | date:'dd/MM/yyyy'}}</label>
                          <label *ngIf="proximaRevision == null" for="colFormLabel" class="col-sm col-form-label"><img class="photoStyle" src="../assets/tarea-completada.png" style="width:24px;height:24px;" />Proxima revision: No hay fecha disponible</label>
                      </div>
                      <div class="form-group row">
                        <label *ngIf="proximaRevision!=null" for="colFormLabel" class="col-sm col-form-label"><img class="photoStyle" src="../assets/take-a-photo.png" style="width:24px;height:24px;" />Envío reporte del progreso: {{proximoCheck | date:'dd/MM/yyyy'}}</label>
                        
                    </div>


                      <div *ngIf="esSuper" class="form-group row">
                          <label for="colFormLabel" class="col-sm col-form-label">
                              <button type="button" class="btn btn-danger" (click)="irPanelAdmin()">Panel Administrador</button>
                          </label>
                      </div>

                  </div>
              </div>
          </div>

      </div>
  </div>

  <div class=" row">

      <div class="col-sm">
          <div class="panel panel-primary">
              <div class="panel-heading">Novedades</div>
              <div class="panel-body">
                  <div>
                      <div *ngIf="tablonAnuncios.length > 0">
                          <div *ngFor="let noticia of tablonAnuncios  | paginate: { itemsPerPage: count, currentPage: p } " class="list-group">
                              <a class="list-group-item list-group-item-action">
                                  <div class="d-flex w-100 justify-content-between">
                                      <h5 class="mb-1"><b>{{noticia.titulo}}</b></h5>
                                      <small>{{noticia.fechaCreacion | date:'dd/MM/yyyy' }}</small>
                                  </div>
                                  <p class="mb-1">{{noticia.texto}}</p>
                                  <a *ngIf="noticia.enlace != 'undefined' " href={{noticia.enlace}} target="_blank">Ir a: {{noticia.enlace}}</a>
                              </a>
                          </div>
                      </div>
                      <div *ngIf="tablonAnuncios.length < 1">

                          <p class="text-justify">Ups! Parece que aún no hay noticias</p>

                      </div>
                  </div>
              </div>
              <div *ngIf="tablonAnuncios.length > 0">
                  <pagination-controls (pageChange)="p = $event"></pagination-controls>
              </div>
          </div>

      </div>

  </div>

  <section id="fixed-form-container">
    <div class="button"><img src="../assets/telegramIcono.png"> ¡Envíame un mensaje a Telegram!</div>
    <div  class="body">
        <div *ngIf="!submitted" >
       <label>¡Se enviará tu duda a nuestro telegram y nos pondremos en contacto contigo!</label>
       <form (ngSubmit) = "enviarTelegram()" [formGroup]="telegramForm">
       <div  class="form-group">
          <label for="pregunta"></label>
          <input placeholder="" [(ngModel)] = "pregunta" name="pregunta"  type="textarea" formControlName="pregunta" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pregunta.errors }"/>
          <div *ngIf="submitted && f.pregunta.errors" class="invalid-feedback">
            <div *ngIf="f.pregunta.errors.required">Campo pregunta obligatorio</div>
        </div>
       </div>
       <button  class="btn btn-primary">Enviar</button>
       </form>
    </div>
    <div *ngIf="submitted">
        <label>¡Tu pregunta ha sido enviada, en breves nos pondremos en contacto contigo!</label>
     </div>
    </div>
   
 </section>

</div>


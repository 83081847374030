
<div class="col-lg-12 col-sm-12">
                <div class="panel panel-primary">
                  <div class="panel-heading">Evolucion gráfica </div>
                  <div class="panel-body"><div>
                    <canvas id="canvas">{{chart}}</canvas>
              </div>
        </div>
    </div>
</div>




<div class="container">

  <div class="mt-2" *ngIf="showSuccess">
    <div class="alert alert-success mt-2">
      Suscripción renovada con exito. 
    </div>
  </div>
  <div class="mt-2" *ngIf="showSuccess">
    <div class="alert alert-info mt-2" role="alert">
      ¡Te recomendamos que visites las  <a href="#" [routerLink]="['/comoFunciona']" >FAQs </a> para ver como funciona todo!
    </div>
  </div>
  

  <div class="mt-2" *ngIf="showError">
    <div class="alert alert-danger mt-2">
     Ha ocurrido un error durante el pago, vuelte a intentarlo. En caso de que siga este error o el pago se haya cargado en tu cuenta visita las 
     <a href="#" [routerLink]="['/comoFunciona']" >FAQs </a> o contacta con nosotros.
    </div>
  </div>


</div>
<div class="container">
    <div class="row">
      <div class="col-12">
              <div class="panel panel-primary">
                <div class="panel-heading">Datos de la suscripción </div>
                <div class="panel-body"><div>
                    <div class="form-group row">
                        <label  style="color:#0377b6"  for="fechaValided" class=" col-sm-3 col-form-label">Suscripción Valida Hasta:</label><label class=" col-sm-2 col-form-label"> {{validez | date:'dd/MM/yyyy'  }}</label>

                      </div>
                      <div class="form-group row">
                          <label style="color:#0377b6"  for="tipoCuenta" class="col-sm-2 col-form-label">Tipo de cuenta:</label> <label class="col-sm-10 col-form-label">{{tipoCuenta}} </label> 
                        </div>
                </div>
              </div>
            </div>
      </div>
    </div>
    </div>


    <div style="  margin-left: 3%; margin-right: 3%;">
      <div *ngIf="cuponAplicado" class="container">
          <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
              ¡Cupon aplicado!
            </div>
          </div>


       

       

      <div style="  margin-left: 3%; margin-right: 3%;">
          <div class="row">
            <div class="col-12">
                    <div class="panel panel-primary">
                      <div class="panel-heading">Planes y precios </div>
                      <div class="panel-body"><div>
                        

                        <section class="pricing py-5">
                          <div class="container">
                            <div class="row">
                          <label for="suplementacion">Seleccione uno de nuestros planes</label>
                          <select [(ngModel)] = "selecPlanes" name="selecPlanes" class="form-control form-control-sm" id="selecPlanes" [value]='nutricion'>
                            <option  value="basico">Básico</option>
                            <option  value="nutricion">Nutrición</option>
                            <option value="entrenamiento">Entrenamiento</option>
                            <option value="nutricionyentrenamiento">Nutrición y entrenamiento</option>
                            <option value="retofatfit">Reto Fitness 8 Semanas</option>
                            <option value="3x2EYN">Oferta Limitada:3X2 Entrenamiento y nutrición</option>
                          </select>
                      </div></div>
                    </section>


                    <ng-container *ngIf="selecPlanes == '3x2EYN'">
                      <section class="pricing py-5" >
                        <div class="container">
                          <div class="row">
                    
                            <div class="col-lg-12">
                              <div class="card mb-5 mb-lg-0">
                                <div class="card-body">
                                  <h5 class="card-title text-muted text-uppercase text-center">OFERTA LIMITADA</h5>
                                  <h6 class="card-price text-center">{{pack14}}€<span class="period">/acceso</span></h6>
                                  <hr>
                                  <ul class="fa-ul" style=" text-align: center;
                                  list-style: inside;">
                                   <li><span class="fa-li"><i class="fas fa-check"></i></span> 3 Meses de entrenamiento + nutrición por el precio de 2</li>
                                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de nutrición personalizado</li>
                                     <li><span class="fa-li"><i class="fas fa-check"></i></span>Seguimiento del progreso</li>
                                     <li><span class="fa-li"><i class="fas fa-check"></i></span>Desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                     <li><span class="fa-li"><i class="fas fa-check"></i></span>Consultas por correo/whatsapp/telegram</li>
                                     <li><span class="fa-li"><i class="fas fa-check"></i></span>Tips de buenos habitos</li>
                                     <li><span class="fa-li"><i class="fas fa-check"></i></span>Plan de entrenamiento adaptado a tus necesidades</li>
                                     <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de cardio personalizado</li>
                                     <li><span class="fa-li"><i class="fas fa-check"></i></span>Se realizará un pago único de la cuantía</li>
                                    <li><span class="fa-li"><i class="fas fa-check"></i></span><strong style="color:red">PLAZAS LIMITADAS</strong></li>                                   
                                  </ul>
                                  <button (click)= "makePayment(pack14, 'Oferta3X2', 'oferta3x2')" class="btn btn-block btn-primary text-uppercase">Comprar</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      </ng-container>


                    <ng-container *ngIf="selecPlanes == 'retofatfit'">
                      <section class="pricing py-5" >
                        <div class="container">
                          <div class="row">
                    
                            <div class="col-lg-12">
                              <div class="card mb-5 mb-lg-0">
                                <div class="card-body">
                                  <h5 class="card-title text-muted text-uppercase text-center">Reto 8 Semanas</h5>
                                  <h6 class="card-price text-center">{{pack13}}€<span class="period">/acceso</span></h6>
                                  <hr>
                                  <ul class="fa-ul" style=" text-align: center;
                                  list-style: inside;">
                                   <li><span class="fa-li"><i class="fas fa-check"></i></span>Reto de 8 semanas</li>
                                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Reto de FATness a FITness en 8 semanas</li>
                                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Atencion Whatsapp 24/7</li>
                                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de entrenamiento personalizado para Gym o para casa.</li>
                                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de cardio y estiramientos personalizado</li>
                                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Dieta personalizada a tu gusto y objetivo (también dieta vegana, vegetariana o para intolerantes).</li>   
                                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Revisiones constantes del progreso y modificaciones de dieta y entrenamiento</li>
                                    <li><span class="fa-li"><i class="fas fa-check"></i></span>Acceso al desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                    <li><span class="fa-li"><i class="fas fa-check"></i></span><strong style="color:red">PLAZAS LIMITADAS</strong></li>                                   
                                  </ul>
                                  <button (click)= "makePayment(pack13, 'Reto FAT to FIT 8 Semanas', 'reto')" class="btn btn-block btn-primary text-uppercase">Comprar</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                      </ng-container>

                      <ng-container *ngIf="selecPlanes == 'basico'">
                        <section class="pricing py-5" >
                          <div class="container">
                            <div class="row">
                         
                              <div class="col-lg-12">
                                <div class="card mb-5 mb-lg-0">
                                  <div class="card-body">
                                    <h5 class="card-title text-muted text-uppercase text-center">Acceso 6 mes</h5>
                                    <h6 class="card-price text-center">{{pack10}}€<span class="period">/acceso</span></h6>
                                    <hr>
                                    <ul class="fa-ul" style=" text-align: center;
                                    list-style: inside;">
                                     <li><span class="fa-li"><i class="fas fa-check"></i></span>Acceso a la aplicación completa</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Registro tu evolución y lleva un progreso</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Registro tus medidas  y lleva un progreso</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Evolucion mediante gráficas</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Rutinas y dietas estandares cada semana</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Acceso a recursos videos/libros (proximamente)</li>
                                    </ul>
                                    <button (click)= "makePayment(pack10, 'Básico', 'semestral')" class="btn btn-block btn-primary text-uppercase">Comprar</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        </ng-container>


                    <ng-container *ngIf="selecPlanes == 'nutricion'">
                        <section class="pricing py-5" >
                          <div class="container">
                            <div class="row">
                          
                              <div class="col-lg-4">
                                <div class="card mb-5 mb-lg-0">
                                  <div class="card-body">
                                    <h5 class="card-title text-muted text-uppercase text-center">Plan 1 mes</h5>
                                    <h6 class="card-price text-center">{{pack1}}€<span class="period">/pack</span></h6>
                                    <hr>
                                    <ul class="fa-ul">
                                     <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de nutrición personalizado</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Seguimiento del progreso</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Consultas por correo/whatsapp/telegram</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Tips de buenos habitos</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Se realizará un pago único de la cuantía de 1 mensualidad</li>
                                    </ul>
                                  
                                    <button (click)= "makePayment(pack1, 'Nutricion', 'mensual')" class="btn btn-block btn-primary text-uppercase">Comprar</button>
                                  </div>
                                </div>
                              </div>
                              
                              <div class="col-lg-4">
                                <div class="card mb-5 mb-lg-0">
                                  <div class="card-body">
                                    <h5 class="card-title text-muted text-uppercase text-center">Plan 3 meses</h5>
                                    <h6 class="card-price text-center">{{pack2}}€<span class="period">/pack</span></h6>
                                    <hr>
                                    <ul class="fa-ul">
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de nutrición personalizado</li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Seguimiento del progreso</li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Consultas por correo/whatsapp/telegram</li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Tips de buenos habitos </li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Se realizará un pago único de la cuantía de 3 mensualidades</li>
                                     </ul>
                                    <button (click)= "makePayment(pack2, 'Nutricion', 'trimestral')" class="btn btn-block btn-primary text-uppercase">Comprar</button>
                                    
                                  </div>
                                </div>
                              </div>
                            
                              <div class="col-lg-4">
                                <div class="card">
                                  <div class="card-body">
                                    <h5 class="card-title text-muted text-uppercase text-center">Plan 6 meses</h5>
                                    <h6 class="card-price text-center">{{pack3}}€<span class="period">/pack</span></h6>
                                    <hr>
                                    <ul class="fa-ul">
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de nutriciónn personalizado</li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Seguimiento del progreso</li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Consultas por correo/whatsapp/telegram</li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Tips de buenos habitos </li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Acceso a la seccion de guias (*Proximamente)</li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Acceso a la seccion de AtlasPodcast(*Proximamente)</li>
                                       <li><span class="fa-li"><i class="fas fa-check"></i></span>Se realizará un pago único de la cuantía de 6 mensualidades</li>
                                     </ul>
                                    <button (click)= "makePayment(pack3, 'Nutricion', 'semestral')" class="btn btn-block btn-primary text-uppercase">Comprar</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        </ng-container>

                        <ng-container *ngIf="selecPlanes == 'entrenamiento'">
                        <section class="pricing py-5">
                          <div class="container">
                            <div class="row">
                              
                              <div class="col-lg-4">
                                <div class="card mb-5 mb-lg-0">
                                  <div class="card-body">
                                    <h5 class="card-title text-muted text-uppercase text-center">Plan 1 mes</h5>
                                    <h6 class="card-price text-center">{{pack4}}€<span class="period">/pack</span></h6>
                                    <hr>
                                    <ul class="fa-ul">
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Plan de entrenamiento adaptado a tus necesidades</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de cardio personalizado</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Seguimiento semanal del progreso</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Consultas por correo/whatsapp/telegram</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Se realizará un pago único de la cuantía de 1 mensualidad</li>
                                    </ul>
                                  
                                    <button (click)= "makePayment(pack4, 'Entrenamiento','mensual')" class="btn btn-block btn-primary text-uppercase">Comprar</button>
                                  </div>
                                </div>
                              </div>
                             
                              <div class="col-lg-4">
                                <div class="card mb-5 mb-lg-0">
                                  <div class="card-body">
                                    <h5 class="card-title text-muted text-uppercase text-center">Plan 3 meses</h5>
                                    <h6 class="card-price text-center">{{pack5}}€<span class="period"> /pack</span></h6>
                                    <hr>
                                    <ul class="fa-ul">
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Plan de entrenamiento adaptado a tus necesidades</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de cardio personalizado</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Seguimiento  del progreso</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Consultas por correo/whatsapp/telegram</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Se realizará un pago único de la cuantía de 3 mensualidades</li>
                                    </ul>
                                    <button (click)= "makePayment(pack5, 'Entrenamiento','trimestral')" class="btn btn-block btn-primary text-uppercase">Comprar</button>
                                    
                                  </div>
                                </div>
                              </div>
                             
                              <div class="col-lg-4">
                                <div class="card">
                                  <div class="card-body">
                                    <h5 class="card-title text-muted text-uppercase text-center">Plan 6 meses</h5>
                                    <h6 class="card-price text-center">{{pack6}}€<span class="period">/pack</span></h6>
                                    <hr>
                                    <ul class="fa-ul">
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Plan de entrenamiento adaptado a tus necesidades</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de cardio personalizado</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Seguimiento del progreso</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Consultas por correo/whatsapp/telegram</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Acceso a la seccion de guias (*Proximamente)</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Acceso a la seccion de AtlasPodcast (*Proximamente)</li>
                                      <li><span class="fa-li"><i class="fas fa-check"></i></span>Se realizará un pago único de la cuantía de 6 mensualidades</li>
                                    </ul>
                                    <button (click)= "makePayment(pack6, 'Entrenamiento','semestral')" class="btn btn-block btn-primary text-uppercase">Comprar</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </section>
                        </ng-container>



                        <ng-container *ngIf="selecPlanes == 'nutricionyentrenamiento'">
                          <section class="pricing py-5">
                            <div class="container">
                              <div class="row">
                               
                                <div class="col-lg-4">
                                  <div class="card mb-5 mb-lg-0">
                                    <div class="card-body">
                                      <h5 class="card-title text-muted text-uppercase text-center">Plan 1 mes</h5>
                                      <h6 class="card-price text-center">{{pack7}}€<span class="period">/pack</span></h6>
                                      <hr>
                                      <ul class="fa-ul">
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Plan de entrenamiento adaptado a tus necesidades</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de cardio personalizado</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de nutricion personalizado</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Seguimiento  del progreso</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Consultas por correo/whatsapp/telegram</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Tips de buenos habitos </li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Se realizará un pago único de la cuantía de 1 mensualidad</li>
                                    </ul>
                                      <button (click)= "makePayment(pack7, 'Nutricion y entrenamiento','mensual')" class="btn btn-block btn-primary text-uppercase">Comprar</button>
                                    </div>
                                  </div>
                                </div>
                                
                                <div class="col-lg-4">
                                  <div class="card mb-5 mb-lg-0">
                                    <div class="card-body">
                                      <h5 class="card-title text-muted text-uppercase text-center">Plan 3 meses</h5>
                                      <h6 class="card-price text-center">{{pack8}}€<span class="period">/pack</span></h6>
                                      <hr>
                                      <ul class="fa-ul">
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Plan de entrenamiento adaptado a tus necesidades</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de cardio personalizado</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de nutricion personalizado</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Seguimiento del progreso</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Consultas por correo/whatsapp/telegram</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Tips de buenos habitos </li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                        <li><span class="fa-li"><i class="fas fa-check"></i></span>Se realizará un pago único de la cuantía de 3 mensualidades</li>
                                    </ul>
                                      <button (click)= "makePayment(pack8, 'Nutricion y entrenamiento','trimestral')" class="btn btn-block btn-primary text-uppercase">Comprar</button>  
                                    </div>
                                  </div>
                                </div>
                                
                                <div class="col-lg-4">
                                  <div class="card">
                                    <div class="card-body">
                                      <h5 class="card-title text-muted text-uppercase text-center">Plan 6 meses</h5>
                                      <h6 class="card-price text-center">{{pack9}}€<span class="period">/pack</span></h6>
                                      <hr>
                                      <ul class="fa-ul">
                                          <li><span class="fa-li"><i class="fas fa-check"></i></span>Plan de entrenamiento adaptado a tus necesidades</li>
                                          <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de cardio personalizado</li>
                                          <li><span class="fa-li"><i class="fas fa-check"></i></span>Programa de nutricion personalizado</li>
                                          <li><span class="fa-li"><i class="fas fa-check"></i></span>Seguimiento del progreso</li>
                                          <li><span class="fa-li"><i class="fas fa-check"></i></span>Consultas por correo/whatsapp/telegram</li>
                                          <li><span class="fa-li"><i class="fas fa-check"></i></span>Tips de buenos habitos</li>
                                          <li><span class="fa-li"><i class="fas fa-check"></i></span>Desglose de macronutrientes y analisis nutricional en función de los objetivos</li>
                                          <li><span class="fa-li"><i class="fas fa-check"></i></span>Acceso a la seccion de guias (*Proximamente)</li>
                                          <li><span class="fa-li"><i class="fas fa-check"></i></span>Acceso a la seccion de AtlasPodcast(*Proxiamemente)</li>
                                          <li><span class="fa-li"><i class="fas fa-check"></i></span>Se realizará un pago único de la cuantía de 6 mensualidades</li>
                                      </ul>
                                      <button (click)= "makePayment(pack9, 'Nutricion y entrenamiento','semestral')" class="btn btn-block btn-primary text-uppercase">Comprar</button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </section>
                          </ng-container>

                    

                        <form>
                            <div *ngIf="selecPlanes == 'nutricion' || selecPlanes == 'entrenamiento' || selecPlanes == 'nutricionyentrenamiento'|| selecPlanes == 'retofatfit' "   class="form-group">
                              <div class="col-md-4 mb-3">
                                <label for="cuponDescuento">¿Tienes un cupon de descuento?</label> 
                                <label *ngIf="cuponVerificar" style="color:red;">{{textoCuponVerificar}}</label>
                                <input  [(ngModel)] = "cuponDescuento" name="cuponDescuento"  type="email" class="form-control" id="cuponDescuento" aria-describedby="emailHelp" placeholder="cupon">
                                <small id="emailHelp" class="form-text text-muted">Introduce tu cupon de descuento</small>
                                <button (click)="aplicarCupon(cuponDescuento)" type="submit" class="btn btn-primary">Aplicar cupon</button>
                              </div>
                             </div>
                        </form>
                      </div>
                    </div> 
                    <section class="pricing py-5">
                      <div class="container">
                          <div class="row">
                        <label style="color:red;">Política de Devolución:</label> 
                        <p style="color: #132989; font-weight: bold;">Sólo se realizarán devoluciones en un plazo máximo de 15 días desde la obtención de la suscripcion. Se descontará de la devolución el número de preparaciones que se hayan realizado a precio de tarifa mensual. Los metodos de pago aceptados son Paypal y tarjeta de credito.</p>
                        <p> <img  class="photoStyle" src="../assets/img_pago_tipo.jpg"></p>
                        
                    </div>
                  </div>
                  </section>
                  </div>
            </div>
        </div>
     
      </div>

        

      
      
        
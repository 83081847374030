import { Component, OnInit } from '@angular/core';
import { HomeServiceService } from '../home-service.service';
import {Title} from "@angular/platform-browser";
import { Router } from '@angular/router';
import { Chart } from 'angular-highcharts';
import * as Highcharts from 'highcharts';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-macronutrientes',
  templateUrl: './macronutrientes.component.html',
  styleUrls: ['./macronutrientes.component.css'],
  template: `
  <div [chart]="chart"></div>
`
})
export class MacronutrientesComponent implements OnInit {
  chart: Chart;
  public tmb;
  public tmbConObjetivos;
  public repartoMacrosHc;
  public repartoMacrosPr;
  public repartoMacrosGr;
  public caloriasCalculadasHcShow;
  public caloriasCalculadasPrShow;
  public caloriasCalculadasGrShow;
  public objetivos;
  public showError = false;


  public gramosHcF;
  public gramosPrF;
  public gramosGrF;

  constructor(private HomeService: HomeServiceService,  public router: Router, private titleService:Title,  private spinner: NgxSpinnerService) { 
    this.titleService.setTitle("AtlasFitness::Macronutrientes");
  }

  

  
  ngOnInit(): void {



    this.spinner.show();
   const data = new FormData();
    data.append('userId', sessionStorage.getItem('user_id2'));

    this.HomeService.tieneSuscripcion(data).subscribe((res:any) => {

      if(res==-1){
       this.router.navigate(['suscripcion']); 
      }
 
      if(res==0){
       this.router.navigate(['suscripcion']); 
      }
 
     });  
     


     this.HomeService.getHomeData().subscribe((res: any) => {
      var today = new Date();
      var year = today.getFullYear();
      var continuar = true;
      var altura = Number(res.altura);
      var edadActual =year - Number(res.anioNacimiento.split("-")[0]);
      var pesoKilosActual =res.pesoKilos;
      var sexo = res.sexo;
      var tmb;
      this.objetivos = res.objetivosText.toLowerCase();


      if(sexo == 'hombre'){
        tmb = 66 + (13.7 * pesoKilosActual) + (5 * altura ) - (6.8 * edadActual);
        this.tmb = tmb.toFixed(2);
       
      }else if(sexo == 'mujer'){
        tmb = 655 + (9.6 * pesoKilosActual) + (1.8 * altura) - (4.7 * edadActual);
        this.tmb=tmb.toFixed(2);
      }else{
        //error actualiza tu sexo
        continuar = false;
        this.showError = true;
      }

      var hc;
      var pr;
      var gr;

      var caloriasCalculadasHc;
      var caloriasCalculadasPr;
      var caloriasCalculadasGr;

      if(continuar){
        if(res.objetivosText == 'Ganar masa muscular'){
          hc = 0.55;
          pr = 0.35;
          gr = 0.10;
          tmb = tmb + 500;

          this.repartoMacrosGr = gr;
          this.repartoMacrosHc = hc;
          this.repartoMacrosPr = pr;
          this.tmbConObjetivos = tmb.toFixed(2);
        }else if(res.objetivosText == 'Definición muscular'){
          hc = 0.25;
          pr = 0.60;
          gr = 0.15;
          tmb = tmb - 500;
          this.repartoMacrosGr = gr;
          this.repartoMacrosHc = hc;
          this.repartoMacrosPr = pr;
          this.tmbConObjetivos = tmb.toFixed(2);
        }else if(res.objetivosText == 'Pérdida de peso'){
          hc = 0.35;
          pr = 0.55;
          gr = 0.10;
          tmb = tmb - 300;
          this.repartoMacrosGr = gr;
          this.repartoMacrosHc = hc;
          this.repartoMacrosPr = pr;
          this.tmbConObjetivos = tmb.toFixed(2);
        }else if(res.objetivosText == 'Mantener el peso'){
          hc = 0.40;
          pr = 0.45;
          gr = 0.15;
          tmb = tmb + 200;
          this.repartoMacrosGr = gr;
          this.repartoMacrosHc = hc;
          this.repartoMacrosPr = pr;
          this.tmbConObjetivos = tmb.toFixed(2);
        }

        caloriasCalculadasHc = tmb * hc;
        caloriasCalculadasPr =  tmb * pr
        caloriasCalculadasGr = tmb * gr;

        this.caloriasCalculadasGrShow = caloriasCalculadasGr.toFixed(2);
        this.caloriasCalculadasHcShow = caloriasCalculadasHc.toFixed(2);
        this.caloriasCalculadasPrShow = caloriasCalculadasPr.toFixed(2);

        var gramosHc = Number((caloriasCalculadasHc / 4).toFixed(2));
        var gramosPr =  Number((caloriasCalculadasPr / 4).toFixed(2));
        var gramosGr =   Number((caloriasCalculadasGr / 9).toFixed(2));

        this.gramosGrF = gramosGr;
        this.gramosHcF = gramosHc;
        this.gramosPrF = gramosPr;

        Highcharts.chart('container', {
          chart: {
              type: 'pie',
              options3d: {
                  enabled: true,
                  alpha: 45,
                  beta: 0
              }
          },
          title: {
              text: 'Reparto macronutrientes en gramos'
          },
          accessibility: {
              point: {
                  valueSuffix: '%'
              }
          },
          tooltip: {
              pointFormat: '{series.name}: <b>{point.y} gr</b>'
          },
          plotOptions: {
              pie: {
                  allowPointSelect: true,
                  cursor: 'pointer',
                  depth: 35,
                  dataLabels: {
                      enabled: true,
                      format: '{point.name}'
                  }
              }
          },
          series: [{
              type: 'pie',
              name: 'Gramos',
              data: [
                  ['Proteinas', gramosPr],
                  ['Hidratos de carbono', gramosHc ],
                  ['Grasas', gramosGr]
              ]
          }]
      });

      }


      console.log(pesoKilosActual);
    this.spinner.hide();
     }, error => {
      this.spinner.hide();
      sessionStorage.clear();
       this.router.navigate(['error']); 
     });



  }

}

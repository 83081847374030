import { Component, OnInit } from '@angular/core';
import {Chart} from 'chart.js';
import { HomeServiceService } from '../home-service.service';
import { resolve, async } from 'q';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgxPaginationModule } from 'ngx-pagination';
import {Title} from "@angular/platform-browser";
import { Observable } from 'rxjs';
import { AngularFireStorage  } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';


@Component({
  selector: 'app-mismedidas',
  templateUrl: './mismedidas.component.html',
  styleUrls: ['./mismedidas.component.css']
})
export class MismedidasComponent implements OnInit {

  chart = [];
  public dataEvolucionEntrenamiento;

  public arrayCintura = [];
  public arrayCadera = [];
  public arrayPecho = [];
  public arrayBrazoDerecho = [];
  public arrayBrazoIzquierdo = [];
  public arrayMuslo = [];
  public arrayPantorrilla = [];
  public arrayFechaProgreso = [];
   
  constructor(private HomeService: HomeServiceService, private sanitizer: DomSanitizer, public router: Router,private titleService:Title,private _storage: AngularFireStorage) {
    this.titleService.setTitle("AtlasFitness::Evolucion graficas");
  }


  
  ngOnInit() {


    const data = new FormData();
    data.append('userId', sessionStorage.getItem('user_id2'));

    this.HomeService.tieneSuscripcion(data).subscribe((res:any) => {

     if(res==-1){
      this.router.navigate(['suscripcion']); 
     }

     if(res==0){
      this.router.navigate(['suscripcion']); 
     }

    });

    this.HomeService.getEvolucionEntrenamientos().subscribe((res: any) => {
      if (resolve.length > 0) {
        this.dataEvolucionEntrenamiento = res.progresoUsuario;

        this.dataEvolucionEntrenamiento.forEach( elemento => {// recorremos cada elemento del array

          this.arrayCintura.push(elemento.cintura);
          this.arrayCadera.push(elemento.cadera);
          this.arrayPecho.push(elemento.pecho);
          this.arrayBrazoDerecho.push(elemento.brazoDerecho);
          this.arrayBrazoIzquierdo.push(elemento.brazoIzquierdo);
          this.arrayMuslo.push(elemento.muslo);
          this.arrayPantorrilla.push(elemento.pantorrilla);
          this.arrayFechaProgreso.push(elemento.fechaProgreso);
             
        });  
        this.chart = new Chart ('canvas', {
          type: 'line',
          data: {
              labels: this.arrayFechaProgreso,
              datasets: [{
                  label: ['Cintura',],
                  data: this.arrayCintura,
                  borderColor: '#80b6f4',
                  pointBorderColor: '#80b6f4',
                  pointBackgroundColor: '#80b6f4',
                  pointHoverBackgroundColor: '#80b6f4',
                  pointHoverBorderColor: '#80b6f4',
                  pointBorderWidth: 10,
                  pointHoverRadius: 10,
                  pointHoverBorderWidth: 1,
                  pointRadius: 3,
                  fill: false,
                  borderWidth: 4,
              },{
                label:  ['Cadera'],
                data: this.arrayCadera,
                borderColor: '#6AF7E8',
                pointBorderColor: '#6AF7E8',
                pointBackgroundColor: '#6AF7E8',
                pointHoverBackgroundColor: '#6AF7E8',
                pointHoverBorderColor: '#6AF7E8',
                pointBorderWidth: 10,
                pointHoverRadius: 10,
                pointHoverBorderWidth: 1,
                pointRadius: 3,
                fill: false,
                borderWidth: 4,
            },{
              label:  ['Pecho'],
              data:  this.arrayPecho,
              borderColor: '#6AF78A',
              pointBorderColor: '#6AF78A',
              pointBackgroundColor: '#6AF78A',
              pointHoverBackgroundColor: '#6AF78A',
              pointHoverBorderColor: '#6AF78A',
              pointBorderWidth: 10,
              pointHoverRadius: 10,
              pointHoverBorderWidth: 1,
              pointRadius: 3,
              fill: false,
              borderWidth: 4,
          },{
                label:  ['Brazo Derecho'],
                data: this.arrayBrazoDerecho,
                borderColor: '#B06AF7',
                pointBorderColor: '#B06AF7',
                pointBackgroundColor: '#B06AF7',
                pointHoverBackgroundColor: '#B06AF7',
                pointHoverBorderColor: '#B06AF7',
                pointBorderWidth: 10,
                pointHoverRadius: 10,
                pointHoverBorderWidth: 1,
                pointRadius: 3,
                fill: false,
                borderWidth: 4,
            },{
              label:  ['Brazo Izquierdo'],
              data: this.arrayBrazoIzquierdo,
              borderColor: '#6A97F7',
              pointBorderColor: '#6A97F7',
              pointBackgroundColor: '#6A97F7',
              pointHoverBackgroundColor: '#6A97F7',
              pointHoverBorderColor: '#6A97F7',
              pointBorderWidth: 10,
              pointHoverRadius: 10,
              pointHoverBorderWidth: 1,
              pointRadius: 3,
              fill: false,
              borderWidth: 4,
          },{
            label:  ['Muslo'],
            data: this.arrayMuslo,
            borderColor: '#F7F76A',
            pointBorderColor: '#F7F76A',
            pointBackgroundColor: '#F7F76A',
            pointHoverBackgroundColor: '#F7F76A',
            pointHoverBorderColor: '#F7F76A',
            pointBorderWidth: 10,
            pointHoverRadius: 10,
            pointHoverBorderWidth: 1,
            pointRadius: 3,
            fill: false,
            borderWidth: 4,
        },{
          label:  ['Pantorrilla'],
          data: this.arrayPantorrilla,
          borderColor: '#F7B56A',
          pointBorderColor: '#F7B56A',
          pointBackgroundColor: '#F7B56A',
          pointHoverBackgroundColor: '#F7B56A',
          pointHoverBorderColor: '#F7B56A',
          pointBorderWidth: 10,
          pointHoverRadius: 10,
          pointHoverBorderWidth: 1,
          pointRadius: 3,
          fill: false,
          borderWidth: 4,
      }],
          },
          options: {
              scales: {
                  yAxes: [{
                      ticks: {
                          beginAtZero: true
                      }
                  }]
              }
          }
      });
      }

    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });


   
  }




}

<div style="  margin-left: 3%; margin-right: 3%;">
    <div *ngIf="datosGuardadosOK" class="container">
        <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
           Usuario Registrado con éxito ahora puede  <button class="btn btn-primary" (click)="seleccionarPlan()">seleccionar plan</button> o <button class="btn btn-primary" (click)="irLogin()">Login</button> 
          </div>
        </div>

        
<div *ngIf="datosError" class="container">
    <div class="alert alert-danger" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
        <span aria-hidden="true">&times;</span>
      </button>
      Fallo al registrar, vuelve a intentarlo, si el error continua contacta con nosotros.
      </div>
    </div>

    <div *ngIf="usuarioRegistrado" class="container">
      <div class="alert alert-danger" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
        ¡El usuario ya se encuentra registrado, porfavor logeate!
        </div>
      </div>
          
        
  <div class="row">
      <div class="col-12 pt-3">
              <div class="panel panel-primary">
                <div class="panel-heading">Registro de nuevo usuario</div>
                <div class="panel-body"><div>
                  <h3>Datos personales</h3>

                  <form [formGroup]="registerForm" (ngSubmit)="onSubmit()">
                     <div class="form-row">
                        <div class="form-group col-md-4 mb-3">
                            <label for= 'nombre'>Nombre</label>
                            <input placeholder="Nombre" [(ngModel)] = "nombre" name="nombre"  type="text" formControlName="nombre" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.nombre.errors }" />
                            <div *ngIf="submitted && f.nombre.errors" class="invalid-feedback">
                                <div *ngIf="f.nombre.errors.required">Campo nombre obligatorio</div>
                            </div>
                        </div>

                      <div class="form-group col-md-4 mb-3">
                          <label for= 'apellido1'>Primer Apellido</label>
                          <input placeholder="Primer Apellido " [(ngModel)] = "apellido1" name="apellido1"  type="text" formControlName="apellido1" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.apellido1.errors }" />
                          <div *ngIf="submitted && f.apellido1.errors" class="invalid-feedback">
                              <div *ngIf="f.apellido1.errors.required">Campo primer apellido obligatorio</div>
                          </div>
                      </div>


                      <div class="form-group col-md-4 mb-3">
                          <label for= 'apellido2'>Segundo Apellido</label>
                          <input placeholder="Segundo Apellido "  [(ngModel)] = "apellido2" name="apellido2"  type="text" formControlName="apellido2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.apellido2.errors }" />
                          <div *ngIf="submitted && f.apellido2.errors" class="invalid-feedback">
                              <div *ngIf="f.apellido2.errors.required">Campo segundo apellido obligatorio</div>
                          </div>
                      </div>


                    </div>
                      <div class="form-row">

                          <div class="form-group col-md-4 mb-3">
                              <label for= 'dni'>DNI Sin letra</label>
                              <input placeholder="DNI "  [(ngModel)] = "dni" name="dni"  type="number" formControlName="dni" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.dni.errors }"  />
                              <div *ngIf="submitted && f.dni.errors" class="invalid-feedback">
                                  <div *ngIf="f.dni.errors.required">Campo DNI obligatorio</div>
                              </div>
                          </div>

                          <div class="form-group col-md-4 mb-3">
                              <label for= 'telefono'>Teléfono</label>
                              <input placeholder="Teléfono "   [(ngModel)] = "telefono" name="telefono"  type="text" formControlName="telefono" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.telefono.errors }"  />
                              <div *ngIf="submitted && f.dni.errors" class="invalid-feedback">
                                  <div *ngIf="f.telefono.errors.required">Campo telefono obligatorio</div>
                              </div>
                          </div>


                          <div class="form-group col-md-4 mb-3">
                              <label for= 'email'>Email</label>
                              <input placeholder="Email "   [(ngModel)] = "email" name="email"  type="text" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                              <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                  <div *ngIf="f.email.errors.required">Campo email obligatorio</div>
                              </div>
                          </div>

                          
                      <div class="form-group col-md-4 mb-3">
                        <label for= 'complexion'>Sexo</label>
                        <select  [(ngModel)] = "sexo" name="sexo" class="form-control" id="sexo" formControlName="sexo" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.sexo.errors }">

                            <option value="hombre">Hombre</option>
                            <option value="mujer">Mujer</option>
                            <option value="otro">Otro</option>
                          </select>
                       
                        <div *ngIf="submitted && f.complexion.errors" class="invalid-feedback">
                            <div *ngIf="f.complexion.errors.required">Campo complexion obligatorio</div>
                        </div>
                    </div>

                      </div>

                      <h3 class="pt-3">Datos fisicos</h3>
                        <div class="form-row">

                            <div class="form-group col-md-4 mb-3">
                                <label for= 'anioNacimiento'>Año de Nacimiento</label>
                                <input placeholder="Año de nacimiento "   [(ngModel)] = "anioNacimiento" name="anioNacimiento"  type="date" formControlName="anioNacimiento" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.anioNacimiento.errors }" />
                                <div *ngIf="submitted && f.anioNacimiento.errors" class="invalid-feedback">
                                    <div *ngIf="f.anioNacimiento.errors.required">Campo año de nacimiento obligatorio</div>
                                </div>
                            </div>

                            <div class="form-group col-md-4 mb-3">
                                <label for= 'altura'>Altura Centimetros</label>
                                <input placeholder="Altura "   [(ngModel)] = "altura" name="altura"  type="text" formControlName="altura" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.altura.errors }" />
                                <div *ngIf="submitted && f.altura.errors" class="invalid-feedback">
                                    <div *ngIf="f.altura.errors.required">Campo altura obligatorio</div>
                                </div>
                            </div>

                            <div class="form-group col-md-4 mb-3">
                                <label for= 'pesoKilos'>Peso Kilogramos</label>
                                <input placeholder="Peso Kilos "   [(ngModel)] = "pesoKilos" name="pesoKilos"  type="text" formControlName="pesoKilos" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pesoKilos.errors }" />
                                <div *ngIf="submitted && f.pesoKilos.errors" class="invalid-feedback">
                                    <div *ngIf="f.pesoKilos.errors.required">Campo peso kilos obligatorio</div>
                                </div>
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-md-4 mb-3">
                                <label for= 'pesoGramos'>Peso Gramos (la parte de gramos 80kilos 500gramos) </label>
                                <input placeholder="Peso Gramos "   [(ngModel)] = "pesoGramos" name="pesoGramos"  type="text" formControlName="pesoGramos" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pesoGramos.errors }" />
                                <div *ngIf="submitted && f.pesoGramos.errors" class="invalid-feedback">
                                    <div *ngIf="f.pesoGramos.errors.required">Campo peso gramos obligatorio</div>
                                </div>
                            </div>

                            <div class="form-group col-md-4 mb-3">
                                <label for= 'complexion'>Complexión</label>
                                <select  [(ngModel)] = "complexion" name="complexion" class="form-control" id="complexion" formControlName="complexion" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.complexion.errors }">

                                    <option value="fuerte">Fuerte</option>
                                    <option value="delgado">Delgado</option>
                                    <option value="atletico">Atletico</option>
                                  </select>
                               
                                <div *ngIf="submitted && f.complexion.errors" class="invalid-feedback">
                                    <div *ngIf="f.complexion.errors.required">Campo complexion obligatorio</div>
                                </div>
                            </div>


                            <div class="form-group col-md-4 mb-3">
                                <label for= 'actividadFisicaDeseada'>Actividad fisica deseada</label>
                                <select  [(ngModel)] = "actividadFisicaDeseada" name="actividadFisicaDeseada" class="form-control" id="actividadFisicaDeseada" formControlName="actividadFisicaDeseada" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.actividadFisicaDeseada.errors }">

                                    <option value= ligero >Ejercicio ligero (1-3 dias por semana) </option>
                                    <option value=moderado>Ejercicio moderado ( 3-5 dias por semana)</option>
                                    <option value=fuerte >Ejercicio fuerte (6 dias por semana)</option>
                                    <option value=profesional>Ejercicio profesional</option>
                                  </select>
                               
                                <div *ngIf="submitted && f.actividadFisicaDeseada.errors" class="invalid-feedback">
                                    <div *ngIf="f.actividadFisicaDeseada.errors.required">Campo actividad fisica obligatorio</div>
                                </div>
                            </div>

                        </div>
                        
                       <div class="form-row">
                            <div class="form-group col-md-4 mb-3">
                                <label for= 'objetivos'>Objetivos</label>
                                <select  [(ngModel)] = "objetivos" name="objetivos" class="form-control" id="objetivos" formControlName="objetivos" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.objetivos.errors }">

                                    <option value="Ganar masa muscular">Ganar masa muscular</option>
                                    <option value="Definición muscular">Definición muscular</option>
                                    <option value="Pérdida de peso">Pérdida de peso</option>
                                    <option value="Mantener el peso">Mantener el peso</option>
                                  </select>
                                <div *ngIf="submitted && f.objetivos.errors" class="invalid-feedback">
                                    <div *ngIf="f.objetivos.errors.required">Campo objetivos obligatorio</div>
                                </div>
                            </div>
                        </div> 
                    
                            <div class="form-row">
                              <div class="form-group col-md-12 mb-3">
                                  <label for= 'comentarios'>Comentarios</label>
                                  <input placeholder="Escribe tus comentarios, que te gusta, horarios, que no te gusta, todo lo que creas que sea importante "   [(ngModel)] = "comentarios" name="comentarios"  type="text" formControlName="comentarios" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.comentarios.errors }" />
                                  <div *ngIf="submitted && f.comentarios.errors" class="invalid-feedback">
                                      <div *ngIf="f.comentarios.errors.required">Campo comentarios obligatorio</div>
                                  </div>
                              </div>
                            </div>
                            <div class="form-row">
                              </div>

                              <div class="form-row">
                                    <div class="form-group col-md-4 mb-3">
                                        <label for= 'suplementacion'>Suplementación</label>
                                        <select  [(ngModel)] = "suplementacion" name="suplementacion" class="form-control" id="suplementacion" formControlName="suplementacion" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.suplementacion.errors }">
        
                                            <option value="si">Si</option>
                                            <option value="no">No</option>
                                            <option value="moderada">Moderada</option>
                                          </select>
                                       
                                        <div *ngIf="submitted && f.suplementacion.errors" class="invalid-feedback">
                                            <div *ngIf="f.suplementacion.errors.required">Campo suplementacion obligatorio</div>
                                        </div>
                                </div>
                              </div>

                           

                    
                        <div class="form-row">

                            <div class="form-group col-md-4 mb-3">
                                <label for= 'contrasenia'>Contraseña</label>
                                <input placeholder="Contraseña "   [(ngModel)] = "contrasenia" name="contrasenia"  type="password" formControlName="contrasenia" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.contrasenia.errors }" />
                                <div *ngIf="submitted && f.contrasenia.errors" class="invalid-feedback">
                                    <div *ngIf="f.contrasenia.errors.required">Campo contraseña obligatorio</div>
                                </div>
                            </div>

                            
                            <div class="form-group col-md-4 mb-3">
                                <label for= 'contrasenia2'>Repita Contraseña</label>
                                <input placeholder="Contraseña "   [(ngModel)] = "contrasenia2" name="contrasenia2"  type="password" formControlName="contrasenia2" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.pesoKilos.errors }" />
                                <div *ngIf="submitted && f.contrasenia2.errors" class="invalid-feedback">
                                    <div *ngIf="f.contrasenia2.errors.required">Campo contraseña obligatorio</div>
                                </div>
                              </div>
                        </div>

                     <div class="form-row">
                     <h3 class="pt-3">Cuestionario Adicional</h3>
                        <p>Este cuestionario es adicional y opcional, puedes rellenarlo mas tarde y enviarmelo por whatsapp o adjuntarlo en la pestaña "Mi perfil", "Datos" si consideras alguna cuestion del mismo importante que quieres que tenga en cuenta, descargalo y revisalo mas tarde! <a href="../../assets/cuestionarioInicial/Cuestionario adicional de bienvenida Atlas Fitness Web.docx" target="_self" download>Descargalo aquí</a></p> Lo puedes descargar más tarde en la pestaña de FAQs
                    </div>
                    <h3 class="pt-3">Checks notificaciones</h3>
                        <div class="form-row mt-02">

                            <div class="form-group col-sm-1 col-md-6 mb-3">
                                <input [(ngModel)] = "permiteSubirFotos" name="permiteSubirFotos" type="checkbox" class="form-check-input" id="permiteSubirFotos" formControlName="permiteSubirFotos" >
                                <label class="form-check-label"  style="padding-left: 5%;"  for="permiteSubirFotos">Consiento el uso de las fotos que suba a la aplicación en la web publica <a href="http://atlasfitness.es">http://atlasfitness.es</a> </label>
                            </div>
                        </div>
    
                        <div class="form-row">
                          <div class="form-group col-sm-12 col-md-6 mb-3">
                            <input [(ngModel)] = "checkPermiteRecibirNotificaciones" name="checkPermiteRecibirNotificaciones"  type="checkbox" class="form-check-input"  id="checkPermiteRecibirNotificaciones" formControlName="checkPermiteRecibirNotificaciones">
                            <label class="form-check-label" style="padding-left: 5%;" for="checkPermiteRecibirNotificaciones">Deseo recibir notificaciones de los anuncios publicados en el tablon de Novedades </label>
                        </div>
                      </div>


                      <div class="pt-3">
                      <div class="form-group">
                          <button class="btn btn-primary"  style="margin-right: 1%;">Registrar</button>
                          <button (click)="atras()" type="button" class=" btn btn-primary">Atrás</button>
                      </div>
                    </div>

                  </form>
                </div>
              </div>
            </div>
      </div>
  </div>
  </div>


import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule  } from '@angular/forms';
import bootstrap from "bootstrap";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { UsuarioBaseComponent } from './usuario-base/usuario-base.component';
import { NuevoProgresoComponent } from './nuevo-progreso/nuevo-progreso.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { NuevaPreparacionComponent } from './nueva-preparacion/nueva-preparacion.component';
import { PerfilUsuarioComponent } from './perfil-usuario/perfil-usuario.component';
import { ActualizarLicenciaComponent } from './actualizar-licencia/actualizar-licencia.component';
import { AdministradorComponent } from './administrador/administrador.component';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { EditarPerfilUsuarioAdministradorComponent } from './editar-perfil-usuario-administrador/editar-perfil-usuario-administrador.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { EntrenadoresComponent } from './entrenadores/entrenadores.component';
import { ErrorComponent } from './error/error.component';
import { BienvenidoComponent } from './bienvenido/bienvenido.component';
import { NgxPayPalModule } from 'ngx-paypal';
import { RegistroEntrenadorComponent } from './registro-entrenador/registro-entrenador.component';
import { ContactoEntrenadorComponent } from './contacto-entrenador/contacto-entrenador.component';
import { RegistroComponent } from './registro/registro.component';
import { InformacionUsuarioComponent } from './informacion-usuario/informacion-usuario.component';
import { MantenimientosComponent } from './mantenimientos/mantenimientos.component';

import { environment } from '../environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import {AngularFireStorageModule} from '@angular/fire/storage';
import { MismedidasComponent } from './mismedidas/mismedidas.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { RecuperarCuentaComponent } from './recuperar-cuenta/recuperar-cuenta.component';
import { TooltipModule } from 'ng2-tooltip-directive';

import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
// Import library module
import { NgxSpinnerModule } from "ngx-spinner";
import { MacronutrientesComponent } from './macronutrientes/macronutrientes.component';
import { BibliotecaComponent } from './biblioteca/biblioteca.component';
import { ModoMantenimientoComponent } from './modo-mantenimiento/modo-mantenimiento.component';
import { ComoFuncionaComponent } from './como-funciona/como-funciona.component';
import { NgxStarsModule } from 'ngx-stars';

import { ChartModule } from 'angular-highcharts';


import { AngularFirestoreModule } from '@angular/fire/firestore';


@NgModule({
  declarations: [
    AppComponent,
    UsuarioBaseComponent,
    NuevoProgresoComponent,
    NavBarComponent,
    NuevaPreparacionComponent,
    PerfilUsuarioComponent,
    ActualizarLicenciaComponent,
    AdministradorComponent,
    LoginComponent,
    EditarPerfilUsuarioAdministradorComponent,
    EntrenadoresComponent,
    ErrorComponent,
    BienvenidoComponent,
    RegistroEntrenadorComponent,
    ContactoEntrenadorComponent,
    RegistroComponent,
    InformacionUsuarioComponent,
    MantenimientosComponent,
    MismedidasComponent,
    ErrorPageComponent,
    RecuperarCuentaComponent,
    MacronutrientesComponent,
    BibliotecaComponent,
    ModoMantenimientoComponent,
    ComoFuncionaComponent

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    NgxPayPalModule,
    AngularFireModule.initializeApp(environment.firebase,'atlasfront'),
    AngularFireMessagingModule,
    AngularFireStorageModule,
    NgxSpinnerModule,
    TooltipModule,
    ChartModule,
    NgxStarsModule,
    AngularFirestoreModule
    
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

<div class="container">
  <div class="row">

      <div *ngIf="datosError" class="container">
          <div class="alert alert-danger" role="alert">
              <button type="button" class="close" data-dismiss="alert" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
              </button>
              Error al acceder, revisa tus datos
          </div>
      </div>
   

      <div class="login">
        <p class="obliqueMotivador">{{mensajeMotivador}}</p>
          <div class="login-triangle"></div>
          <h2 class="login-header">Login</h2>
          <form (ngSubmit)="checkLogin()" class="login-container">
              <p>
                  <input [(ngModel)]="email" name="email" type="email" placeholder="Email">
              </p>
              <p>
                  <input [(ngModel)]="password" name="password" type="password" placeholder="Contraseña">
              </p>
              <p>
                  <input type="submit" value="Acceder">
              </p>
              <form (ngSubmit)="registroUsuario()">
                  <p>
                      <input type="submit" value="¿No tienes cuenta? Regístrate">
                  </p>
                  <p  style="text-align:center;">
                    <a (click)="recuperarPass()" href="#" [routerLink]="['recuperarCuenta']">¿Has olvidado la contraseña?</a>
                </p>
              </form>

          </form>
      

      </div>
  </div>

<!--
<div class="banner">
    <div class="login" >
    <p style="display: inline-block;"><strong>Descarga nuestra app</strong></p>
    <a href="https://www.ejemplo.com/descargar-app"><img style="width: 175px; height: 80px;"  src="../../assets/disponible_google_play_img.png" alt="Descarga nuestra app"></a>
</div>
  </div>
  
  <style>
    @media (max-width: 768px) {
      .banner {
        display: none;
      }
    }
  </style>
  -->
  
 <!-- <div class="row hidden-xs hidden-sm">
      <div class="login" >
          <a href="https://www.instagram.com/atlasfitness.es"><img class="photoStyle" style="margin:10px; margin-left:20%" src="../assets/instagram.png" /></a>
          <a href="https://www.facebook.com/Fitness-motivaci%C3%B3n-254934594998449"><img class="photoStyle" style="margin:10px" src="../assets/facebook.png" /></a>
          <a href="https://web.whatsapp.com/send?phone=34631311108&text=Hola,%20tengo%20una%20duda"><img class="photoStyle" style="margin:10px" src="../assets/whatsapp.png" /></a>
      </div> 
  </div>

  <div class="row visible-xs visible-sm">
    <div class="login" >
        <a href="https://www.instagram.com/atlasfitness.es"><img class="photoStyle" style="margin:10px; margin-left:28%" src="../assets/instagram_movil.png" /></a>
        <a href="https://www.facebook.com/Fitness-motivaci%C3%B3n-254934594998449"><img class="photoStyle" style="margin:10px" src="../assets/facebook_movil.png" /></a>
        <a href="whatsapp://send?phone=34631311108&text=Hola,%20tengo%20una%20duda"><img class="photoStyle" style="margin:10px" src="../assets/whatsapp_movil.png" /></a>
    </div>
</div> -->




</div>


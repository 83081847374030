<div style="  margin-left: 3%; margin-right: 3%;">

  
<div class="row">
    <div class="col-12 pt-3">
            <div class="panel panel-primary">
              <div class="panel-heading">¿Cómo funciona AtlasFitness?</div>
              <div class="panel-body"><div>
                <h3>FAQs</h3>
                <p>A continuación encontraras como utlizar la plataforma AtlasFitness! , si tienes alguna duda este es tu lugar!</p>
               
                    <div class="row">
                        <div class="col-12 mx-auto">
                            <div class="accordion" id="faqExample">
                                <div class="card">
                                    <div class="card-header p-2" id="headingOne">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                <img class="photoStyle" src="../assets/faqs_icon.png" style="width:24px;height:24px;" /> Primeros pasos en la web
                                            </button>
                                          </h5>
                                    </div>
                
                                    <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#faqExample">
                                        <div class="card-body">
                                            <b>A:</b> Para poder empezar a utilizar la web, primero debes seleccionar un plan, si tienes alguna duda con que plan seleccionar puedes escribir un Whatsapp al +34 604 25 97 22 y te aconsejaremos de cual es tu mejor plan, 
                                            una vez seleccionado el plan, en menos de 24h un entrenador personal se pondrá en contacto contigo para preguntarte un poco sobre tus hábitos, rutinas y demás preguntas personales que pueda necesitar para realizar tu planificación.
                                            Despues deberas subir tu progreso 3 fotos y medidas en el apartado de  <a href="#"  [routerLink]="['/nuevoProgreso']" > mi progreso </a>.
                                            La primera planificación tarda de 24/48h, las siguientes van seguidas.
                                            Es importante que revises el correo y verifiques que te llegan los correos. 
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header p-2" id="headingTwo">
                                        <h5 class="mb-0">
                                        <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                            <img class="photoStyle" src="../assets/faqs_icon.png" style="width:24px;height:24px;" /> ¿Cómo subo mi progreso?
                                        </button>
                                      </h5>
                                    </div>
                                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#faqExample">
                                        <div class="card-body">
                                            <b>A:</b>  Puedes subir tu progreso en la pestaña "Registro evolución" , <a href="#"  [routerLink]="['/nuevoProgreso']" > mi progreso </a> .                   
                                        </div>
                                        <div class="card-body">              
                                            <img src="../../assets/miProgreso_2.PNG" class="responsive">
                                        </div>
                                    </div>
                                </div>
                    
                                <div class="card">
                                    <div class="card-header p-2" id="headingThree">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                <img class="photoStyle" src="../assets/faqs_icon.png" style="width:24px;height:24px;" /> ¿Dónde puedo ver la planificación que me ha enviado el entrenador personal?
                                            </button>
                                          </h5>
                                    </div>
                                    <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#faqExample">
                                        <div class="card-body">
                                            <b>A:</b>   Las planificaciones las puedes ver en <a href="#"  [routerLink]="['/nuevaPreparacion']" >la pestaña de mi preparación</a> , aun asi, el día que esta disponible te llegará un correo avisandote de que ya esta disponible.
                                         </div>
                                         <div class="card-body">              
                                             <img src="../../assets/miPreparacion_2.PNG" class="responsive">
                                         </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header p-2" id="headingFour">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                                <img class="photoStyle" src="../assets/faqs_icon.png" style="width:24px;height:24px;" /> ¿Cuando es mi proxima planificación?
                                            </button>
                                          </h5>
                                    </div>
                                    <div id="collapseFour" class="collapse" aria-labelledby="headingFour" data-parent="#faqExample">
                                        <div class="card-body">
                                            <b>A:</b>   Por norma general en el pdf de entrenamientos viene cuando es tu proxima planificación, pero tambien en inicion en la seccion de resumen general, proxima revisión pero es importante que 3 dias antes de la fehca subas tu progreso de nuevo para poder ajustar las
                                           planificaciones en<a href="#"  [routerLink]="['/nuevoProgreso']" >  mi progreso </a>, "Registro evolución", 3 dias antes llega un correo avisandote. 
                                        </div>
                                        <div class="card-body">              
                                            <img src="../../assets/proxPlani.PNG" class="responsive">
                                        </div>
                                    </div>
                                </div>
                                <div class="card">
                                    <div class="card-header p-2" id="headingFive">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                                <img class="photoStyle" src="../assets/faqs_icon.png" style="width:24px;height:24px;" /> ¿Cómo veo la evolucíon del progreso?
                                            </button>
                                          </h5>
                                    </div>
                                    <div id="collapseFive" class="collapse" aria-labelledby="headingFive" data-parent="#faqExample">
                                        <div class="card-body">
                                            <b>A:</b>   Puedes verlo en el historico de las fotos de la pestaña <a href="#"  [routerLink]="['/nuevoProgreso']" > mi progreso </a>, pero si quieres verlo en gráficas, puedes verlo en la pestaña "Mi progreso", <a href="#"  [routerLink]="['/misMedidas']" > evolución medidas </a>.
                                        </div>
                                        <div class="card-body">              
                                            <img src="../../assets/evolucionMedidas.PNG" class="responsive">
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header p-2" id="headingSeven">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                                <img class="photoStyle" src="../assets/faqs_icon.png" style="width:24px;height:24px;" /> He tenido un problema durante el pago o con alguna funcionalidad de la web
                                            </button>
                                          </h5>
                                    </div>
                                    <div id="collapseSeven" class="collapse" aria-labelledby="headingSeven" data-parent="#faqExample">
                                        <div class="card-body">
                                            <b>A:</b>    No te preocupes si has tenido un problema con el pago y te han cobrado, escribenos un Whatsapp y lo solucionaremos, lo mismo si has detectado algun problema en la web!
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header p-2" id="headingEight">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseEight" aria-expanded="false" aria-controls="collapseEight">
                                                <img class="photoStyle" src="../assets/faqs_icon.png" style="width:24px;height:24px;" /> Cuestionario adicional de bienvenida
                                            </button>
                                          </h5>
                                    </div>
                                    <div id="collapseEight" class="collapse" aria-labelledby="headingEight" data-parent="#faqExample">
                                        <div class="card-body">
                                            <b>A:</b>    Descarga de nuevo el cuestionario <a href="../../assets/cuestionarioInicial/Cuestionario adicional de bienvenida Atlas Fitness Web.docx" target="_self" download>aquí</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header p-2" id="headingNine">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseNine" aria-expanded="false" aria-controls="collapseNine">
                                                <img class="photoStyle" src="../assets/faqs_icon.png" style="width:24px;height:24px;" /> ¿Donde subo el cuestionario adicional de bienvenida?
                                            </button>
                                          </h5>
                                    </div>
                                    <div id="collapseNine" class="collapse" aria-labelledby="headingNine" data-parent="#faqExample">
                                        <div class="card-body">
                                            <b>A:</b> Lo puedes enviar por WhatsApp a tu entrenador personal o subirlo en <a href="#"  [routerLink]="['/perfil']" > Mi perfil </a>
                                           </div>
                                    </div>
                                </div>

                                <div class="card">
                                    <div class="card-header p-2" id="heading12">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapse12" aria-expanded="false" aria-controls="collapse12">
                                                <img class="photoStyle" src="../assets/faqs_icon.png" style="width:24px;height:24px;" /> ¿Donde puedo ver el desglose de los macronutrientes y que son?
                                            </button>
                                          </h5>
                                    </div>
                                    <div id="collapse12" class="collapse" aria-labelledby="heading12" data-parent="#faqExample">
                                        <div class="card-body">
                                            <b>A:</b> En el apartado de <a href="#"  [routerLink]="['/macronutrientes']" > macronutrientes </a> encontraras los macronutrientes que son las cantidades en gramos de proteinas, grasas e hidratos de carbono que deberas comer
                                            para conseguir tus ibjetivos. Esta grafica cambia en funcion de los objetivos que tengas en tu <a href="#"  [routerLink]="['/perfil']" > perfil </a>, además se incluye información
                                            de las calorias necesarias para conseguir tus objetivos. Esto esta calculado con las formulas de Harris - Benedict y son aproximadas, nuestros entrenadores personales son los encargados de revisarlas e ir haciendo
                                            ajustes para cada cliente por lo que la información es aproximada.
                                         </div>
                                         <div class="card-body">              
                                            <img src="../../assets/macros.PNG" class="responsive">
                                        </div>
                                    </div>
                                </div> 

                                <div class="card">
                                    <div class="card-header p-2" id="headingSix">
                                        <h5 class="mb-0">
                                            <button class="btn btn-link collapsed" type="button" data-toggle="collapse" data-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                                <img class="photoStyle" src="../assets/faqs_icon.png" style="width:24px;height:24px;" /> ¿No esta tu pregunta?
                                            </button>
                                          </h5>
                                    </div>
                                    <div id="collapseSix" class="collapse" aria-labelledby="headingSix" data-parent="#faqExample">
                                        <div class="card-body">
                                          Si no esta aqui la respuesta a tu pregunta envianos tu pregunta por correo electronido a atlasfitnessweb@gmail.com o envianos un whatsapp al +34 631 31 11 08
                                        </div>
                                    </div>
                                </div>
                              
                           
                            </div>
                
                        </div>
                    </div>
                    <!--/row-->
               
                <!--container-->
              </div>
            </div>
          </div>
    </div>
</div>
</div>

<nav *ngIf="this.HomeService.isUserLoggedIn()" class="navbar navbar-expand-lg navbar-light navBarHeader">
  <div>  <a class="navbar-brand" href="#"> </a> </div>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse " id="navbarSupportedContent">
    <ul class="navbar-nav mr-auto">
      <li class="nav-item active">
        <a *ngIf="this.HomeService.isUserLoggedIn()"class="nav-link" href="#"  [routerLink]="['inicio']" >Inicio <span class="sr-only">(current)</span></a>
      </li>
      <li class="nav-item">
        <a *ngIf="this.HomeService.isUserLoggedIn()" class="nav-link" href="#" [routerLink]="['nuevaPreparacion']">Mi preparación</a>
      </li>
      <li class="nav-item dropdown">
        <a *ngIf="this.HomeService.isUserLoggedIn()" class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  >
          Registro evolución
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a *ngIf="this.HomeService.isUserLoggedIn()" class="dropdown-item"href="#"  [routerLink]="['nuevoProgreso']" >Mi progreso</a>
          <a *ngIf="this.HomeService.isUserLoggedIn()" class="dropdown-item" href="#" [routerLink]="['misMedidas']">Evolución medidas</a>
          <a *ngIf="this.HomeService.isUserLoggedIn()" class="dropdown-item" href="#" [routerLink]="['macronutrientes']">Macronutrientes</a>  
        </div>
      </li>
      <li class="nav-item dropdown">
        <a *ngIf="this.HomeService.isUserLoggedIn()" class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  >
          Perfil
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a *ngIf="this.HomeService.isUserLoggedIn()" class="dropdown-item"href="#"  [routerLink]="['perfil']" >Datos</a>
          <a *ngIf="this.HomeService.isUserLoggedIn()" class="dropdown-item" href="#" [routerLink]="['suscripcion']" >Suscripción</a>
        </div>
      </li>
      <li class="nav-item active">
        <a *ngIf="this.HomeService.isUserLoggedIn() && false"class="nav-link" href="#"  [routerLink]="['tienda']" >Tienda</a>
      </li>
      <li class="nav-item dropdown">
        <a *ngIf="this.HomeService.isUserLoggedIn()  && esVisible" class="nav-link dropdown-toggle"  id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"  >
          Administrador
        </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a *ngIf="this.HomeService.isUserLoggedIn() && esVisible" class="dropdown-item"href="#"  [routerLink]="['administrador']" >Gestión Usuarios</a>
          <a *ngIf="this.HomeService.isUserLoggedIn() && esVisible" class="dropdown-item" href="#" [routerLink]="['mantenimientos']" >Mantenimiento</a>
        </div>
      </li>
      <li class="nav-item active">
        <a *ngIf="this.HomeService.isUserLoggedIn()"class="nav-link" href="#"  [routerLink]="['comoFunciona']" >FAQs</a>
      </li>
        <li class="nav-item">
            <a *ngIf="this.HomeService.isUserLoggedIn()" class="nav-link" href="#" (click) = "this.HomeService.logOut()" >Cerrar Sesión</a>
          </li>
    </ul>
  </div>
</nav>


<nav *ngIf="!this.HomeService.isUserLoggedIn()" class="navbar navbar-expand-lg navbar-light navBarHeader">
</nav>


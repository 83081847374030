import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import {enableProdMode} from '@angular/core';
import { AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(
    private afMessaging: AngularFireMessaging,
    private afs: AngularFirestore
  ) {
    this.afMessaging.requestPermission
      .subscribe(
        () => {
          this.afMessaging.getToken
            .subscribe(
              (token) => {
                console.log('Token del dispositivo:', token);
                // Guarda el token del dispositivo en la base de datos de Firebase
                this.afs.collection('tokens').doc(token).set({ token });
              },
              (error) => {
                console.error(error);
              }
            );
        },
        (error) => {
          console.error(error);
        }
      );
  }
  title = 'AtlasFitness';
}
enableProdMode();
<div class="container">
  <div class="row">


     <div *ngIf="emailEnviado" class="container">
        <div class="alert alert-success" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
           <span aria-hidden="true">&times;</span>
           </button>
           Se ha enviado un email para restablecer la contraseña.
        </div>
     </div>
     <div *ngIf="ERROR_AddressException" class="container">
      <div class="alert alert-danger" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
           <span aria-hidden="true">&times;</span>
           </button>
          Parece que hay un problema con el envio del correo y tu correo electronico, prueba en unos minutos o contacta con tu entrenador personal.
        </div>
     </div>
     <div *ngIf="ERROR_MessagingException" class="container">
      <div class="alert alert-danger" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
           <span aria-hidden="true">&times;</span>
           </button>
           Parece que hay un problema con el envio del email, prueba en unos minutos o contacta con tu entrenador personal.
        </div>
     </div>
     <div *ngIf="NOT_FOUND" class="container">
      <div class="alert alert-info" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close">
           <span aria-hidden="true">&times;</span>
           </button>
          Parece que ese correo no esta registrado
        </div>
     </div>



     <div class="login">
        <div class="login-triangle"></div>
        <h2 class="login-header">Recuperación de cuenta</h2>
        <form  (ngSubmit)="recuperarCuenta()" class="login-container">
           <p><input [(ngModel)] = "email" name = "email"  type="email" placeholder="email"></p>
           <p><input  [(ngModel)] = "dni" name = "dni" type="number" placeholder="dni"></p>
           <p *ngIf="!showLoginButton"><input type="submit" value="Recuperar"></p>
        </form>
        <form *ngIf="showLoginButton" (ngSubmit)="goLogin()" class="login-container">
        <p><input type="submit" value="Acceder"></p>
        </form>
     </div>
  </div>
</div>
<div style="  margin-left: 3%; margin-right: 3%;">
  <div class="row">
    <div class="col-12">
            <div class="panel panel-primary">
              <div class="panel-heading">Asistente de configuración de cuenta </div>
              <div class="panel-body"><div>
               <h3 style="text-align: center"> <img  class="photoStyle" src="../assets/android-chrome-192x192.png" > </h3>
              
              <p>Antes de empezar a utilizar la herramienta necesitamos saber un poco más de ti.</p>
              <p>A continuación, te vamos a mostrar los pasos que tienes que seguir. Es importante que rellenes bien el formulario de registro, ya que nuestros entrenadores se van a basar en los datos proporcionados.</p>
              <ul>
                  <li><strong>Paso 1:</strong> Introduce tus datos del perfil con la máxima precisión posible. Algunos de ellos se podrán cambiar en <strong>Perfil --> Datos</strong>.</li>
                  <li><strong>Paso 2:</strong> Sube tu estado actual en la pestaña <strong>Registro evolución --> Mi progreso</strong>.</li>
                  <li><strong>Paso 3:</strong> Selecciona el tipo de suscripción que deseas tener.</li>
              </ul>

                  <p style="text-align: center">  ¡¡¡ Una vez finalizado, pulse registrar y en un plazo de 3 días tendrá su plan de entrenamiento y rutina !!!</p>
              </div>
            </div>
            <div class="panel-body"><div>
                <button (click)="continuar()" type="button" class="mr-3 btn btn-primary">Continuar</button>
                <button (click)="atras()" type="button" class=" btn btn-primary">Atrás</button>
             </div>
           </div>
          </div>

    </div>
   </div>
 </div>

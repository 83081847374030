import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NuevoProgresoComponent } from './nuevo-progreso/nuevo-progreso.component';
import { UsuarioBaseComponent } from './usuario-base/usuario-base.component';
import { NuevaPreparacionComponent } from './nueva-preparacion/nueva-preparacion.component';
import { PerfilUsuarioComponent } from './perfil-usuario/perfil-usuario.component';
import { ActualizarLicenciaComponent } from './actualizar-licencia/actualizar-licencia.component';
import { AdministradorComponent } from './administrador/administrador.component';
import { LoginComponent } from './login/login.component';
import { EditarPerfilUsuarioAdministradorComponent } from './editar-perfil-usuario-administrador/editar-perfil-usuario-administrador.component';
import { EntrenadoresComponent } from './entrenadores/entrenadores.component';
import { BienvenidoComponent } from './bienvenido/bienvenido.component';
import { RegistroEntrenadorComponent } from './registro-entrenador/registro-entrenador.component';
import { RegistroComponent } from './registro/registro.component';
import { InformacionUsuarioComponent } from './informacion-usuario/informacion-usuario.component';
import { MantenimientosComponent } from './mantenimientos/mantenimientos.component';
import { ContactoEntrenadorComponent } from './contacto-entrenador/contacto-entrenador.component';
import { MismedidasComponent } from './mismedidas/mismedidas.component';
import { ErrorPageComponent } from './error-page/error-page.component';
import { RecuperarCuentaComponent } from './recuperar-cuenta/recuperar-cuenta.component';
import { MacronutrientesComponent } from './macronutrientes/macronutrientes.component';
import { BibliotecaComponent } from './biblioteca/biblioteca.component';
import { ModoMantenimientoComponent } from './modo-mantenimiento/modo-mantenimiento.component';
import {ComoFuncionaComponent} from './como-funciona/como-funciona.component';



const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'inicio',
    component: UsuarioBaseComponent
  },
  {
    path: 'nuevoProgreso',
    component: NuevoProgresoComponent
  },
  {
    path: 'nuevaPreparacion',
    component: NuevaPreparacionComponent
  },
  {
    path: 'perfil',
    component: PerfilUsuarioComponent
  },
  {
    path: 'suscripcion',
    component: ActualizarLicenciaComponent
  },
  {
    path: 'administrador',
    component: AdministradorComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'editarPerfilUsuarioAdministrador',
    component: EditarPerfilUsuarioAdministradorComponent
  },
  {
    path: 'entrenadores',
    component: EntrenadoresComponent
  },
  {
    path: 'bienvenido',
    component: BienvenidoComponent
  },
  {
    path: 'registro-entrenador',
    component: RegistroEntrenadorComponent
  },
  {
    path: 'registro',
    component: RegistroComponent
  },
  {
    path: 'informacion',
    component: InformacionUsuarioComponent
  },
  {
    path: 'mantenimientos',
    component: MantenimientosComponent
  },
  {
    path: 'contactoEntrenador',
    component: ContactoEntrenadorComponent
  },
  {
    path:'misMedidas',
    component: MismedidasComponent
  },
  {
    path:'error',
    component: ErrorPageComponent
  },
  {
    path:'recuperarCuenta',
    component: RecuperarCuentaComponent
  },
  {
    path:'macronutrientes',
    component: MacronutrientesComponent
  },
  {
    path:'tienda',
    component: BibliotecaComponent
  },
  {
    path:'modoMantenimiento',
    component: ModoMantenimientoComponent
  },
  {
    path:'comoFunciona',
    component: ComoFuncionaComponent
  }



];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

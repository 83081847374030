import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { HomeServiceService } from '../home-service.service';
import {formatDate} from '@angular/common';
import {Title} from "@angular/platform-browser";
import { DatePipe } from '@angular/common';
import { NgxPaginationModule } from 'ngx-pagination';


@Component({
  selector: 'app-administrador',
  templateUrl: './administrador.component.html',
  styleUrls: ['./administrador.component.css'],
  providers: [DatePipe],
  
})
export class AdministradorComponent implements OnInit {

  public nombre;
  public apellido1;
  public apellido2;
  public dni;
  public telefono;
  public email;
  public user_id
  public superAdmin = 'n';


  public usuarios;
  public myDate;

  public usuariosBaja;

  emailEnviadoOk = false;

  myCurDate = new Date();

  p: Number = 1;
  count: Number = 7;

  constructor(private HomeService: HomeServiceService, public router: Router,private titleService:Title, private datePipe: DatePipe) {
                
    this.titleService.setTitle("AtlasFitness::Administrador");
    this.myDate = this.datePipe.transform(this.myCurDate, 'yyyy-mm-dd');

   }

  ngOnInit() {

    this.emailEnviadoOk = false;
   
    const data2 = new FormData();
    data2.append('user_id2',sessionStorage.getItem('user_id2'));
    this.HomeService.esSuper(data2).subscribe((res: any) => {

      if(res == null){
        sessionStorage.clear();
        this.router.navigate(['login']);
      }else {
        this.superAdmin = res.superAdmin;
      }
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });

    this.HomeService.getUsuariosDeBaja().subscribe((res: any) => {
     
      this.usuariosBaja = res;
      
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });

    this.HomeService.savePerfilUsuario(null).subscribe(Response => {
     
     this.usuarios = Response;

     this.usuarios.sort(function compare(a, b) {
      var dateA = new Date(a.proximaRevision);
      var dateB = new Date(b.proximaRevision);
      return  dateB.valueOf() - dateA.valueOf() ;
    });
    const yourDate = new Date()
    
    var currentDate = yourDate.toISOString().split('T')[0];

    for (const u in this.usuarios) {
  
      var date = Date.parse( this.usuarios[u].proximaRevision);
      var currentDay = Date.parse(currentDate);
      var diff = date - currentDay;
     
      if (date < currentDay) { //ha pasado el dia de la revision y no se ha subido
        this.usuarios[u].color = "#F69A9A";
      }else if((diff/(1000*60*60*24)) < 4 ){ // faltan menos de 4 dias
        this.usuarios[u].color = "#F6EE9A";
      }else if((diff/(1000*60*60*24)) < 2 ){ //faltan menos de 2 dias
        this.usuarios[u].color = "#FB9A5F "; 
      }else{ //todo okey
        this.usuarios[u].color = "";
      }

      var dateFechaFin = Date.parse( this.usuarios[u].fechaFinLicencia);
      var currentDayFechaFin = Date.parse(currentDate);
      var diffFechaFin = dateFechaFin - currentDayFechaFin;

      if((diffFechaFin/(1000*60*60*24)) < 4 ){ // faltan menos de 4 dias
        this.usuarios[u].color_fin = "#BF9CFF"; 
        this.usuarios[u].image_payment = true;
      }else if((diffFechaFin/(1000*60*60*24)) < 2 ){ //faltan menos de 2 dias
        this.usuarios[u].color_fin = "#5900FF";
        this.usuarios[u].image_payment = true;
      }else{ //todo okey
        this.usuarios[u].color_fin = "";
        this.usuarios[u].image_payment = false;
      }

   

    }
    

    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });
  }


  public submitAdministradorSearch() {

    this.myDate = formatDate(new Date(), 'dd/MM/yyyy', 'en');
  
   
    const data = new FormData();
    data.append('nombre', this.nombre);
    data.append('apellido1', this.apellido1);
    data.append('apellido2', this.apellido2);
    data.append('dni', this.dni);
    data.append('telefono', this.telefono);
    data.append('email', this.email);
 
    this.HomeService.savePerfilUsuario(data).subscribe(Response => {
    

     this.usuarios = Response;
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });
  }


  getRow(user_id) {

    sessionStorage.setItem('user_id', user_id);
   this.router.navigate(['editarPerfilUsuarioAdministrador']);
  }

  
  enviarRecordatorio(email){
    this.emailEnviadoOk = false;
    const data = new FormData();
    data.append('email', email);

    this.HomeService.enviarRecordatorioMail(data).subscribe(Response => {
      this.emailEnviadoOk = true;
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });

  }


  enviarRecordatorioBaja(email){
    this.emailEnviadoOk = false;
    const data = new FormData();
    data.append('email', email);

    this.HomeService.enviarRecordatorioMailBaja(data).subscribe(Response => {
      this.emailEnviadoOk = true;
    },
    error => {
     sessionStorage.clear();
      this.router.navigate(['error']); 
    });

  }
   
   
 



}

import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HomeServiceService } from '../home-service.service';
import {Title} from "@angular/platform-browser";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

@Injectable({
  providedIn: 'root'
})

export class LoginComponent implements OnInit {

  email = '';
  password = '';
  invalidLogin = false;
  datosError = false;
  private contErr = 0;

  mensajeMotivador = "";




  constructor(private router: Router,
              private HomeService: HomeServiceService, private titleService:Title, private spinner: NgxSpinnerService) {
                
                this.titleService.setTitle("AtlasFitness::Login");
         

               }
  ngOnInit() {

    const frasesMotivadoras = [
      "Si tienes miedo de fallar, probablemente falles. - Kobe Bryant",
      "No se trata de si te derriban, se trata de si te levantas. - Vince Lombardi",
      "Si entrenas duro, no solo serás duro, serás duro de superar. - Herschel Walker",
      "Si solo das el 90% en los entrenamientos, solo darás el 90% cuando importe. - Michael Owen",
      "¡Nunca te rindas! El fracaso y el rechazo son sólo el primer paso para tener éxito. - Jim Valvano",
      "Un campeón es alguien que se levanta cuando el otro no puede. - William Harrison",
      "No te rindas. Sufre ahora y vive el resto de tu vida como un campeón. - Muhammad Ali",
      "Debes dar el 100% en la primera mitad del partido. Si eso no es suficiente, en la segunda mitad debes dar todo lo que te queda.- Yogi Berra",
      "En el polvo de la derrota y en los laureles de la victoria, se puede encontrar gloria si uno dio su mejor esfuerzo. - Eric Liddell",
      "Aunque nadie puede volver atrás y hacer un nuevo comienzo, cualquiera puede comenzar a partir de ahora y crear un nuevo final. - Carl Bard",
      "Lo único que se interpone entre ti y tu sueño, es la voluntad de intentarlo y la creencia de que en realidad es posible. - Joel Brown",
      "La derrota no es el peor de los fracasos. No intentarlo es el verdadero fracaso. - George Edward Woodberry",
      "El mayor placer de la vida es hacer lo que la gente dice que no puedes hacer. - Walter Bagehot",
      "He fallado más de 9.000 tiros en mi carrera. He perdido casi 300 juegos. 26 veces, han confiado en mí para hacer un tiro ganador y he perdido. He fallado una y otra y otra vez en mi vida. Y es por eso que tenga éxito. - Michael Jordan",
      "Proponte alcanzar unas metas altas, y no te detengas hasta llegar allí. - Bo Jackson",
      "Los campeones no están hechos en el gimnasio. Los campeones están hechos de algo que tienen en su interior, un deseo, un sueño, una visión. - Muhammad Ali",
      "La adversidad hace que algunos hombres se rompan; otros rompen sus límites. - William Arthur Ward",
      "Trabaja en tu debilidad hasta que se convierta en tu fortaleza. - Knute Rockne",
      "El deporte no construye el carácter. Lo revela. - Heywood Broun",
      "El camino hacia el éxito siempre está en construcción. - Lily Tomlin",
      "Lo que encaramos parece insuperable, pero somos más fuertes de lo que creemos.-Arnold Schwarzenegger.",
      "Si fuera fácil todos serían buenos.-Phil Heath.",
      "Trabajo duro y entrenar. No hay fórmula secreta.-Ronnie Coleman",
      "No digas más mañana",
      "Entrega, compite, repite",
      "No te vayas dejando algo sin terminar.-Arnold Schwarzenegger.",
      "El dolor es temporal, el orgullo para siempre",
      "La pelea se gana o pierde lejos de los testigos, tras las líneas, en el gimnasio, en la carretera, lejos de donde bailo bajo esas luces.-Muhammad Ali",
      "Solo los caminos duros llevan a la grandeza.",
      "Cuanto más sudas en el entrenamiento, menos sangras en el combate.",
      "Piensa que puedes y podrás, pero espera el sufrimiento, perseverancia y disciplina.",
      "Lo que cuenta no es el número de horas que entrenas, sino la energía que pones en esas horas.",
      "Para ser el número uno, tienes que entrenar como si fueras el número dos.-Maurice Green.",
      "Mientras los ganadores entrenan, los perdedores se quejan.",
      "Ganar es el segundo paso, querer ganar es el primero.",
      "Algunos quieren que ocurra, otros desearían que ocurra, otros hacen que ocurra.",
      "Cuando pienses en abandonar, piensa porqué empezaste.",
      "Ama el dolor del esfuerzo, porque si no existiese tendrías mucha más competencia.",
      "Ser derrotado es a menudo una condición temporal. Rendirse es lo que lo hace permanente.",
      "Pensar en ir al gimnasio quema entre 0 y 0 calorías.",
      "Lo importante es avanzar, actuar y no ponerse excusas. Tarde o temprano verás los resultados."
    ];

    
      const rndInt = this.randomIntFromInterval(1, 41);
      
      this.mensajeMotivador = frasesMotivadoras[rndInt];    

  }

   randomIntFromInterval(min, max) { // min and max included 
    return Math.floor(Math.random() * (max - min + 1) + min)
  }

  checkLogin() {
    this.spinner.show();
    this.datosError = false;
   
    (this.HomeService.authenticate(this.email, this.password).subscribe(
      data => {
        this.router.navigate(['inicio']);
        this.invalidLogin = false;
 
      },
      error => {
        this.invalidLogin = true;
        sessionStorage.clear();
        this.datosError = true;
        this.contErr = this.contErr + 1
        this.spinner.hide();
       
      }
    )
    );

  }

  registroUsuario(){
    this.router.navigate(['informacion']);
  }

  recuperarPass(){
    this.router.navigate(['recuperarCuenta']);
  }

}
